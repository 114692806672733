// src/components/Footer.js

import React from 'react';
import { Box, Typography, Button } from '@mui/material';

const Footer = () => {
    return (
        <Box 
            sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center', // Optional: Center horizontally
                height: '100px', // Set height for vertical centering
                backgroundColor: '#f0f0f0' // Just for visual clarity

            }}
        >
            <Typography variant="caption" sx={{ fontSize: '0.75rem' }}>
                &copy; transfer.co
            </Typography>
                    </Box>
    );
};
export default Footer;

