// ExchangeModule.js
import React, { useEffect, useRef, useState, useCallback } from "react";
import { 
  Box, 
  CircularProgress, 
  Typography, 
  Dialog, 
  DialogTitle, 
  DialogContent,
  DialogActions,
  Button,
  Alert,
  IconButton,
  Snackbar,
  Paper
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import RefreshIcon from '@mui/icons-material/Refresh';

// Simplified widget configuration
const WIDGET_CONFIG = {
  amount: 0.1,
  backgroundColor: "FFFFFF",
  darkMode: false,
  from: "btc",
  to: "eth",
  link_id: "afa21d29e6928a", // Your affiliate link ID
  lang: "en-US",
  logo: true,
  primaryColor: "00C26F"
};

// Construct the widget URL with config params
const constructWidgetUrl = (config) => {
  const baseUrl = "https://changenow.io/embeds/exchange-widget/v2/widget.html";
  const params = new URLSearchParams();
  
  for (const [key, value] of Object.entries(config)) {
    params.append(key, typeof value === 'boolean' ? value.toString() : value);
  }
  
  return `${baseUrl}?${params.toString()}`;
};

const ExchangeModule = () => {
  const iframeRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [widgetHeight, setWidgetHeight] = useState(400); // Fixed height to avoid layout shifts
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationData, setConfirmationData] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const [showTroubleshooting, setShowTroubleshooting] = useState(false);
  const initAttempt = useRef(0);
  const widgetLoaded = useRef(false);
  
  // Simple notification system
  const showNotification = (message, severity = 'info') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  // Handle exchange confirmations
  const handleExchangeConfirmation = useCallback((data) => {
    console.log('Exchange confirmation received:', data);
    setConfirmationData(data);
    setConfirmationOpen(true);
  }, []);

  // Reload the widget when it fails
  const reloadWidget = useCallback(() => {
    console.log('Reloading exchange widget');
    setLoading(true);
    setError(null);
    widgetLoaded.current = false;
    initAttempt.current += 1;
    
    if (iframeRef.current) {
      // Add timestamp to prevent caching
      const url = constructWidgetUrl(WIDGET_CONFIG) + `&t=${Date.now()}`;
      iframeRef.current.src = url;
      showNotification('Reloading exchange widget...', 'info');
    }
  }, []);

  // Handle message events from the widget iframe
  const handleWidgetMessages = useCallback((event) => {
    // Basic origin verification
    if (!event.origin.includes('changenow.io')) {
      return;
    }
    
    try {
      const data = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;
      
      switch (data?.type) {
        case 'iframe-height':
          // Keep a minimum height but allow expansion
          setWidgetHeight(Math.max(400, data.height + 20));
          break;
          
        case 'widget-ready':
        case 'WIDGET_INITIALIZED':
          if (!widgetLoaded.current) {
            console.log('Exchange loaded successfully');
            setLoading(false);
            widgetLoaded.current = true;
            showNotification('ready to transfer.co', 'success');
          }
          break;
          
        case 'exchange-created':
          // Handle successful exchange
          handleExchangeConfirmation({
            fromCurrency: data.fromCurrency || 'BTC',
            toCurrency: data.toCurrency || 'ETH',
            amount: data.amount || '0.1',
            estimatedAmount: data.estimatedAmount || '1.5',
            transactionId: data.id || 'TX' + Date.now()
          });
          break;
          
        default:
          // For debugging
          if (data?.type && !data.type.includes('CURRENCY_LIST')) {
            console.log('Widget message:', data);
          }
      }
    } catch (err) {
      // Ignore parsing errors
    }
  }, [handleExchangeConfirmation]);

  // Main effect for setting up widget and listeners
  useEffect(() => {
    initAttempt.current += 1;
    console.log(`Exchange widget initialization attempt ${initAttempt.current}`);
    
    // Set up message event listener
    window.addEventListener('message', handleWidgetMessages);
    
    // Create global exchange dialog function
    window.openExchangeDialog = () => {
      console.log("Exchange dialog function called");
      
      // Focus on the exchange widget
      if (iframeRef.current) {
        iframeRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        
        // Add highlight effect
        iframeRef.current.style.boxShadow = '0 0 20px rgba(0, 210, 50, 0.8)';
        setTimeout(() => {
          iframeRef.current.style.boxShadow = 'none';
        }, 2000);
        
        // Reload if not ready
        if (!widgetLoaded.current && !loading) {
          reloadWidget();
        }
      }
    };
    
    // Set timeout to detect widget loading failure
    const timeoutId = setTimeout(() => {
      if (loading && !widgetLoaded.current) {
        console.log('Widget failed to load, showing error');
        setError({
          title: 'Widget Load Error',
          message: 'The exchange widget could not be loaded. This may be due to network issues or ad blockers.',
          type: 'LOAD_ERROR'
        });
        setLoading(false);
      }
    }, 8000);
    
    return () => {
      window.removeEventListener('message', handleWidgetMessages);
      clearTimeout(timeoutId);
    };
  }, [handleWidgetMessages, loading, reloadWidget]);

  // Simplified script loading
  useEffect(() => {
    if (document.querySelector('script[src*="stepper-connector.js"]')) {
      console.log('Stepper connector script already loaded');
      return;
    }
    
    const script = document.createElement('script');
    script.src = 'https://changenow.io/embeds/exchange-widget/v2/stepper-connector.js';
    script.async = true;
    
    document.body.appendChild(script);
    
    return () => {
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: `${widgetHeight}px`,
          borderRadius: "12px",
          overflow: "hidden",
          mt: 2,
          mb: 4,
          transition: "height 0.3s ease-in-out",
          backgroundColor: "#f8f8f8",
          boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
        }}
      >
        {/* Loading overlay */}
        {loading && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(255, 255, 255, 0.9)",
              zIndex: 10,
            }}
          >
            <CircularProgress
              size={40}
              thickness={4}
              sx={{ color: "#00D632", mb: 2 }}
            />
            <Typography variant="body2" color="textSecondary">
              Loading exchange...
            </Typography>
          </Box>
        )}

        {/* Error display */}
        {error && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#fff",
              zIndex: 5,
              p: 3,
            }}
          >
            <Paper
              elevation={3}
              sx={{
                p: 3,
                width: '100%',
                maxWidth: 500,
                borderRadius: 2,
                textAlign: 'center'
              }}
            >
              <Box sx={{ mb: 2, display: 'flex', justifyContent: 'center' }}>
                <ErrorIcon color="error" sx={{ fontSize: 48 }} />
              </Box>
              
              <Typography variant="h6" color="error" gutterBottom>
                {error.title}
              </Typography>
              
              <Typography variant="body2" color="textSecondary" align="center" sx={{ mb: 3 }}>
                {error.message}
              </Typography>
              
              <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<RefreshIcon />}
                  onClick={reloadWidget}
                >
                  Reload Widget
                </Button>
                
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setShowTroubleshooting(true)}
                >
                  Troubleshooting
                </Button>
              </Box>
            </Paper>
          </Box>
        )}

        {/* Exchange widget iframe */}
        <iframe
          id="iframe-widget"
          ref={iframeRef}
          src={constructWidgetUrl(WIDGET_CONFIG)}
          style={{
            height: "100%",
            width: "100%",
            border: "none",
            borderRadius: "12px",
          }}
          title="Cryptocurrency Exchange Widget"
          key={`exchange-widget-${initAttempt.current}`}
        />
      </Box>

      {/* Exchange Confirmation Dialog */}
      <Dialog 
        open={confirmationOpen} 
        onClose={() => setConfirmationOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ 
          background: 'linear-gradient(90deg, #00D632 0%, #A1E100 100%)',
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <CheckCircleIcon />
            <span>Exchange Initiated</span>
          </Box>
          <IconButton 
            edge="end" 
            color="inherit" 
            onClick={() => setConfirmationOpen(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        
        <DialogContent sx={{ pt: 2, pb: 1 }}>
          {confirmationData && (
            <>
              <Alert severity="success" sx={{ mb: 2 }}>
                Your exchange request has been submitted successfully!
              </Alert>
              
              <Typography variant="body2" paragraph>
                You are exchanging <strong>{confirmationData.amount} {confirmationData.fromCurrency}</strong> for approximately <strong>{confirmationData.estimatedAmount} {confirmationData.toCurrency}</strong>.
              </Typography>
              
              <Typography variant="body2" paragraph>
                Transaction ID: <strong>{confirmationData.transactionId}</strong>
              </Typography>
              
              <Typography variant="body2" color="textSecondary">
                You will receive an email confirmation with details of your transaction. The exchange process typically takes 5-30 minutes to complete.
              </Typography>
            </>
          )}
        </DialogContent>
        
        <DialogActions>
          <Button onClick={() => setConfirmationOpen(false)}>Close</Button>
          <Button variant="contained" color="primary" onClick={() => {
            setConfirmationOpen(false);
            showNotification('You can track your exchange in your account', 'info');
          }}>
            View Transaction
          </Button>
        </DialogActions>
      </Dialog>

      {/* Troubleshooting Dialog */}
      <Dialog
        open={showTroubleshooting}
        onClose={() => setShowTroubleshooting(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ 
          bgcolor: '#f5f5f5',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <WarningIcon color="warning" />
            <span>Exchange Widget Troubleshooting</span>
          </Box>
          <IconButton 
            edge="end" 
            onClick={() => setShowTroubleshooting(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        
        <DialogContent>
          <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', mt: 1 }}>
            Common Issues & Solutions
          </Typography>
          
          <Box component="ul" sx={{ pl: 2 }}>
            <Box component="li" sx={{ mb: 1 }}>
              <Typography variant="body2">
                <strong>Ad Blockers or Privacy Extensions:</strong> These can interfere with the exchange widget. Try disabling them for this site.
              </Typography>
            </Box>
            
            <Box component="li" sx={{ mb: 1 }}>
              <Typography variant="body2">
                <strong>Network Issues:</strong> Ensure you have a stable internet connection.
              </Typography>
            </Box>
            
            <Box component="li" sx={{ mb: 1 }}>
              <Typography variant="body2">
                <strong>Browser Compatibility:</strong> Try using Chrome, Firefox, or Edge.
              </Typography>
            </Box>
            
            <Box component="li" sx={{ mb: 1 }}>
              <Typography variant="body2">
                <strong>Cookies Settings:</strong> Ensure cookies are enabled for our site.
              </Typography>
            </Box>
          </Box>
          
          <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', mt: 3 }}>
            Additional Steps
          </Typography>
          
          <Box sx={{ mb: 2 }}>
            <Typography variant="body2" paragraph>
              1. Clear your browser cache and cookies, then reload the page.
            </Typography>
            
            <Typography variant="body2" paragraph>
              2. Try using a private/incognito browser window.
            </Typography>
          </Box>
          
          <Alert severity="info" sx={{ mt: 2 }}>
            If issues persist, please contact support@transfer.co for assistance.
          </Alert>
        </DialogContent>
        
        <DialogActions>
          <Button onClick={() => setShowTroubleshooting(false)}>Close</Button>
          <Button 
            variant="contained" 
            color="primary"
            startIcon={<RefreshIcon />}
            onClick={() => {
              setShowTroubleshooting(false);
              reloadWidget();
            }}
          >
            Try Again
          </Button>
        </DialogActions>
      </Dialog>

      {/* Status Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert 
          onClose={() => setSnackbarOpen(false)} 
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ExchangeModule;