import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext.js';
import axiosInstance from '../services/api.js';
import {
  CircularProgress, Box, Typography, useTheme, useMediaQuery
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import ChatDisplay from './ChatDisplay.js';
import ChatInput from './ChatInput.js';

const Chat = ({ channel, isSidebarOpen, sidebarWidth = 270 }) => {
  const { user } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const [messages, setMessages] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [error, setError] = useState(null);
  const lastMessageRef = useRef(null);
  const abortControllerRef = useRef(null);

  // Optimized scroll to bottom with debounce
  const scrollToBottom = useCallback((behavior = 'smooth') => {
    if (!lastMessageRef.current) return;

    requestAnimationFrame(() => {
      lastMessageRef.current.scrollIntoView({ 
        behavior,
        block: 'end',
        inline: 'nearest'
      });
    });
  }, []);

  // Optimized message fetching with abort controller
  // In Chat.js, modify fetchMessages:
const fetchMessages = useCallback(async () => {
  if (!channel?._id || !user?._id) {
    console.log('Missing channel or user ID for fetching messages');
    return;
  }

  // Cancel any ongoing requests
  if (abortControllerRef.current) {
    abortControllerRef.current.abort();
  }
  abortControllerRef.current = new AbortController();

  setLoadingMessages(true);
  setError(null);

  try {
    const response = await axiosInstance.get(
      `/api/message/channel/${channel._id}/messages`,
      { signal: abortControllerRef.current.signal }
    );
    
    if (!response?.data?.success) {
      throw new Error('Invalid response when fetching messages');
    }

    const fetchedMessages = response.data.messages || [];
    setMessages(fetchedMessages);

    // Add delay to ensure DOM update
    setTimeout(() => {
      requestAnimationFrame(() => {
        if (lastMessageRef.current) {
          lastMessageRef.current.scrollIntoView({ 
            behavior: 'auto',
            block: 'end'
          });
        }
      });
    }, 100);

  } catch (error) {
    // ... error handling
  } finally {
    setLoadingMessages(false);
  }
}, [channel?._id, user?._id]);

  // Cleanup abort controller on unmount
  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  // Fetch messages when channel changes
  useEffect(() => {
    if (channel?._id && user?._id) {
      setMessages([]); // Clear messages before fetching new ones
      fetchMessages();
    }
  }, [channel?._id, user?._id, fetchMessages]);

  // Optimized new message handler
  const handleNewMessage = useCallback((newMessage) => {
    if (!newMessage?._id) {
      console.log('❌ No message ID found');
      return;
    }

    console.log('📝 Processing new message:', newMessage._id);

    setMessages(prevMessages => {
      if (prevMessages.some(msg => msg._id === newMessage._id)) {
        console.log('⚠️ Duplicate message detected:', newMessage._id);
        return prevMessages;
      }
      
      console.log('✅ Adding new message:', newMessage._id);
      const updatedMessages = [...prevMessages, newMessage];

      // Use MutationObserver to detect when the DOM is actually updated
      const observer = new MutationObserver(() => {
        console.log('🔄 DOM updated, attempting to scroll');
        if (lastMessageRef.current) {
          console.log('📜 Scrolling to new message:', newMessage._id);
          lastMessageRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'end'
          });
        }
        observer.disconnect();
      });

      // Start observing the chat container
      const chatContainer = document.querySelector('#chat-area');
      if (chatContainer) {
        observer.observe(chatContainer, {
          childList: true,
          subtree: true
        });
      }

      return updatedMessages;
    });
  }, []);

  // Optimized delete message handler - no scrolling
  const handleDeletedMessage = useCallback((deletedMessageId) => {
    setMessages(prevMessages => 
      prevMessages.filter(msg => msg._id !== deletedMessageId)
    );
  }, []);

  if (!user) {
    return (
      <Box sx={{ padding: 2, textAlign: 'center', backgroundColor: '#FF00FF' }}>
        <Typography>Please log in to view messages</Typography>
      </Box>
    );
  }

  const inputHeight = isMobile ? '80px' : '70px';
  const safeAreaBottom = isMobile ? 'env(safe-area-inset-bottom)' : '0px';
  return (
    <Box sx={{ 
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100dvh - 84px)', // Subtract header height (64px) + 20px separation
      maxHeight: 'calc(90dvh - 84px)', // Subtract header height (64px) + 20px separation
      position: 'relative',
      overflow: 'hidden',
      // backgroundColor: '#FF0000',
      marginTop: '24px' // Header height (64px) + 20px separation
    }}>
      <Box id="chat-area" sx={{
        flex: 1,
        overflowY: 'auto',
        marginTop: '1px',
        WebkitOverflowScrolling: 'touch',
        height: `calc(100dvh - ${inputHeight} - ${safeAreaBottom} - 84px)`, // Subtract header height (64px) + 20px separation
        paddingBottom: '1rem',
        // backgroundColor: '#00FF00'
      }}>
        <ChatDisplay
          messages={messages}
          channel={channel}
          lastMessageRef={lastMessageRef}
          onMessageDeleted={handleDeletedMessage}
          onNewMessage={handleNewMessage}
        />
      </Box>

      <Box sx={{
        position: 'fixed',
        bottom: 0,
        left: isSidebarOpen && !isMobile ? sidebarWidth : 0,
        right: 0,
        height: inputHeight,
        // backgroundColor: '#0000FF',
        transition: 'left 0.3s',
        zIndex: 1000,
        paddingBottom: safeAreaBottom,
        // top: '0px' // Header height (64px) + 20px separation
      }}>
        <ChatInput
          user={user}
          currentChannel={channel}
          onMessageSent={handleNewMessage}
          isSidebarOpen={isSidebarOpen}
          sidebarWidth={sidebarWidth}
        />
      </Box>
    </Box>
  );
};

export default Chat;