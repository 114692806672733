// ChatDisplay.js
import React, { useState, useEffect, useRef, useCallback, memo } from 'react';
import { Box, Typography, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, useTheme, ThemeProvider, useMediaQuery } from '@mui/material';
import { Close, Delete, Share, PlayArrow } from '@mui/icons-material';

import { useUploadQueue } from '../contexts/UploadQueueContext.js';
import UploadQueue from '../components/UploadQueue.js';
import { FileCard } from '../components/shared/FileCard.js';
import ShareModal from './ShareModal.js';
import axiosInstance from '../services/api.js';
import { Helmet } from 'react-helmet';
import FilesInChannel from './FilesInChannel.js';
import theme from '../theme.js'; // Import the global theme
import FullPreviewModal from './FullPreviewModal.js';

const MessageItem = memo(({ message, isLast, lastMessageRef, onFileUpdate, setModalState, setIsDeleting, messageUpdates }) => {
  const { uploadQueue } = useUploadQueue();
  const messageFiles = messageUpdates?.get(message?._id) || message?.fileUrls || [];
  const [previewModal, setPreviewModal] = useState({
    open: false,
    mediaUrl: '',
    mediaType: '',
    fileName: '',
    initialFileIndex: 0,
    messageFiles: [],
    isFullscreen: false
  });
  const hasContent = message?.content && message.content !== '(file attached)';
  const hasFiles = (messageFiles?.length > 0) || (message?.processingFiles?.length > 0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // useEffect(() => {
  //   if (messageFiles.length > 0) {
  //     console.log(`MessageItem: Updated files for message ${message._id}:`, messageFiles);
  //   }
  // }, [messageFiles, message._id]);
  

  const handleImageClick = useCallback((url, fileName, allFiles, index) => {
    if (!url) return;
    console.debug('Image preview clicked:', { url, fileName, index });
    setPreviewModal({
      open: true,
      mediaUrl: url,
      mediaType: 'image',
      fileName: fileName || '',
      initialFileIndex: index || 0,
      messageFiles: allFiles || []
    });
  }, []);

  const handleVideoClick = useCallback((url, fileName, allFiles, index) => {
    if (!url) return;
    console.debug('Video preview clicked:', { url, fileName, index });
    
    // Set preview modal with fullscreen video settings
    setPreviewModal({
      open: true,
      mediaUrl: url,
      mediaType: 'video',
      fileName: fileName || '',
      initialFileIndex: index || 0,
      messageFiles: allFiles || [],
      isFullscreen: true // Add flag for fullscreen
    });
  }, []);

  const handleClosePreview = useCallback(() => {
    setPreviewModal(prev => ({
      ...prev,
      open: false
    }));
  }, []);

  const handleFileUpdate = useCallback((fileUrl) => {
    if (fileUrl) {
      console.log(`MessageItem: File update received for message ${message._id}:`, fileUrl);
      onFileUpdate(message._id, fileUrl);
    }
  }, [message._id, onFileUpdate]);

// This is the corrected handleFileClick function with proper const declaration
const handleFileClick = useCallback((file) => {
  try {
    console.log('File attachment clicked:', {
      fileName: file.name,
      fileUrl: file.url || file.previewUrl,
      fileType: file.type,
      messageId: message._id,
      timestamp: new Date().toISOString()
    });

    // Ensure we have a valid URL
    if (!file.url && !file.previewUrl) {
      console.warn('File has no URL or previewUrl:', file);
      return;
    }

    // Use URL from file or fallback to previewUrl
    const fileUrl = file.url || file.previewUrl;

    // Prepare allFiles array for the preview modal
    let allFiles = [];
    if (messageFiles && messageFiles.length > 0) {
      allFiles = messageFiles;
    } else if (file.url || file.previewUrl) {
      allFiles = [fileUrl];
    }

    // Determine media type from file extension
    const fileName = file.name || fileUrl.split('/').pop().split('?')[0];
    const isVideo = fileName.match(/\.(mp4|mov|avi|webm)$/i);
    const isImage = fileName.match(/\.(jpg|jpeg|png|gif|webp)$/i);
    const mediaType = isVideo ? 'video' : isImage ? 'image' : 'other';
    
    // Find the index of the current file in all message files
    let fileIndex = -1;
    if (allFiles.length > 0) {
      fileIndex = allFiles.findIndex(url => {
        // Handle both string URLs and file objects
        if (typeof url === 'string') {
          return url === fileUrl;
        }
        return url.url === fileUrl || url.previewUrl === fileUrl;
      });
    }
    
    if (fileIndex === -1) fileIndex = 0;
    
    console.log('File preview data:', {
      mediaType,
      allFilesCount: allFiles.length,
      fileIndex,
      url: fileUrl
    });

    // Open the preview modal
    setPreviewModal({
      open: true,
      mediaUrl: fileUrl,
      mediaType: mediaType,
      fileName: fileName,
      initialFileIndex: fileIndex,
      files: allFiles
    });

    console.log('Preview modal opened successfully');
  } catch (error) {
    console.error('Error opening file preview:', error, {
      file,
      messageId: message._id
    });
  }
}, [message._id, messageFiles, setPreviewModal]);
  
  
  const renderImagePreview = useCallback((file) => {
    console.debug('Rendering image preview:', {
      fileName: file.name,
      url: file.url,
      type: file.type,
      messageId: message._id
    });

    const imageUrl = file.url?.includes('?') ? file.url : `${file.url}?wrap=0`;

    return (
      <Box 
        sx={{ 
          position: 'relative',
          width: '100%',
          height: '100%',
          borderRadius: '8px',
          overflow: 'hidden',
          backgroundColor: 'black',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        onClick={() => {
          if (file.status !== 'processing' || file.previewUrl) {
            console.debug('Image preview clicked', {
              url: imageUrl,
              messageId: message._id
            });
            handleFileClick(file);
          } else {
            console.log('File still processing, preview not available');
          }
        }}
      >
        <img
          src={imageUrl}
          alt={file.name}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain'
          }}
          onError={(e) => {
            console.error('Image loading error:', {
              fileName: file.name,
              url: imageUrl,
              error: e,
              messageId: message._id
            });
          }}
        />
      </Box>
    );
  }, [message._id, handleFileClick]);

  const renderVideoPreview = useCallback((file) => {
    console.debug('Rendering video preview:', {
      fileName: file.name,
      url: file.url,
      type: file.type,
      messageId: message._id
    });

    const videoUrl = `${file.url?.split('?')[0]}?wrap=0`;

    return (
      <Box 
        sx={{ 
          position: 'relative',
          width: '100%',
          height: '100%',
          borderRadius: '8px',
          overflow: 'hidden',
          backgroundColor: 'black',
          cursor: 'pointer'
        }}
        onClick={() => {
          console.debug('Video preview clicked', {
            url: file.url,
            messageId: message._id
          });
          handleVideoClick(file.url, file.name, messageFiles, messageFiles.indexOf(file.url));
        }}
      >
        <video
          src={videoUrl}
          controls={false} // Remove controls from preview
          preload="metadata"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'contain'
          }}
          onError={(e) => {
            console.error('Video loading error:', {
              fileName: file.name,
              originalUrl: file.url,
              processedUrl: videoUrl,
              error: e.target.error,
              messageId: message._id,
              videoElement: {
                networkState: e.target.networkState,
                readyState: e.target.readyState,
                error: e.target.error?.message,
                currentSrc: e.target.currentSrc
              }
            });
          }}
        >
          Your browser does not support the video tag.
        </video>
        
        {/* Play indicator overlay */}
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '48px',
            height: '48px',
            borderRadius: '50%',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
            }
          }}
        >
          <PlayArrow fontSize="large" />
        </Box>
      </Box>
    );
  }, [message._id, handleVideoClick, messageFiles]);

  const renderFiles = useCallback(() => {
    const fileMap = new Map();

    messageFiles.forEach((url) => {
      const fileName = url.split('/').pop().split('?')[0];
      const fileType = fileName.match(/\.(mp4|mov|avi|webm)$/i) ? 'video' : 
                      fileName.match(/\.(jpg|jpeg|png|gif|webp)$/i) ? 'image' : 'other';
      
      fileMap.set(fileName, {
        name: fileName,
        url: url,
        type: fileType,
        status: 'complete'
      });
    });

    message.processingFiles?.forEach((file) => {
      if (!fileMap.has(file.name) && !file.uploaded) {
        fileMap.set(file.name, {
          ...file,
          previewUrl: file.previewUrl,
          status: 'processing'
        });
      }
    });

    return (
      <Box sx={{ 
        display: 'grid', 
        gridTemplateColumns: {
          xs: 'repeat(auto-fill, minmax(150px, 1fr))',
          sm: 'repeat(auto-fill, minmax(300px, 1fr))'
        },
        gap: {
          xs: 1,
          sm: 2
        },
        padding: {
          xs: 1,
          sm: 2
        },
        pl: 4,
        mt: hasContent ? 2 : 0,
        backgroundColor: uploadQueue.length > 0 ? 'transparent' : 'transparent'
      }}>
        {Array.from(fileMap.entries()).map(([fileName, file]) => (
          <Box key={fileName} sx={{ 
            position: 'relative',
            paddingTop: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            width: '100%',
            borderRadius: {
              xs: '4px',
              sm: '8px'
            },
            margin: 'auto',
          }}>
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%'
            }}>
              {file.status === 'complete' ? (
                file.type === 'video' ? (
                  renderVideoPreview(file)
                ) : file.type === 'image' ? (
                  renderImagePreview(file)
                ) : (
                  <Box onClick={() => handleFileClick(file)}>
                    <FileCard
                      file={file}
                      onRemove={handleFileUpdate}
                      size={isMobile ? "small" : "medium"}
                      messageId={message._id}
                      sx={{
                        width: '100% !important',
                        height: '100% !important',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        cursor: 'pointer'
                      }}
                    />
                  </Box>
                )
              ) : (
                <FileCard
                  file={file}
                  onRemove={handleFileUpdate}
                  isNewUpload={file.status === 'processing'}
                  size={isMobile ? "small" : "medium"}
                  messageId={message._id}
                  onClick={() => handleFileClick(file)}
                  sx={{
                    cursor: 'pointer',
                    width: '100% !important',
                    height: '100% !important',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    '& img': {
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                      backgroundColor: 'black'
                    }
                  }}
                />
              )}
            </Box>
          </Box>
        ))}
      </Box>
    );
  }, [messageFiles, message.processingFiles, message._id, handleFileUpdate, handleFileClick, hasContent, isMobile, renderVideoPreview, renderImagePreview]);

  return (
    <Box component="div" position="relative">
      <Box ref={isLast ? lastMessageRef : null} sx={{ 
        mb: isMobile ? 0 : 2, 
        backgroundColor: 'background.paper', 
        borderRadius: 1, 
        boxShadow: '0 1px 3px rgba(255, 0, 0, 0.12)', 
        position: 'relative',
        p: { xs: 0, sm: 0 },
        pl: { xs: 2 },
        pr: { xs: 1 },
        ...(isMobile && {
          my: 1,
          mx: 0.5
        })
      }}>
        {isMobile && (
          <Box sx={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: 0.5,
            mb: 0.5,
            pt: 2
          }}>
            <Box sx={{ 
              width: 24,
              height: 24,
              borderRadius: '50%',
              overflow: 'hidden',
              flexShrink: 0,
              mt: 0.5
            }}>
              <img 
                src="/images/logo.png"

                alt="User Avatar" 
                style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
              />
            </Box>
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{
                pt: 0.5
              }}
            >
              {message.user_email}
            </Typography>
          </Box>
        )}

        {!isMobile && (
          <Box sx={{ 
            position: 'absolute', 
            left: 16, 
            top: 16,
            width: 40, 
            height: 40, 
            borderRadius: '50%', 
            overflow: 'hidden',
          }}>
            <img 
              src={`https://ui-avatars.com/api/?name=${message.user_email}&background=random`} 
              alt="User Avatar" 
              style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
            />
          </Box>
        )}

        <Box sx={{ 
          ml: isMobile ? '0px' : '72px',
          backgroundColor: 'grey.100', 
          borderRadius: 1, 
          p: isMobile ? 1 : 2,
          pt: 2,
          minHeight: isMobile ? '32px' : '56px',
        }}>
          {hasContent && (
            <Typography 
              variant="body1"
              component="div"
              sx={{
                wordBreak: 'break-word',
                mb: hasFiles ? 2 : 0,
                pl: 2,
                pt: 0
              }}
            >
              {message.content}
            </Typography>
          )}

          {hasFiles && renderFiles()}

          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            flexWrap: 'nowrap',
            gap: isMobile ? 0.25 : 1,
            mt: 1
          }}>
            <Typography 
              variant="caption" 
              color="grey.300"
              sx={{
                flexShrink: 1,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              ID: {message._id}
            </Typography>
            <Box sx={{ 
              display: 'flex', 
              gap: isMobile ? 0.25 : 1,
              flexShrink: 0
            }}>
              <IconButton
                size="small"
                onClick={() => {
                  setIsDeleting(true);
                  setModalState(prev => ({
                    ...prev,
                    messageToDelete: message,
                    deleteConfirmOpen: true
                  }));
                }}
                sx={{ 
                  color: 'grey.300',
                  padding: isMobile ? 0.25 : 1,
                }}
              >
                <Delete fontSize="small" />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => setModalState(prev => ({ 
                  ...prev, 
                  selectedMessage: { ...message, fileUrls: messageFiles }, 
                  shareModalOpen: true 
                }))}
                sx={{ 
                  color: 'grey.300',
                  padding: isMobile ? 0.25 : 1,
                }}
              >
                <Share fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>

      <FullPreviewModal
        open={previewModal.open}
        onClose={handleClosePreview}
        mediaUrl={previewModal.mediaUrl}
        mediaType={previewModal.mediaType}
        fileName={previewModal.fileName}
        initialFileIndex={previewModal.initialFileIndex}
        files={previewModal.messageFiles}
        isFullscreen={previewModal.isFullscreen}
      />
    </Box>
  );
});

const ChatDisplayContainer = ({ channel, messages, onMessageDeleted, lastMessageRef }) => {
  const [modalState, setModalState] = useState({
    deleteConfirmOpen: false,
    messageToDelete: null,
    shareModalOpen: false,
    selectedMessage: null
  });
  const [isDeleting, setIsDeleting] = useState(false);
  const [messageUpdates, setMessageUpdates] = useState(new Map());
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const handleMessageUpdate = (event) => {
      const { detail } = event;
      if (detail?.messageId && detail?.fileUrls) {
        console.log('🔄 ChatDisplay: Message File Update Event:', {
          messageId: detail.messageId,
          fileUrls: detail.fileUrls,
          timestamp: new Date().toISOString(),
          source: 'message-file-updated'
        });
        
        setMessageUpdates(prev => {
          const newUpdates = new Map(prev);
          const existingUrls = newUpdates.get(detail.messageId) || [];
          const combinedUrls = [...new Set([...existingUrls, ...detail.fileUrls])];
          console.log('✅ ChatDisplay: URLs Updated:', {
            messageId: detail.messageId,
            previousUrls: existingUrls,
            newUrls: detail.fileUrls,
            combinedUrls,
            timestamp: new Date().toISOString()
          });
          newUpdates.set(detail.messageId, combinedUrls);
          return newUpdates;
        });
      }
    };

    window.addEventListener('message-file-updated', handleMessageUpdate);
    return () => window.removeEventListener('message-file-updated', handleMessageUpdate);
  }, []);

  const handleFileUpdate = useCallback((messageId, fileUrl) => {
    console.log('📝 ChatDisplay: File Update Received:', {
      messageId,
      fileUrl,
      timestamp: new Date().toISOString(),
      source: 'handleFileUpdate'
    });
    
    setMessageUpdates(prev => {
      const newUpdates = new Map(prev);
      const currentUrls = newUpdates.get(messageId) || [];

      if (!currentUrls.includes(fileUrl)) {
        const updatedUrls = [...currentUrls, fileUrl];
        console.log('✅ ChatDisplay: New URL Added:', {
          messageId,
          newUrl: fileUrl,
          allUrls: updatedUrls,
          timestamp: new Date().toISOString()
        });
        newUpdates.set(messageId, updatedUrls);
      } else {
        console.log('ℹ️ ChatDisplay: URL Already Exists:', {
          messageId,
          existingUrl: fileUrl,
          timestamp: new Date().toISOString()
        });
      }
      return newUpdates;
    });
  }, []);

  const handleDelete = async () => {
    if (!modalState.messageToDelete?._id) return;

    try {
      const response = await axiosInstance.post(
        `/api/message/channel/${channel._id}/message/${modalState.messageToDelete._id}/confirmDelete`,
        { filesToDelete: modalState.messageToDelete.fileUrls || [] }
      );

      if (response.data.success) {
        onMessageDeleted?.(modalState.messageToDelete._id, false);
        setMessageUpdates(prev => {
          const updates = new Map(prev);
          updates.delete(modalState.messageToDelete._id);
          return updates;
        });
      }
    } catch (error) {
      console.error('Delete failed:', error);
    } finally {
      setModalState(prev => ({ ...prev, deleteConfirmOpen: false, messageToDelete: null }));
      setIsDeleting(false);
    }
  };

  if (!channel?._id) {
    return (
      <Box sx={{ 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100%',
        padding: isMobile ? 0 : 2,
        border: '0px solid #FF0000'
      }}>
        Please select a channel to view messages.
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', border: '0px solid #00FFFF' }}>
        <Box sx={{ 
          px: isMobile ? 0 : 2, 
          py: 0, 
          mt: '0px', 
          position: 'relative',
          zIndex: 1 
        }}>
          <FilesInChannel channel={channel} />
        </Box>

        <Box sx={{ 
          flex: 1, 
          overflowY: 'auto',
          px: isMobile ? 0 : 2,
          pt: isMobile ? 0 : 2,
          pb: isMobile ? 0 : 6,
          WebkitOverflowScrolling: 'touch',
        }}>
          {messages.map((message, index) => (
            <MessageItem 
              key={message._id}
              message={message} 
              isLast={index === messages.length - 1}
              lastMessageRef={lastMessageRef}
              onFileUpdate={handleFileUpdate}
              setModalState={setModalState}
              setIsDeleting={setIsDeleting}
              messageUpdates={messageUpdates}
            />
          ))}
          <div ref={lastMessageRef} />
        </Box>

        <Dialog 
          open={modalState.deleteConfirmOpen} 
          onClose={() => setModalState(prev => ({ ...prev, deleteConfirmOpen: false }))}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Delete Message?</DialogTitle>
          <DialogContent>
            <Typography>This action cannot be undone.</Typography>
            {modalState.messageToDelete?.fileUrls?.length > 0 && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle2" sx={{ mb: 1 }}>
                  Attached files will also be deleted:
                </Typography>
                <Box sx={{ 
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
                  gap: 1
                }}>
                  {modalState.messageToDelete.fileUrls.map((url, index) => (
                    <Box key={index} sx={{ mb: 1 }}>
                      <FileCard 
                        file={{ 
                          url, 
                          name: url.split('/').pop().split('?')[0]
                        }} 
                        isPreview={true} 
                        customProgress={100} 
                        size="small"
                        showFileName={true}
                      />
                      <Typography 
                        variant="caption" 
                        sx={{ 
                          display: 'block',
                          mt: 0.5,
                          color: 'text.secondary',
                          wordBreak: 'break-all'
                        }}
                      >
                        {url}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setModalState(prev => ({ ...prev, deleteConfirmOpen: false }))}>
              Cancel
            </Button>
            <Button onClick={handleDelete} color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <ShareModal open={modalState.shareModalOpen} message={modalState.selectedMessage} onClose={() => setModalState(prev => ({ ...prev, shareModalOpen: false }))} />
      </Box>
    </ThemeProvider>
  );
};

export default memo(ChatDisplayContainer);