import React, { useState, useEffect, useMemo } from 'react';
import { Box, Paper, Typography, Grid } from '@mui/material';
import { useAuth } from '../contexts/AuthContext.js';

const SessionMonitor = ({ 
    showUserDetails = true, 
    showSessionMetrics = true,
    variant = 'full', // 'full', 'compact', or 'minimal'
    sx = {} 
}) => {
    const { 
        user,
        accessToken,
        isAuthenticated,
        accessTokenExpiration: contextTokenExpiration,
        refreshTokenExpiration: contextRefreshExpiration
    } = useAuth();

    const [sessionRefreshCount, setSessionRefreshCount] = useState(0);
    const [lastRefreshTime, setLastRefreshTime] = useState(null);
    const [sessionStartTime] = useState(Date.now());
    const [timeRemaining, setTimeRemaining] = useState(null);

    // Format duration helper
    const formatDuration = (ms, format = 'long') => {
        if (!ms) return format === 'long' ? '0 hours 0 minutes 0 seconds' : '0h 0m 0s';
        const seconds = Math.floor((ms / 1000) % 60);
        const minutes = Math.floor((ms / (1000 * 60)) % 60);
        const hours = Math.floor((ms / (1000 * 60 * 60)) % 24);
        const days = Math.floor(ms / (1000 * 60 * 60 * 24));

        return format === 'long'
            ? `${days ? `${days} days ` : ''}${hours}h ${minutes}m ${seconds}s`
            : `${days ? `${days}d ` : ''}${hours}h ${minutes}m ${seconds}s`;
    };

    // Update both session duration and time remaining
    useEffect(() => {
        const timer = setInterval(() => {
            const now = Date.now();

            // Update time remaining
            if (contextTokenExpiration) {
                const remaining = contextTokenExpiration - now;
                setTimeRemaining(remaining > 0 ? remaining : 0);
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [contextTokenExpiration]);

    // Session state management
    const sessionState = useMemo(() => {
        const now = Date.now();
        const userId = user?._id || user?.id;

        const validationDetails = {
            isAuthenticated,
            hasUser: !!user,
            hasUserId: !!userId,
            hasAccessToken: !!accessToken,
            hasTokenExpiration: !!contextTokenExpiration,
            tokenValid: contextTokenExpiration ? contextTokenExpiration > now : false,
            refreshValid: contextRefreshExpiration ? contextRefreshExpiration > now : false,
            timeRemaining
        };

        const userDetails = user ? {
            id: userId,
            email: user.email,
            name: user.name || 'N/A',
            roles: user.roles || [],
            permissions: user.permissions || [],
            isVerified: user.isVerified || false
        } : null;

        const isValid = validationDetails.isAuthenticated && 
                        validationDetails.hasAccessToken && 
                        validationDetails.hasUser &&
                        validationDetails.tokenValid;

        return {
            isValid,
            validation: validationDetails,
            user: userDetails,
            timeRemaining: timeRemaining != null ? formatDuration(timeRemaining, 'short') : 'N/A',
            expiresAt: contextTokenExpiration ? new Date(contextTokenExpiration).toLocaleString() : 'N/A',
            sessionDuration: formatDuration(Date.now() - sessionStartTime, 'short'),
            refreshCount: sessionRefreshCount,
            lastRefresh: lastRefreshTime ? new Date(lastRefreshTime).toLocaleString() : 'Never'
        };
    }, [
        user, 
        isAuthenticated, 
        accessToken, 
        contextTokenExpiration,
        contextRefreshExpiration,
        timeRemaining,
        sessionStartTime,
        sessionRefreshCount,
        lastRefreshTime
    ]);

    // Render minimal version (just time remaining)
    if (variant === 'minimal') {
        return (
            <Typography 
                variant="caption" 
                color="inherit" 
                sx={{ opacity: 0.9, ...sx }}
            >
                {sessionState.timeRemaining}
            </Typography>
        );
    }

    // Render compact version (status + time)
    if (variant === 'compact') {
        return (
            <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 1,
                ...sx 
            }}>
                <Typography variant="body2">
                    {sessionState.isValid ? '✓' : '✗'}
                </Typography>
                <Typography variant="body2">
                    {sessionState.timeRemaining}
                </Typography>
            </Box>
        );
    }

    // Render full version
    return (
        <Box sx={{ mb: 3, ...sx }}>
            {/* Session Status */}
            <Paper sx={{ 
                p: 2, 
                mb: 2, 
                bgcolor: sessionState.isValid ? 'success.light' : 'error.light',
                color: 'white' 
            }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6">
                        {sessionState.isValid ? '✓ Session Active' : '✗ Session Invalid'}
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'monospace' }}>
                        {sessionState.timeRemaining}
                    </Typography>
                </Box>
            </Paper>

            {/* Details Grid */}
            <Grid container spacing={2}>
                {/* User Profile */}
                {showUserDetails && sessionState.user && (
                    <Grid item xs={12} md={6}>
                        <Paper sx={{ p: 2, height: '100%' }}>
                            <Typography variant="subtitle1" color="primary" gutterBottom>
                                User Profile
                            </Typography>
                            <Box sx={{ ml: 2 }}>
                                <Typography variant="body2">Name: {sessionState.user.name}</Typography>
                                <Typography variant="body2">Email: {sessionState.user.email}</Typography>
                                <Typography variant="body2">
                                    Status: {sessionState.user.isVerified ? 
                                        <span style={{ color: 'green' }}>✓ Verified</span> : 
                                        <span style={{ color: 'orange' }}>⚠ Unverified</span>
                                    }
                                </Typography>
                                {sessionState.user.roles.length > 0 && (
                                    <Typography variant="body2">
                                        Roles: {sessionState.user.roles.join(', ')}
                                    </Typography>
                                )}
                            </Box>
                        </Paper>
                    </Grid>
                )}

                {/* Session Info */}
                {showSessionMetrics && (
                    <Grid item xs={12} md={showUserDetails ? 6 : 12}>
                        <Paper sx={{ p: 2, height: '100%' }}>
                            <Typography variant="subtitle1" color="primary" gutterBottom>
                                Session Metrics
                            </Typography>
                            <Box sx={{ ml: 2 }}>
                                <Typography variant="body2">
                                    Duration: {sessionState.sessionDuration}
                                </Typography>
                                <Typography variant="body2">
                                    Refresh Count: {sessionState.refreshCount}
                                </Typography>
                                <Typography variant="body2">
                                    Expires: {sessionState.expiresAt}
                                </Typography>
                                <Typography variant="body2">
                                    Last Refresh: {sessionState.lastRefresh}
                                </Typography>
                            </Box>
                        </Paper>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default SessionMonitor;