import React, { useState, useEffect, useCallback } from 'react';
import { Box, Card, CardContent, Typography, IconButton, CircularProgress } from '@mui/material';
import { Close, ZoomIn } from '@mui/icons-material';
import { useUploadQueue } from '../../contexts/UploadQueueContext.js';
import FullPreviewModal from '../../components/FullPreviewModal.js';


const PREVIEW_RETRY_LIMIT = 3;
const PREVIEW_RETRY_DELAY = 1000;

export const FileCard = ({
  file,
  onRemove,
  customProgress,
  size = 'default',
  showFileName = true,
  messageId
}) => {
  const { uploadLogs, uploadProgress } = useUploadQueue();
  const [previewUrl, setPreviewUrl] = useState('');
  const [mediaType, setMediaType] = useState('');
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [uploadStatus, setUploadStatus] = useState({
    stage: 'idle',
    progress: 0,
    details: null
  });

  // Enhanced upload status tracking
  useEffect(() => {
    if (file.name) {
      const logs = uploadLogs[file.name] || [];
      const lastLog = logs[logs.length - 1];
      const progress = uploadProgress[file.name] || customProgress || 0;
      
      const determineStatus = (log) => {
        if (!log) return { stage: 'idle', progress: 0, details: null };

        // Status determination logic
        if (log.includes('Uploading chunk')) {
          const match = log.match(/chunk (\d+)\/(\d+)/);
          return {
            stage: 'uploading',
            progress,
            details: match ? {
              currentChunk: parseInt(match[1]),
              totalChunks: parseInt(match[2])
            } : null
          };
        }

        if (log.includes('Stitching') || log.includes('Combining chunks')) {
          return {
            stage: 'stitching',
            progress: 99,
            details: { message: 'Combining file chunks...' }
          };
        }

        if (log.includes('Finalizing') || log.includes('Processing complete')) {
          return {
            stage: 'finalizing',
            progress: 99,
            details: { message: 'Finalizing upload...' }
          };
        }

        if (log.includes('complete')) {
          return {
            stage: 'complete',
            progress: 100,
            details: null
          };
        }

        if (log.includes('error')) {
          return {
            stage: 'error',
            progress: 0,
            details: log.replace('❌ ', '')
          };
        }

        return { stage: 'idle', progress: 0, details: null };
      };

      const nextStatus = determineStatus(lastLog);

      // Only update if there's a meaningful change
      if (nextStatus.stage !== uploadStatus.stage || 
          nextStatus.progress !== uploadStatus.progress ||
          JSON.stringify(nextStatus.details) !== JSON.stringify(uploadStatus.details)) {
        setUploadStatus(nextStatus);
      }
    }
  }, [file.name, uploadLogs, uploadProgress, customProgress, uploadStatus]);

  // Determine media type
  useEffect(() => {
    if (!file) return;

    const fileType = file.type || '';
    const fileName = file.name || '';
    const extension = fileName.split('.').pop().toLowerCase();

    if (/^image\//i.test(fileType) || /\.(jpg|jpeg|png|gif|webp|svg|bmp)$/i.test(fileName)) {
      setMediaType('image');
    } else if (/^video\//i.test(fileType) || /\.(mp4|webm|ogg|mov|avi)$/i.test(fileName)) {
      setMediaType('video');
    } else if (/^application\/pdf$/i.test(fileType) || /\.pdf$/i.test(fileName)) {
      setMediaType('pdf');
    } else {
      setMediaType('other');
    }
  }, [file]);

  // Handle preview URL
  useEffect(() => {
    let objectUrl = '';
    let retryTimeout;

    const createPreview = async () => {
      try {
        if (file instanceof File) {
          objectUrl = URL.createObjectURL(file);
          setPreviewUrl(objectUrl);
        } else if (file.url) {
          setPreviewUrl(file.url);
        }
        setError(null);
      } catch (err) {
        console.error(`Error creating preview for ${file.name}:`, err);
        
        if (retryCount < PREVIEW_RETRY_LIMIT) {
          retryTimeout = setTimeout(() => {
            setRetryCount(prev => prev + 1);
          }, PREVIEW_RETRY_DELAY);
        } else {
          setError('Failed to load preview');
        }
      }
    };

    if (file) {
      createPreview();
    }

    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
      if (retryTimeout) {
        clearTimeout(retryTimeout);
      }
    };
  }, [file, retryCount]);

  const handlePreviewClick = useCallback(() => {
    if (mediaType === 'image' || mediaType === 'video') {
      setPreviewOpen(true);
    }
  }, [mediaType]);

  const renderPreviewModal = () => (
    <FullPreviewModal
      open={previewOpen}
      onClose={() => setPreviewOpen(false)}
      mediaType={mediaType}
      previewUrl={previewUrl}
      fileName={file.name}
    />
  );

  

  const renderStatus = () => {
    const getStatusDisplay = () => {
      switch (uploadStatus.stage) {
        case 'uploading':
          return {
            progress: uploadStatus.progress,
            message: uploadStatus.details ? 
              `Chunk ${uploadStatus.details.currentChunk}/${uploadStatus.details.totalChunks}` : 
              'Uploading...'
          };
        case 'stitching':
          return {
            progress: undefined,
            message: 'Combining chunks...'
          };
        case 'finalizing':
          return {
            progress: undefined,
            message: 'Finalizing upload...'
          };
        case 'complete':
          return {
            progress: 100,
            message: 'Upload complete'
          };
        case 'error':
          return {
            progress: undefined,
            message: uploadStatus.details,
            error: true
          };
        default:
          return null;
      }
    };

    const status = getStatusDisplay();
    if (!status) return null;

    return (
      <Box 
        sx={{ 
          position: 'absolute', 
          top: 0, 
          left: 0, 
          right: 0, 
          bottom: 0, 
          bgcolor: status.error ? 'rgba(255,0,0,0.2)' : 'rgba(0,0,0,0.5)', 
          color: 'white', 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center', 
          flexDirection: 'column', 
          gap: 1 
        }}
      >
        <CircularProgress 
          variant={typeof status.progress === 'number' ? 'determinate' : 'indeterminate'} 
          value={status.progress} 
          size={24} 
          sx={{ color: status.error ? 'error.main' : 'white' }} 
        />
        <Typography 
          variant="caption" 
          sx={{ 
            color: status.error ? 'error.main' : 'white', 
            textAlign: 'center',
            px: 1
          }}
        >
          {status.message}
        </Typography>
      </Box>
    );
  };

  const renderPreview = useCallback(() => {
    if (!previewUrl) {
      return (
        <Box
          sx={{
            width: '100%',
            height: size === 'small' ? 80 : 120,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'grey.100',
            borderRadius: 1
          }}
        >
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            <Typography variant="caption" color="text.secondary">
              No preview
            </Typography>
          )}
        </Box>
      );
    }

    switch (mediaType) {
      case 'image':
        return (
          <Box
            sx={{
              position: 'relative',
              cursor: 'pointer',
              '&:hover': {
                '& .zoom-icon': {
                  opacity: 1
                }
              }
            }}
            onClick={handlePreviewClick}
          >
            <Box
              component="img"
              src={previewUrl}
              alt={file.name}
              onLoad={() => setIsLoading(false)}
              onError={() => {
                if (retryCount < PREVIEW_RETRY_LIMIT) {
                  setRetryCount(prev => prev + 1);
                } else {
                  setError('Failed to load preview');
                  setIsLoading(false);
                }
              }}
              sx={{
                width: '100%',
                height: size === 'small' ? 80 : 120,
                objectFit: 'cover',
                borderRadius: 1,
                display: isLoading ? 'none' : 'block'
              }}
            />
            <Box
              className="zoom-icon"
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                opacity: 0,
                transition: 'opacity 0.2s',
                bgcolor: 'rgba(0,0,0,0.5)',
                borderRadius: '50%',
                p: 1
              }}
            >
              <ZoomIn sx={{ color: 'white' }} />
            </Box>
          </Box>
        );

      case 'video':
        return (
          <Box
            sx={{ 
              position: 'relative', 
              cursor: 'pointer',
              width: '100%',
              height: size === 'small' ? 80 : 120,
              borderRadius: 1,
              overflow: 'hidden'
            }}
            onClick={handlePreviewClick}
          >
            <Box 
              component="video"
              src={`${previewUrl}?wrap=0`}
              controls
              playsInline
              preload="metadata"
              onLoadedData={() => setIsLoading(false)}
              onError={() => {
                setError('Failed to load video');
                setIsLoading(false);
              }}
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: 1
              }}
            />
            {isLoading && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  bgcolor: 'rgba(0,0,0,0.1)'
                }}
              >
                <CircularProgress size={24} />
              </Box>
            )}
          </Box>
        );

      default:
        return (
          <Box
            sx={{
              width: '100%',
              height: size === 'small' ? 80 : 120,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'grey.100',
              borderRadius: 1
            }}
          >
            <Typography variant="caption" color="text.secondary">
              {file.name}
            </Typography>
          </Box>
        );
    }
  }, [mediaType, previewUrl, file, size, isLoading, retryCount, handlePreviewClick]);

  return (
    <>
      <Card
        sx={{
          position: 'relative',
          borderRadius: 1,
          overflow: 'hidden',
          height: '100%',
          transition: 'transform 0.2s',
          ...(uploadStatus.stage === 'complete' && {
            '&::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              border: 2,
              borderColor: 'success.main',
              borderRadius: 'inherit',
              animation: 'pulse 2s infinite'
            }
          })
        }}
      >
        {onRemove && (
          <IconButton
            size="small"
            onClick={() => onRemove(file.name)}
            sx={{
              position: 'absolute',
              top: 4,
              right: 4,
              zIndex: 2,
              bgcolor: 'rgba(255, 255, 255, 0.8)',
              '&:hover': {
                bgcolor: 'rgba(255, 255, 255, 0.9)'
              }
            }}
          >
            <Close fontSize="small" />
          </IconButton>
        )}

        <Box sx={{ position: 'relative' }}>
          {renderPreview()}
          {renderStatus()}
        </Box>

        {showFileName && (
          <CardContent sx={{ p: 1 }}>
            <Typography
              variant="caption"
              noWrap
              sx={{
                display: 'block',
                textAlign: 'left',
                fontSize: '0.75rem'
              }}
            >
              {file.name}
            </Typography>
          </CardContent>
        )}

        <style>
          {`
            @keyframes pulse {
              0% {
                opacity: 1;
                transform: scale(1);
              }
              50% {
                opacity: 0.5;
                transform: scale(1.02);
              }
              100% {
                opacity: 1;
                transform: scale(1);
              }
            }
          `}
        </style>
      </Card>

      {renderPreviewModal()}
    </>
  );
};