import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, CircularProgress, useMediaQuery, Snackbar } from '@mui/material';
import { useAuth } from '../contexts/AuthContext.js';
import Header from '../components/Header.js';
import Sidebar from '../components/Sidebar.js';
import Chat from '../components/Chat.js';
import axiosInstance from '../services/api.js';
import DashboardLayout from '../components/DashboardLayout.js';
import { useChatTitle } from '../contexts/ChatTitleContext.js';
import { Helmet } from 'react-helmet-async';

const sidebarWidth = 270; // Sidebar width

const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 600px)'); // Mobile responsiveness

  const { user, accessToken, isAuthenticated, loading: authLoading } = useAuth();
  const { setChatTitle } = useChatTitle();

  const [channels, setChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [lastActiveMessages, setLastActiveMessages] = useState({});
  const [lastActiveChannel, setLastActiveChannel] = useState(null);
  const [fetchError, setFetchError] = useState(null);
  const [isCreateChannelDialogOpen, setIsCreateChannelDialogOpen] = useState(false);

  const handleToggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  // Helper function to fetch last active messages
  const fetchLastActiveMessage = useCallback(async (channelId) => {
    if (!user?._id) return null;
    
    try {
      const response = await axiosInstance.get(`/api/users/${user._id}/lastActiveMessage/${channelId}`);
      return response.data.lastActiveMessage || null;
    } catch (error) {
      console.error('Error fetching last active message:', error);
      return null;
    }
  }, [user]);

  // Fetch messages for the selected channel
  const fetchChannelMessages = useCallback(async (channelId) => {
    try {
      if (!channelId) {
        console.warn('Invalid channel ID for message fetching');
        return;
      }

      const response = await axiosInstance.get(`/api/message/channel/${channelId}/messages`);
      if (!response?.data) {
        setLastActiveMessages(prev => ({ ...prev, [channelId]: [] }));
        return;
      }

      const messages = response.data.messages || [];
      setLastActiveMessages(prev => ({ ...prev, [channelId]: messages }));
      console.log('✅ Messages loaded for channel:', channelId, `Total: ${messages.length}`);
    } catch (error) {
      console.error('❌ Error loading messages:', error);
      setLastActiveMessages(prev => ({ ...prev, [channelId]: [] }));
    }
  }, []);

  const handleChannelSelect = useCallback(async (channel) => {
    if (!channel?._id || !user?._id) {
      console.error("Invalid channel selection:", { channel, userId: user?._id });
      return;
    }

    try {
      setSelectedChannel(channel);
      setLastActiveChannel(channel._id);
      setChatTitle(channel.name);

      await axiosInstance.put(`/api/users/${user._id}/lastActiveChannel`, {
        lastActiveChannel: channel._id,
      });

      if (isMobile) {
        setIsSidebarOpen(false);
      }
    } catch (error) {
      console.error("Channel selection error:", error);
    }
  }, [user, isMobile, setChatTitle]);

  const fetchChannels = useCallback(async () => {
    if (!user?._id || !accessToken) return;

    console.log('🔄 Fetching channels and initializing data...');
    try {
      const response = await axiosInstance.get(`/api/channels/${user._id}`);
      const { channels: fetchedChannels = [], lastActiveChannel: lastActiveChannelId = null } = response.data;

      if (fetchedChannels.length === 0) {
        setIsCreateChannelDialogOpen(true);
        setChannels([]);
        return;
      }

      setChannels(fetchedChannels);
      let channelToSelect = fetchedChannels.find(ch => ch._id === lastActiveChannelId) || fetchedChannels[0];
      if (channelToSelect) {
        setTimeout(() => handleChannelSelect(channelToSelect), 0);
      }
    } catch (error) {
      console.error("❌ Error fetching channels:", error);
      setFetchError(error.response?.data?.message || 'Failed to fetch channels');
      setChannels([]);
    }
  }, [user, accessToken, handleChannelSelect]);

  useEffect(() => {
    if (!authLoading && isAuthenticated && user && accessToken) {
      fetchChannels();
    }
  }, [authLoading, isAuthenticated, user, accessToken, fetchChannels]);

  return (
    <>
      <Helmet>
        <title>Dashboard - {user?.username || 'User Profile'}</title>
        <meta name="description" content="Manage your channels and messages efficiently in the dashboard." />
        <meta name="robots" content="index, follow" />
        {/* Add Open Graph tags or other SEO metadata here */}
        <meta property="og:title" content={`Dashboard - ${user?.username || 'User Profile'}`} />
        <meta property="og:description" content="Manage your channels and messages efficiently in the dashboard." />
        <meta property="og:image" content="URL-to-image" />
        <meta property="og:url" content="https://www.vau7t.com/dashboard" />
      </Helmet>

      <DashboardLayout
        sidebarWidth={sidebarWidth}
        isSidebarOpen={isSidebarOpen}
        header={<Header onToggleSidebar={handleToggleSidebar} isSidebarOpen={isSidebarOpen} />}
        sidebar={
          <Sidebar 
            selectedChannel={selectedChannel} 
            handleChannelSelect={handleChannelSelect} 
            channels={channels}
            setChannels={setChannels}  
            user={user}  
            sidebarWidth={sidebarWidth}
            isSidebarOpen={isSidebarOpen}
            onToggleSidebar={handleToggleSidebar}
          />
        }
        chat={
          <Chat 
            channel={selectedChannel} 
            isSidebarOpen={isSidebarOpen} 
            sidebarWidth={sidebarWidth}
            messages={lastActiveMessages[selectedChannel?._id] || []}
            onMessageSent={() => isMobile && setIsSidebarOpen(false)} // ✅ Close sidebar on mobile after sending a message
          />
        }
      />
    </>
  );
};

export default Dashboard;
