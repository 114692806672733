import axios from 'axios';
import { getAuthToken, removeAuthToken } from '../utils/authUtils.js';

// Base URL configuration based on environment
const getBaseUrl = () =>
  process.env.NODE_ENV === 'development' 
    ? 'http://localhost:5000' 
    : 'https://www.transfer.co';

const BASE_URL = process.env.REACT_APP_API_URL || getBaseUrl();

// console.log('🌐 API Base URL:', BASE_URL, '(Environment:', process.env.NODE_ENV, ')');

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Cache-Control': 'no-cache'
  }
});

axiosInstance.interceptors.request.use(
  (config) => {
    // console.log('🔄 API Request:', {
    //   url: config.url,
    //   method: config.method,
    //   hasToken: !!config.headers.Authorization,
    //   environment: process.env.NODE_ENV
    // });
    const token = getAuthToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    console.error('❌ Request Error:', {
      message: error.message,
      code: error.code
    });
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    // console.log('✅ API Response:', {
    //   url: response.config.url,
    //   status: response.status,
    //   hasData: !!response.data
    // });
    return response;
  },
  async (error) => {
    console.error('❌ Response Error:', {
      message: error.message,
      code: error.code,
      status: error.response?.status,
      url: error.config?.url
    });

    if (error.code === 'ERR_NETWORK') {
      console.error('🔌 Server connection failed:', {
        baseUrl: BASE_URL,
        environment: process.env.NODE_ENV
      });
      error.customMessage = 'Unable to connect to server. Please check your connection.';
    }

    if (error.code === 'ECONNABORTED') {
      console.error('⏰ Request timeout:', {
        baseUrl: BASE_URL,
        environment: process.env.NODE_ENV
      });
      error.customMessage = 'Request timeout. Please try again.';
    }

    if (error.response?.status >= 500) {
      console.error('🔥 Server error:', {
        data: error.response.data,
        environment: process.env.NODE_ENV
      });
      error.customMessage = 'Server error. Please try again later.';
    }

    if (error.response?.status === 401) {
      console.error('🔒 Authentication error:', {
        data: error.response.data,
        environment: process.env.NODE_ENV
      });
      error.customMessage = 'Authentication failed. Please log in again.';
    }

    if (error.response?.status === 401 || error.response?.status === 403) {
      removeAuthToken();
      window.location.href = '/#/login';
    }

    return Promise.reject(error);
  }
);

// Health check function
export const checkServerHealth = async () => {
  try {
    console.log('🏥 Checking server health...', {
      baseUrl: BASE_URL,
      environment: process.env.NODE_ENV
    });
    const response = await axiosInstance.get('/api/health');
    console.log('✅ Server is healthy:', response.data);
    return true;
  } catch (error) {
    console.error('❌ Server health check failed:', {
      message: error.message,
      code: error.code,
      url: error.config?.url,
      environment: process.env.NODE_ENV
    });
    return false;
  }
};

// API route constants
export const API_ROUTES = {
  AUTH: {
    LOGIN: '/api/users/login',
    REGISTER: '/api/users/register',
    LOGOUT: '/api/users/logout',
    REFRESH_TOKEN: '/api/auth/tokens/refresh',
    GENERATE_TOKENS: '/api/auth/tokens/generate'
  },
  USERS: {
    PROFILE: '/api/users/profile',
    UPDATE: '/api/users/update',
    DELETE: '/api/users/delete'
  },
  HEALTH: '/api/health'
};

export default axiosInstance;
export { BASE_URL, getBaseUrl };