import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Box, IconButton, Drawer, List, Button, Typography, Dialog, DialogContent, DialogActions, DialogTitle, Slide, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext.js';
import axiosInstance from '../services/api.js';
import { useChatTitle } from '../contexts/ChatTitleContext.js';
import SessionMonitor from '../components/SessionMonitor.js';
import ExchangeModule from '../components/ExchangeModule.js';
import { Close } from '@mui/icons-material';

// Create a simplified ExchangeDialog component
const ExchangeDialog = ({ open, onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth={true}
      fullScreen={isMobile}
      sx={{
        zIndex: 9999,
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
        }
      }}
      PaperProps={{
        sx: {
          borderRadius: isMobile ? 0 : '16px',
          overflow: 'hidden',
          zIndex: 10000,
          bgcolor: 'rgba(255, 255, 255, 0.95)',
          backdropFilter: 'blur(10px)',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.3)',
        }
      }}
    >
      <Box sx={{ position: 'relative', p: 0 }}>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            zIndex: 10001,
            color: 'white',
            bgcolor: 'rgba(0, 0, 0, 0.7)',
            '&:hover': {
              bgcolor: 'rgba(132, 255, 0, 0.8)',
            },
          }}
          aria-label="close exchange dialog"
        >
          <Close />
        </IconButton>
        
        <DialogContent sx={{ p: 0, zIndex: 10000 }}>
          <ExchangeModule />
        </DialogContent>
      </Box>
    </Dialog>
  );
};

const Header = ({ showLogin = true, showDrawer = true, scrollDirection = 'up', hasScrolled = false, onToggleSidebar }) => {
  const navigate = useNavigate();
  const [isSpinning, setIsSpinning] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { user, logout } = useAuth();
  const { chatTitle } = useChatTitle();
  const [exchangeDialogOpen, setExchangeDialogOpen] = useState(false);
  const [confirmLogoutOpen, setConfirmLogoutOpen] = useState(false);
  const [launchFailed, setLaunchFailed] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const headerHeight = 60; // Define header height for consistency
  const logoRef = useRef(null);

  // Central function to handle exchange dialog opening - no confirmation, directly open
  const handleOpenExchangeDialog = useCallback(() => {
    // Trigger spinning animation
    setIsSpinning(true);
    
    // Open dialog locally - no redirection to external sites
    setExchangeDialogOpen(true);
    
    // Reset spinning after animation completes
    setTimeout(() => setIsSpinning(false), 1000);
    
    // Reset any previous failure state
    setLaunchFailed(false);
  }, []);

  // Listen for global exchange dialog events
  useEffect(() => {
    const handleGlobalOpen = () => {
      handleOpenExchangeDialog();
    };
    
    // Add event listener for custom event
    window.addEventListener('openExchangeDialog', handleGlobalOpen);
    
    // Create a global function that other components can call
    window.openExchangeDialog = () => {
      handleOpenExchangeDialog();
    };
    
    return () => {
      window.removeEventListener('openExchangeDialog', handleGlobalOpen);
      delete window.openExchangeDialog;
    };
  }, [handleOpenExchangeDialog]);

  // Create a global callback for the exchange module to signal failures - simplified
  useEffect(() => {
    window.exchangeLoadFailed = () => {
      console.error('Exchange module reported loading failure');
      setLaunchFailed(true);
    };
    
    return () => {
      delete window.exchangeLoadFailed;
    };
  }, []);

  // Handle logo click to show animation and open exchange dialog
  const handleLogoClick = useCallback(() => {
    handleOpenExchangeDialog();
  }, [handleOpenExchangeDialog]);

  // Toggle drawer open/close
  const toggleDrawer = useCallback(
    (open) => () => {
      setIsDrawerOpen(open);
    },
    []
  );

  // Show logout confirmation dialog
  const handleLogoutClick = useCallback((e) => {
    e.preventDefault();
    setConfirmLogoutOpen(true);
    if (isDrawerOpen) {
      setIsDrawerOpen(false);
    }
  }, [isDrawerOpen]);

  // Close logout confirmation dialog
  const handleCancelLogout = useCallback(() => {
    setConfirmLogoutOpen(false);
  }, []);

  // Handle actual logout process
  const handleConfirmLogout = useCallback(async () => {
    try {
      await axiosInstance.post('/api/auth/logout', {}, { withCredentials: true });
      await logout();
      setConfirmLogoutOpen(false);
      navigate('/login');
    } catch (error) {
      console.error('❌ Logout failed:', error.response?.data || error.message);
      setConfirmLogoutOpen(false);
    }
  }, [logout, navigate]);

  // Navigation items for drawer menu
  const navItems = user ? (
    <>
      <Button color="inherit" fullWidth sx={{ textTransform: 'none', color:'black', p: 0 }}>
        <SessionMonitor variant="minimal" showUserDetails={true} />
      </Button>

      <NavLink to="/dashboard" style={{ textDecoration: 'none', width: '100%' }} onClick={() => setIsDrawerOpen(false)}>
        <Button color="inherit" fullWidth sx={{ textTransform: 'none', p: 0 }}>Dashboard</Button>
      </NavLink>

      <NavLink to="/traffic" style={{ textDecoration: 'none', width: '100%' }} onClick={() => setIsDrawerOpen(false)}>
        <Button color="inherit" fullWidth sx={{ textTransform: 'none', p: 0 }}>Traffic</Button>
      </NavLink>

      <NavLink to="/TokenTester" style={{ textDecoration: 'none', width: '100%' }} onClick={() => setIsDrawerOpen(false)}>
        <Button color="inherit" fullWidth sx={{ textTransform: 'none', p: 0 }}>TokenTester</Button>
      </NavLink>
      
      <NavLink to="/AuthDashboard" style={{ textDecoration: 'none', width: '100%' }} onClick={() => setIsDrawerOpen(false)}>
        <Button color="inherit" fullWidth sx={{ textTransform: 'none', p: 0 }}>AuthDashboard</Button>
      </NavLink>
      
      <Button color="inherit" onClick={handleLogoutClick} fullWidth sx={{ textTransform: 'none', color: 'black', p: 0 }}>
        Logout
      </Button>
    </>
  ) : (
    <NavLink to="/login" style={{ textDecoration: 'none', width: '100%' }} onClick={() => setIsDrawerOpen(false)}>
      <Button color="inherit" fullWidth sx={{ textTransform: 'none', p: 0 }}>Login</Button>
    </NavLink>
  );

  return (
    <>
      {/* Floating Logo - Always visible when scrolling up */}
      <Box
        sx={{
          position: 'fixed',
          top: '10px',
          left: { xs: '10px', sm: '20px', md: '24px' },
          zIndex: 2000,
          transition: 'all 0.3s ease',
          padding: 0,
          borderRadius: '50%',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          backdropFilter: 'blur(5px)',
          opacity: scrollDirection === 'up' ? 1 : 0,
          visibility: scrollDirection === 'up' ? 'visible' : 'hidden',
          transform: scrollDirection === 'up' ? 'translateY(0)' : 'translateY(-20px)',
          pointerEvents: scrollDirection === 'up' ? 'auto' : 'none',
          background: launchFailed ? 'rgba(255, 0, 0, 0.8)' : 'transparent', // Red background on failure
        }}
      >
        <Box
          ref={logoRef}
          onClick={handleLogoClick}
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            '&:hover': { 
              transform: 'scale(1.05)',
            },
            transition: 'transform 0.2s ease',
            animation: isSpinning ? 'spin 1s linear' : 'none',
            '@keyframes spin': {
              '0%': {
                transform: 'rotateY(0deg)'
              },
              '100%': {
                transform: 'rotateY(360deg)'
              }
            }
          }}
        >
          <img
            src="/images/logo.png"
            alt="Logo"
            style={{ 
              width: 40,
              height: 40,
              objectFit: 'contain'
            }}
          />
        </Box>
      </Box>

      {/* Main Header - Shows/Hides based on scroll direction */}
      <Slide appear={false} direction="down" in={scrollDirection === 'up'}>
        <Box
          component="header"
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            height: `${headerHeight}px`, // Fixed height for consistency
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            color: 'white',
            padding: 0,
            margin: 0,
            width: '100%',
            zIndex: 1900,
            background: 'transparent',
            backdropFilter: 'none',
            boxShadow: 'none',
            transition: 'box-shadow 0.3s ease',
          }}
        >
          {/* Placeholder for logo spacing */}
          <Box sx={{ width: 40, ml: { xs: 1, sm: 2, md: 3 } }} />
          
          {/* Center branding */}
          <Box
            sx={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: 900,
                color: 'white',
                WebkitTextFillColor: 'white',
                letterSpacing: "0.5px",
              }}
            >
              Transfer.co
            </Typography>
          </Box>

          {/* Logo and Chat Title Wrapper */}
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 1,
            pl: { xs: 1, sm: 2, md: 3 }
          }}>
            {/* Display the selected channel name */}
            {chatTitle && (
              <Box 
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  borderRadius: '4px', 
                  padding: 0,
                  cursor: 'pointer',
                  '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' }
                }} 
                onClick={onToggleSidebar}
              >
                <Typography
                  variant="h6"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: isMobile ? '120px' : '200px',
                    color: 'white',
                    fontWeight: 500,
                  }}
                >
                  {`: ${chatTitle}`}
                </Typography>
              </Box>
            )}
          </Box>

          {/* SessionMonitor & MenuIcon Wrapper */}
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 0, 
            color: 'black', 
            marginLeft: 'auto', 
            mr: { xs: 1, sm: 2, md: 3 } 
          }}>
            {/* {showDrawer && (
              <IconButton onClick={toggleDrawer(true)} sx={{ color: 'black', p: isMobile ? 0.5 : 1 }}>
                <MenuIcon />
              </IconButton>
            )} */}
          </Box>
        </Box>
      </Slide>

      {/* Optimized Drawer for Mobile */}
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: '200px', // Fixed width of 200px
            color: '#fff',
            p: 0,
            // backgroundColor: 'rgba(255, 255, 255, 0.95)', // Semi-transparent white
            backdropFilter: 'blur(10px)', // Glass effect
            borderLeft: '1px solid rgba(0,0,0,0.1)', // Subtle border
          },
        }}
      >
        <Box
          sx={{
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 0,
            width: '100%'
          }}
        >
          <List sx={{ width: '100%', p: 0 }}>
            {/* Add Login/User Info at the top */}
            {user ? (
              <Button color="inherit" fullWidth sx={{ 
                textTransform: 'none', 
                color: 'black',
                p: 1,
                borderBottom: '1px solid rgba(0,0,0,0.1)'
              }}>
                <SessionMonitor variant="minimal" showUserDetails={true} />
              </Button>
            ) : (
              showLogin && (
                <NavLink to="/login" style={{ textDecoration: 'none', width: '100%' }}>
                  <Button 
                    color="primary" 
                    fullWidth 
                    variant="contained"
                    sx={{ 
                      textTransform: 'none',
                      m: 1,
                      width: 'calc(100% - 16px)', // Account for margin
                      borderRadius: 1
                    }}
                  >
                    Login
                  </Button>
                </NavLink>
              )
            )}
            
            {/* Rest of the nav items */}
            {navItems}
          </List>
        </Box>
      </Drawer>

      {/* Logout Confirmation Dialog */}
      <Dialog
        open={confirmLogoutOpen}
        onClose={handleCancelLogout}
        aria-labelledby="logout-dialog-title"
        fullScreen={isMobile}
        PaperProps={{
          sx: {
            m: 0,
            p: 0,
            width: isMobile ? '100%' : undefined,
            height: isMobile ? '100%' : undefined,
          }
        }}
      >
        <DialogTitle id="logout-dialog-title" sx={{ p: isMobile ? 1 : 2 }}>
          Confirm Logout
        </DialogTitle>
        <DialogContent sx={{ p: isMobile ? 1 : 2 }}>
          <Typography variant="body1">
            Are you sure you want to log out of your account?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: isMobile ? 1 : 2 }}>
          <Button onClick={handleCancelLogout} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmLogout} color="error" variant="contained">
            Logout
          </Button>
        </DialogActions>
      </Dialog>

      {/* Shared Exchange Dialog - positioned below header */}
      <ExchangeDialog open={exchangeDialogOpen} onClose={() => setExchangeDialogOpen(false)} />
    </>
  );
};

export default Header;