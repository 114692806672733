import { createContext, useContext, useReducer, useRef, useCallback } from 'react';

const SidebarContext = createContext();

const sidebarReducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_SIDEBAR':
      return { ...state, isOpen: !state.isOpen };
    default:
      return state;
  }
};

export const SidebarProvider = ({ children }) => {
  const [state, dispatch] = useReducer(sidebarReducer, { isOpen: false });
  
  // Create a ref for tracking toggle state
  const toggleRef = useRef({
    current: false,
    lastToggleTime: 0
  });

  const toggleSidebar = useCallback((source) => {
    const now = Date.now();
    const TOGGLE_DELAY = 300; // milliseconds

    // Check if a toggle is already in progress
    if (toggleRef.current.current) {
      console.warn(`⚠️ Sidebar toggle blocked: Another toggle is in progress. (Source: ${source})`);
      return;
    }

    // Check time since last toggle
    if (now - toggleRef.current.lastToggleTime < TOGGLE_DELAY) {
      console.warn(`⚠️ Sidebar toggle blocked: Too soon after last toggle. (Source: ${source})`);
      return;
    }

    console.log(`🔄 Sidebar toggle requested from: ${source}. Current state: ${state.isOpen}`);
    
    // Mark toggle in progress
    toggleRef.current.current = true;
    toggleRef.current.lastToggleTime = now;

    // Dispatch toggle action
    dispatch({ type: 'TOGGLE_SIDEBAR' });

    // Reset toggle status after delay
    setTimeout(() => {
      toggleRef.current.current = false;
    }, TOGGLE_DELAY);
  }, [state.isOpen]);

  return (
    <SidebarContext.Provider value={{ 
      isSidebarOpen: state.isOpen, 
      toggleSidebar, 
      isToggling: toggleRef 
    }}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => {
  const context = useContext(SidebarContext);
  
  // Provide a safe fallback if context is not available
  if (!context) {
    return {
      isSidebarOpen: false,
      toggleSidebar: () => console.warn('Sidebar context not available'),
      isToggling: { current: false }
    };
  }

  return context;
};