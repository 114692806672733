import React, { createContext, useContext, useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../services/api.js';

const AuthContext = createContext(null);

const TOKEN_CHECK_INTERVAL = 60000;
const TOKEN_REFRESH_THRESHOLD = 30000;
const SESSION_WARNING_THRESHOLD = 30000; // 30 seconds warning before expiration
const SESSION_REFRESH_INTERVAL = 30000;  // Check every 30 seconds
const SESSION_REFRESH_THRESHOLD = 120000; // Refresh if less than 2 minutes left

const getStoredValue = (key) => {
  try {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  } catch (error) {
    console.error(`Error reading ${key} from storage:`, error);
    return null;
  }
};

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const checkIntervalRef = useRef(null);
  const isRefreshingRef = useRef(false);
  const isValidatingRef = useRef(false);

  // Session warning state
  const [sessionWarning, setSessionWarning] = useState({
    show: false,
    message: '',
    type: 'info' // 'info', 'warning', 'error'
  });

  // Auth state
  const [state, setState] = useState(() => {
    const storedUser = getStoredValue('user');
    const storedToken = localStorage.getItem('accessToken');
    const storedExpiration = getStoredValue('accessTokenExpiration');
    
    return {
      user: storedUser,
      isAuthenticated: !!(storedUser?._id && storedToken && storedExpiration && storedExpiration > Date.now()),
      loading: true,
      accessToken: storedToken,
      accessTokenExpiration: storedExpiration,
      refreshTokenExpiration: getStoredValue('refreshTokenExpiration'),
      error: null,
      isRefreshing: false,
      refreshMessage: null,
      timeUntilRefresh: null
    };
  });

  const updateAuthState = useCallback((updates) => {
    setState((prev) => ({ ...prev, ...updates }));
  }, []);

  const updateSession = useCallback(async ({ accessToken, accessTokenExpiration, refreshTokenExpiration }) => {
    try {
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('accessTokenExpiration', JSON.stringify(accessTokenExpiration));
      localStorage.setItem('refreshTokenExpiration', JSON.stringify(refreshTokenExpiration));
      
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  
      updateAuthState({
        accessToken,
        accessTokenExpiration,
        refreshTokenExpiration,
        isAuthenticated: true,
        error: null,
        refreshMessage: 'Session updated successfully'
      });
  
      return true;
    } catch (error) {
      console.error('Failed to update session:', error);
      updateAuthState({
        error: 'Failed to update session',
        refreshMessage: 'Session update failed'
      });
      return false;
    }
  }, [updateAuthState]);



  // Login function
  const login = async (email, password) => {
    try {
      updateAuthState({ loading: true, error: null });

      const response = await axiosInstance.post('/api/users/login', { email, password });
      const { accessToken, accessTokenExpiration, refreshTokenExpiration, user } = response.data;

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('accessTokenExpiration', JSON.stringify(accessTokenExpiration));
      localStorage.setItem('refreshTokenExpiration', JSON.stringify(refreshTokenExpiration));
      localStorage.setItem('user', JSON.stringify(user));

      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

      updateAuthState({
        user,
        accessToken,
        accessTokenExpiration,
        refreshTokenExpiration,
        isAuthenticated: true,
        loading: false,
        error: null,
        refreshMessage: 'Login successful'
      });

      setTimeout(() => updateAuthState({ refreshMessage: null }), 3000);
      return true;
    } catch (error) {
      updateAuthState({
        loading: false,
        error: error.response?.data?.message || 'Login failed',
        isAuthenticated: false,
        refreshMessage: 'Login failed'
      });

      setTimeout(() => updateAuthState({ refreshMessage: null }), 3000);
      return false;
    }
  };

  // Register function
  const register = async (name, email, password) => {
    try {
      updateAuthState({ loading: true, error: null });

      const response = await axiosInstance.post('/api/users/register', { name, email, password });
      const { accessToken, accessTokenExpiration, refreshTokenExpiration, user } = response.data;

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('accessTokenExpiration', JSON.stringify(accessTokenExpiration));
      localStorage.setItem('refreshTokenExpiration', JSON.stringify(refreshTokenExpiration));
      localStorage.setItem('user', JSON.stringify(user));

      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

      updateAuthState({
        user,
        accessToken,
        accessTokenExpiration,
        refreshTokenExpiration,
        isAuthenticated: true,
        loading: false,
        error: null,
        refreshMessage: 'Registration successful'
      });

      setTimeout(() => updateAuthState({ refreshMessage: null }), 3000);
      return true;
    } catch (error) {
      updateAuthState({
        loading: false,
        error: error.response?.data?.message || 'Registration failed',
        isAuthenticated: false,
        refreshMessage: 'Registration failed'
      });

      setTimeout(() => updateAuthState({ refreshMessage: null }), 3000);
      return false;
    }
  };

  const handleSessionExpired = useCallback(() => {
    localStorage.clear();
    axiosInstance.defaults.headers.common['Authorization'] = '';

    updateAuthState({
      user: null,
      isAuthenticated: false,
      loading: false,
      accessToken: null,
      accessTokenExpiration: null,
      refreshTokenExpiration: null,
      error: 'Session expired. Please log in again.',
      isRefreshing: false,
      refreshMessage: 'Session expired. Redirecting to login...',
      timeUntilRefresh: null
    });

    navigate('/#/login', { replace: true });
  }, [navigate, updateAuthState]);

  // Logout function
  const logout = useCallback(async () => {
    try {
      updateAuthState({ refreshMessage: 'Logging out...' });
  
      const response = await axiosInstance.post('/api/auth/logout', {}, { 
        withCredentials: true 
      });
  
      console.log("✅ Logout response:", response.data);
  
      // Clear all local storage
      localStorage.clear();
      
      // Clear axios default headers
      axiosInstance.defaults.headers.common['Authorization'] = '';
      
      // Update auth state
      updateAuthState({
        user: null,
        isAuthenticated: false,
        loading: false,
        accessToken: null,
        accessTokenExpiration: null,
        refreshTokenExpiration: null,
        error: null,
        isRefreshing: false,
        refreshMessage: null,
        timeUntilRefresh: null
      });
  
      // Handle redirection based on response
      if (response.data?.redirect) {
        // For hash router, we need to handle the hash part
        const baseUrl = window.location.origin;
        const hashPath = '#/';
        
        if (window.location.hostname === 'localhost') {
          navigate('/', { replace: true });
        } else {
          // For production environment
          window.location.href = `${baseUrl}${hashPath}`;
        }
      }
  
    } catch (error) {
      console.error('❌ Logout error:', error.response?.data || error.message);
      
      // Even if the API call fails, we should still clear local state
      localStorage.clear();
      axiosInstance.defaults.headers.common['Authorization'] = '';
      
      updateAuthState({
        user: null,
        isAuthenticated: false,
        loading: false,
        accessToken: null,
        accessTokenExpiration: null,
        refreshTokenExpiration: null,
        error: null,
        isRefreshing: false,
        refreshMessage: null,
        timeUntilRefresh: null
      });
  
      // Redirect to root even on error
      const baseUrl = window.location.origin;
      window.location.href = `${baseUrl}/#/`;
    }
  }, [navigate, updateAuthState]);







  const refreshTokens = useCallback(async (silent = false) => {
    if (isRefreshingRef.current) {
      console.log('🔄 Already refreshing tokens...');
      return false;
    }

    try {
      isRefreshingRef.current = true;

      if (!silent) {
        updateAuthState({ 
          isRefreshing: true,
          refreshMessage: 'Refreshing session...'
        });
      }

      const response = await axiosInstance.post('/api/auth/refresh-token', {}, {
        withCredentials: true
      });

      if (!response.data.accessToken) {
        throw new Error('Invalid refresh response');
      }

      localStorage.setItem('accessToken', response.data.accessToken);
      localStorage.setItem('accessTokenExpiration', JSON.stringify(response.data.accessTokenExpiration));

      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${response.data.accessToken}`;

      updateAuthState({
        accessToken: response.data.accessToken,
        accessTokenExpiration: response.data.accessTokenExpiration,
        isAuthenticated: true,
        isRefreshing: false,
        refreshMessage: silent ? null : 'Session refreshed successfully'
      });

      setSessionWarning({ show: false, message: '', type: 'info' });

      return true;
    } catch (error) {
      console.error('Token refresh failed:', error);
      handleSessionExpired();
      return false;
    } finally {
      isRefreshingRef.current = false;
      if (!silent) {
        setTimeout(() => updateAuthState({ refreshMessage: null }), 3000);
      }
    }
  }, [updateAuthState, handleSessionExpired]);
  

  // Session monitoring effect
  useEffect(() => {
    const checkSession = async () => {
      if (!state.accessTokenExpiration || !state.isAuthenticated || state.isRefreshing) {
        return;
      }

      const timeUntilExpiry = state.accessTokenExpiration - Date.now();
      const secondsRemaining = Math.ceil(timeUntilExpiry / 1000);


          // Update timeUntilRefresh in state for UI components
    updateAuthState({
      timeUntilRefresh: secondsRemaining
    });


      if (timeUntilExpiry <= SESSION_WARNING_THRESHOLD && timeUntilExpiry > 0) {
        setSessionWarning({
          show: true,
          message: `Session will expire in ${Math.ceil(timeUntilExpiry / 1000)} seconds`,
          type: timeUntilExpiry <= 30000 ? 'warning' : 'info'
        });

        if (timeUntilExpiry <= SESSION_REFRESH_THRESHOLD) {
          await refreshTokens(true);
        }
      } else if (timeUntilExpiry <= 0) {
        setSessionWarning({
          show: true,
          message: 'Session expired. Attempting to refresh...',
          type: 'error'
        });
        await refreshTokens(false);
      } else {
        setSessionWarning({ show: false, message: '', type: 'info' });
      }
    };

    const interval = setInterval(checkSession, SESSION_REFRESH_INTERVAL);
    return () => clearInterval(interval);
  }, [state.accessTokenExpiration, state.isAuthenticated, state.isRefreshing, refreshTokens]);

  // Initial session validation
  useEffect(() => {
    const validateSession = async () => {
      if (isValidatingRef.current || !state.accessToken) return;

      try {
        isValidatingRef.current = true;
        console.log('Starting session validation');

        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${state.accessToken}`;

        if (state.accessTokenExpiration <= Date.now()) {
          console.log('Stored token expired, attempting refresh');
          const refreshSuccess = await refreshTokens(true);
          if (!refreshSuccess) {
            throw new Error('Token refresh failed');
          }
        }

        const response = await axiosInstance.get('/api/users/me');
        
        if (!response.data?._id) {
          throw new Error('Invalid user data');
        }

        console.log('Session validated successfully');
        
        localStorage.setItem('user', JSON.stringify(response.data));
        
        updateAuthState({
          user: response.data,
          isAuthenticated: true,
          loading: false,
          error: null
        });
      } catch (error) {
        console.error('Session validation failed:', error);
        handleSessionExpired();
      } finally {
        isValidatingRef.current = false;
      }
    };

    validateSession();
  }, [state.accessToken, state.accessTokenExpiration, refreshTokens, handleSessionExpired]);

  // API interceptor for token refresh
  useEffect(() => {
    const interceptor = axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;

        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;

          try {
            const refreshSuccess = await refreshTokens(true);
            if (refreshSuccess) {
              const token = localStorage.getItem('accessToken');
              originalRequest.headers['Authorization'] = `Bearer ${token}`;
              return axiosInstance(originalRequest);
            }
          } catch (refreshError) {
            handleSessionExpired();
            return Promise.reject(refreshError);
          }
        }

        return Promise.reject(error);
      }
    );

    return () => axiosInstance.interceptors.response.eject(interceptor);
  }, [refreshTokens, handleSessionExpired]);

  const value = {
    ...state,
    login,
    logout,
    register,
    refreshTokens,
    updateSession

  };

  return (
    <AuthContext.Provider value={value}>
      {children}
      {/* Session warning notification */}
      {sessionWarning.show && (
        <div
          style={{
            position: 'fixed',
            bottom: state.refreshMessage ? '80px' : '20px',
            right: '20px',
            padding: '12px 20px',
            borderRadius: '4px',
            backgroundColor: 
              sessionWarning.type === 'error' ? '#f44336' :
              sessionWarning.type === 'warning' ? '#ff9800' : '#4caf50',
            color: 'white',
            boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
            zIndex: 9999,
            transition: 'all 0.3s ease'
          }}
        >
          {sessionWarning.message}
        </div>
      )}
      {/* Refresh message notification */}
      {state.refreshMessage && (
        <div
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            padding: '12px 20px',
            borderRadius: '4px',
            backgroundColor: state.timeUntilRefresh > 5 ? '#4CAF50' : '#FFA726',
            color: 'white',
            boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
            zIndex: 9999,
            transition: 'all 0.3s ease'
          }}
        >
          {state.refreshMessage}
        </div>
      )}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthProvider;