// FullPreviewModal.js - Fixed version with proper initialization order
import React, { useState, useEffect, useRef } from 'react';
import { Dialog, DialogContent, IconButton, Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import { Close, ArrowBack, ArrowForward } from '@mui/icons-material';

const FullPreviewModal = ({ open, onClose, mediaUrl, mediaType, fileName, files = [], initialFileIndex = 0 }) => {
  const [currentIndex, setCurrentIndex] = useState(initialFileIndex);
  const [touchStartX, setTouchStartX] = useState(null);
  const [touchEndX, setTouchEndX] = useState(null);
  const [touchStartY, setTouchStartY] = useState(null);
  const [touchEndY, setTouchEndY] = useState(null);
  const [touchStartTime, setTouchStartTime] = useState(null);
  const [isPullingDown, setIsPullingDown] = useState(false);
  const [pullDistance, setPullDistance] = useState(0);
  const contentRef = useRef(null);
  const videoRef = useRef(null);
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Format files array and determine media type for each file
  const formattedFiles = (Array.isArray(files) ? files : []).map(file => {
    if (!file) return null;

    if (typeof file === 'string') {
      const name = file.split('/').pop()?.split('?')[0] || 'unknown';
      const type = name.match(/\.(mp4|mov|webm)$/i) ? 'video' : 'image';
      const url = file.includes('?') ? `${file}&wrap=0` : `${file}?wrap=0`;
      return { url, name, type };
    }
    
    if (typeof file === 'object' && (file.fileUrl || file.url)) {
      const fileUrl = file.fileUrl || file.url;
      const url = fileUrl.includes('?') ? `${fileUrl}&wrap=0` : `${fileUrl}?wrap=0`;
      return { 
        url,
        name: file.fileName || file.name || 'unknown',
        type: file.fileType || file.type || (file.fileName?.match(/\.(mp4|mov|webm)$/i) ? 'video' : 'image')
      };
    }
    
    return null;
  }).filter(Boolean);
  
  // Initialize currentFile - moved below formattedFiles definition
  const currentFile = formattedFiles.length > 0 ? formattedFiles[currentIndex] : { 
    url: mediaUrl ? (mediaUrl.includes('?') ? `${mediaUrl}&wrap=0` : `${mediaUrl}?wrap=0`) : '',
    name: fileName || 'unknown',
    type: mediaType || 'image'
  };

  useEffect(() => {
    if (open) {
      setCurrentIndex(initialFileIndex);
      setPullDistance(0);
      setIsPullingDown(false);
    }
  }, [open, initialFileIndex]);

  // Effect to handle video auto-play when changing files
  useEffect(() => {
    // If the current file is a video and we have a video ref, try to play it
    if (videoRef.current && open && currentFile?.type === 'video') {
      try {
        // Use Promise-based play with error handling
        const playPromise = videoRef.current.play();
        
        if (playPromise !== undefined) {
          playPromise.catch(error => {
            // Auto-play was prevented by the browser
            console.log('Auto-play prevented:', error);
            // We don't need to do anything special, the user can play manually
          });
        }
      } catch (err) {
        console.log('Error auto-playing video:', err);
      }
    }
  }, [currentIndex, open, currentFile]);

  const handlePrevious = () => {
    if (formattedFiles.length <= 1) return;
    setCurrentIndex(prev => prev > 0 ? prev - 1 : formattedFiles.length - 1);
  };

  const handleNext = () => {
    if (formattedFiles.length <= 1) return;
    setCurrentIndex(prev => prev < formattedFiles.length - 1 ? prev + 1 : 0);
  };

  // Handle center click behavior - advance or close if last
  const handleCenterClick = (e) => {
    // Prevent triggering on video controls
    if (currentFile.type === 'video' && (
      e.target.tagName === 'VIDEO' || 
      e.target.closest('video')
    )) {
      return;
    }

    // If it's the last item, close the modal
    if (currentIndex === formattedFiles.length - 1) {
      onClose();
      return;
    }

    // Otherwise, go to next item
    handleNext();
  };

  // Enhanced mobile touch handlers
  const onTouchStart = (e) => {
    // Capture both X and Y coordinates for better gesture detection
    setTouchStartX(e.touches[0].clientX);
    setTouchStartY(e.touches[0].clientY);
    setTouchEndX(null);
    setTouchEndY(null);
    setTouchStartTime(Date.now());
  };

  const onTouchMove = (e) => {
    // Track both axes of movement
    setTouchEndX(e.touches[0].clientX);
    setTouchEndY(e.touches[0].clientY);
    
    // If we have start and current positions
    if (touchStartY !== null && touchStartX !== null) {
      const deltaY = e.touches[0].clientY - touchStartY;
      const deltaX = Math.abs(e.touches[0].clientX - touchStartX);
      
      // If primarily moving downward and near the top of the content area (for pull-down-to-close)
      if (deltaY > 20 && deltaY > deltaX && contentRef.current?.scrollTop <= 10) {
        setIsPullingDown(true);
        setPullDistance(Math.min(deltaY, 200)); // Limit max pull distance
        e.preventDefault(); // Prevent scroll
      }
    }
  };

  const onTouchEnd = () => {
    // Don't process if no touch event data
    if (touchStartX === null || touchStartTime === null) return;
    
    // Handle pull-down-to-close
    if (isPullingDown && pullDistance > 100) {
      onClose();
      return;
    }
    
    // Reset pull down state if not closing
    setIsPullingDown(false);
    setPullDistance(0);
    
    // Process horizontal swipe for navigation
    if (touchEndX !== null) {
      const deltaX = touchStartX - touchEndX;
      const deltaY = touchEndY !== null ? Math.abs(touchStartY - touchEndY) : 0;
      const timeDiff = Date.now() - touchStartTime;
      
      // Detect if this was a swipe (fast enough, far enough, and more horizontal than vertical)
      const isSwipe = Math.abs(deltaX) > 50 && timeDiff < 300 && Math.abs(deltaX) > deltaY;
      
      if (isSwipe) {
        if (deltaX > 0) {
          // Swiped left, go to next
          handleNext();
        } else {
          // Swiped right, go to previous
          handlePrevious();
        }
      } else if (Math.abs(deltaX) < 10 && deltaY < 10 && timeDiff < 300) {
        // This was a tap (minimal movement in either direction and quick)
        handleCenterClick({ target: { tagName: 'DIV' } });
      }
    } else {
      // No movement was registered, this was likely a tap
      if (Date.now() - touchStartTime < 300) {
        handleCenterClick({ target: { tagName: 'DIV' } });
      }
    }
  };

  // Desktop keyboard navigation - enabled for both images and videos
  useEffect(() => {
    if (!open) return;

    const handleKeyDown = (e) => {
      switch (e.key) {
        case 'ArrowRight':
        case ' ': // Space key
          handleNext();
          e.preventDefault(); // Prevent page scrolling
          break;
        case 'ArrowLeft':
          handlePrevious();
          e.preventDefault(); // Prevent page scrolling
          break;
        case 'Escape':
          onClose();
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [open, handleNext, handlePrevious, onClose]);

  if (!open) return null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      fullScreen
      PaperProps={{
        sx: {
          bgcolor: 'black',
          m: 0,
          p: 0,
          height: '100vh',
          width: '100vw'
        }
      }}
    >
        
      {/* File name header */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bgcolor: 'rgba(0,0,0,0.5)',
          px: 2,
          py: 1,
          zIndex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Typography 
          variant="subtitle1" 
          sx={{ 
            color: 'white',
            textAlign: 'center',
            maxWidth: '80%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
        >
          {currentFile.name || fileName || 'Preview'}
        </Typography>
      </Box>

      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 16,
          top: 16,
          color: 'white',
          zIndex: 2  // Increased to appear above header
        }}
      >
        <Close />
      </IconButton>

      {/* Desktop arrow buttons - always show for both images and videos when there are multiple files */}
      {formattedFiles.length > 1 && (
        <>
          <IconButton
            onClick={handlePrevious}
            aria-label="Previous image or video"
            sx={{
              position: 'absolute',
              left: 16,
              top: '50%',
              transform: 'translateY(-50%)',
              color: 'white',
              zIndex: 1,
              backgroundColor: 'rgba(0,0,0,0.3)',
              '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.5)'
              },
              width: 40,
              height: 40
            }}
          >
            <ArrowBack />
          </IconButton>
          <IconButton
            onClick={handleNext}
            aria-label="Next image or video"
            sx={{
              position: 'absolute',
              right: 16,
              top: '50%',
              transform: 'translateY(-50%)',
              color: 'white',
              zIndex: 1,
              backgroundColor: 'rgba(0,0,0,0.3)',
              '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.5)'
              },
              width: 40,
              height: 40
            }}
          >
            <ArrowForward />
          </IconButton>
        </>
      )}

      {/* Counter for both mobile and desktop */}
      {formattedFiles.length > 1 && (
        <Box sx={{
          position: 'absolute',
          bottom: 16,
          left: '50%',
          transform: 'translateX(-50%)',
          color: 'white',
          bgcolor: 'rgba(0,0,0,0.5)',
          px: 2,
          py: 0.5,
          borderRadius: 4,
          zIndex: 1
        }}>
          <Typography variant="body2">
            {currentIndex + 1} / {formattedFiles.length}
          </Typography>
        </Box>
      )}

      {/* Swipe instruction for mobile - show briefly on open */}
      {isMobile && formattedFiles.length > 1 && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 60,
            left: '50%',
            transform: 'translateX(-50%)',
            color: 'white',
            bgcolor: 'rgba(0,0,0,0.5)',
            px: 2,
            py: 1,
            borderRadius: 4,
            zIndex: 1,
            animation: 'fadeOut 3s forwards',
            '@keyframes fadeOut': {
              '0%': { opacity: 1 },
              '70%': { opacity: 1 },
              '100%': { opacity: 0 }
            }
          }}
        >
          <Typography variant="body2" sx={{ textAlign: 'center' }}>
            Swipe to navigate • Tap to advance
          </Typography>
        </Box>
      )}

      <DialogContent
        ref={contentRef}
        onClick={handleCenterClick} // Enable click for both mobile and desktop
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
        sx={{
          p: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          cursor: 'pointer', // Always show pointer cursor
          overflow: 'auto',
          transform: isPullingDown ? `translateY(${pullDistance}px)` : 'none',
          transition: isPullingDown ? 'none' : 'transform 0.3s ease',
          opacity: isPullingDown ? Math.max(1 - pullDistance / 200, 0.5) : 1
        }}
      >
        {currentFile.type === 'video' ? (
          <video
            ref={videoRef}
            key={currentFile.url}
            src={currentFile.url}
            controls
            autoPlay
            playsInline
            muted={false}
            style={{
              maxWidth: '100vw',
              maxHeight: 'calc(100vh - 60px)',
              width: 'auto',
              height: 'auto',
              objectFit: 'contain'
            }}
            onClick={(e) => e.stopPropagation()}
            onLoadedMetadata={(e) => {
              // Try to play when metadata is loaded
              try {
                e.target.play().catch(err => {
                  console.log('Auto-play prevented on load:', err);
                });
              } catch (err) {
                console.log('Error during auto-play on load:', err);
              }
            }}
          />
        ) : (
          <img
            key={currentFile.url}
            src={currentFile.url}
            alt={currentFile.name}
            style={{
              maxWidth: '100vw',
              maxHeight: 'calc(100vh - 60px)',
              width: 'auto',
              height: 'auto',
              objectFit: 'contain'
            }}
          />
        )}
      </DialogContent>

      {/* Pull to close indicator */}
      {isPullingDown && (
        <Box sx={{
          position: 'absolute',
          top: pullDistance / 2,
          left: '50%',
          transform: 'translateX(-50%)',
          color: 'white',
          textAlign: 'center',
          opacity: Math.min(pullDistance / 100, 1),
          zIndex: 2
        }}>
          <Typography variant="body2">
            Pull down to close
          </Typography>
        </Box>
      )}
    </Dialog>
  );
};

export default FullPreviewModal;