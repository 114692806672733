import React from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import App from './App.js';
import { StyledEngineProvider } from '@mui/material/styles/index.js';
import { SidebarProvider } from './contexts/SidebarContext.js';
import { ChatTitleProvider } from './contexts/ChatTitleContext.js';
import UploadQueueProvider from './contexts/UploadQueueContext.js';
import './index.css';

const helmetContext = {};

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <HelmetProvider context={helmetContext}>
      <HashRouter>
        <UploadQueueProvider>
          <SidebarProvider>
            <ChatTitleProvider>
              <StyledEngineProvider injectFirst>
                <App />
              </StyledEngineProvider>
            </ChatTitleProvider>
          </SidebarProvider>
        </UploadQueueProvider>
      </HashRouter>
    </HelmetProvider>
  </React.StrictMode>
);