import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { handleCookies, detectPlatform } from '../utils/cookieUtils.js'; // Ensure the correct path

const CookieVerification = ({ refreshInterval = 2000 }) => {
  const [cookieStatus, setCookieStatus] = useState({
    browser: {},
    localStorage: {},
    total: 0,
    platform: detectPlatform() || {} // Ensure platform is always an object
  });

  useEffect(() => {
    const updateCookieStatus = () => {
      const allCookies = handleCookies.getAll();
      console.log('Updating cookie status:', allCookies); // Debugging log

      setCookieStatus(prev => ({
        ...prev,
        ...allCookies,
        platform: detectPlatform() || {} // Ensure platform is always an object
      }));
    };

    updateCookieStatus();
    const interval = setInterval(updateCookieStatus, refreshInterval);
    return () => clearInterval(interval);
  }, [refreshInterval]);

  const renderCookieSection = (title, cookies, showValue = true) => (
    <Box sx={{ mb: 2 }}>
      <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 'bold' }}>
        {title}:
      </Typography>
      <Paper sx={{ p: 1, bgcolor: 'grey.50' }}>
        {Object.keys(cookies).length > 0 ? (
          Object.entries(cookies).map(([name, value], index) => (
            <Box key={index} sx={{ ml: 2, mb: 0.5 }}>
              <Typography 
                variant="body2" 
                sx={{ 
                  fontFamily: 'monospace', 
                  fontSize: '0.75rem',
                  overflowWrap: 'break-word' 
                }}
              >
                • {name}
                {showValue && `: ${value}`}
              </Typography>
            </Box>
          ))
        ) : (
          <Typography variant="body2" color="text.secondary" sx={{ ml: 2, fontStyle: 'italic' }}>
            No cookies found
          </Typography>
        )}
      </Paper>
    </Box>
  );

  const { platform } = cookieStatus;

  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="subtitle2" color="primary" sx={{ mb: 1 }}>
        Cookie Status ({cookieStatus.total} total)
      </Typography>
      
      {/* Platform Information */}
      <Box sx={{ mb: 2 }}>
        <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 'bold' }}>
          Platform Details:
        </Typography>
        <Paper sx={{ p: 1, mb: 2, bgcolor: 'grey.50' }}>
          <Typography variant="body2" sx={{ ml: 2 }}>
            • Device: {platform.deviceType || 'Unknown'}
          </Typography>
          <Typography variant="body2" sx={{ ml: 2 }}>
            • Browser: {platform.isChrome ? 'Chrome' : 
                       platform.isSafari ? 'Safari' : 'Other'}
          </Typography>
          <Typography variant="body2" sx={{ ml: 2 }}>
            • iOS: {platform.isIOS ? 'Yes' : 'No'}
          </Typography>
        </Paper>
      </Box>

      {/* Cookie Sections */}
      <Box sx={{ 
        p: 2,
        backgroundColor: '#fff',
        borderRadius: 1,
        maxHeight: '300px',
        overflowY: 'auto',
        border: '1px solid rgba(0, 0, 0, 0.12)'
      }}>
        {renderCookieSection('Browser Cookies', cookieStatus.browser, true)}
        {renderCookieSection('LocalStorage Cookies', cookieStatus.localStorage)}
      </Box>
    </Box>
  );
};

export default CookieVerification;