import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import UserChannels from '../components/UserChannels.js';

const Sidebar = ({ 
  selectedChannel, 
  handleChannelSelect, 
  channels, 
  user, 
  setChannels,
  isSidebarOpen,
  onToggleSidebar 
}) => {
  return (
    <Box sx={{ 
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    }}>
      {/* Toggle Button Container */}
      <Box sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '8px',
        borderBottom: '1px solid #ddd'
      }}>
        <IconButton onClick={onToggleSidebar}>
          <ChevronLeftIcon 
            sx={{ 
              transform: isSidebarOpen ? 'rotate(0deg)' : 'rotate(180deg)',
              transition: 'transform 0.3s ease-in-out'
            }} 
          />
        </IconButton>
      </Box>

      {/* Channels List Container */}
      <Box sx={{ 
        flex: 1,
        overflowY: 'auto',
        overflowX: 'hidden'
      }}>
        <UserChannels 
          channels={channels}
          selectedChannel={selectedChannel}
          onChannelSelect={handleChannelSelect} 
          setChannels={setChannels}
          user={user} 
        />
      </Box>
    </Box>
  );
};

Sidebar.propTypes = {
  selectedChannel: PropTypes.object,
  handleChannelSelect: PropTypes.func.isRequired,
  channels: PropTypes.array.isRequired,
  user: PropTypes.object,
  setChannels: PropTypes.func.isRequired,
  isSidebarOpen: PropTypes.bool,
  onToggleSidebar: PropTypes.func
};

export default Sidebar;