// utils/authUtils.js

export const TOKEN_KEY = 'accessToken'; // Ensure consistent token key

/**
 * Get the stored authentication token from local storage or session storage.
 * @returns {string|null} The authentication token or null if not found.
 */
export const getAuthToken = () => {
  return localStorage.getItem(TOKEN_KEY) || sessionStorage.getItem(TOKEN_KEY);
};

/**
 * Save the authentication token securely.
 * @param {string} token - The authentication token to be stored.
 * @param {boolean} rememberMe - If true, store token in localStorage; otherwise, use sessionStorage.
 */
export const setAuthToken = (token, rememberMe = false) => {
  if (token) {
    if (rememberMe) {
      localStorage.setItem(TOKEN_KEY, token);
    } else {
      sessionStorage.setItem(TOKEN_KEY, token);
    }
  } else {
    removeAuthToken();
  }
};

/**
 * Remove the authentication token from storage.
 */
export const removeAuthToken = () => {
  localStorage.removeItem(TOKEN_KEY);
  sessionStorage.removeItem(TOKEN_KEY);
};

/**
 * Check if a user is authenticated (i.e., has a valid token).
 * @returns {boolean} True if a token exists, false otherwise.
 */
export const isAuthenticated = () => !!getAuthToken();
