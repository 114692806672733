import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import Header from './Header.js';
import axiosInstance from '../services/api.js';

const Layout = ({ children }) => {
  const [scrollDirection, setScrollDirection] = useState('up');
  const [hasScrolled, setHasScrolled] = useState(false);
  const lastScrollY = useRef(0);
  const scrollThreshold = 10; // Minimum scroll amount to trigger direction change
  const ticking = useRef(false);
  const headerTrackingSent = useRef(false);

  // Optimized scroll handler using requestAnimationFrame
  const handleScroll = useCallback(() => {
    if (!ticking.current) {
      window.requestAnimationFrame(() => {
        const currentScrollY = window.scrollY;
        
        // Set hasScrolled if we've scrolled down at all
        if (currentScrollY > 0) {
          setHasScrolled(true);
        } else {
          setHasScrolled(false);
        }
        
        // Only change direction if we've scrolled past threshold
        if (Math.abs(currentScrollY - lastScrollY.current) > scrollThreshold) {
          // Determine scroll direction
          if (currentScrollY > lastScrollY.current) {
            setScrollDirection('down');  // Header hides
          } else {
            setScrollDirection('up');    // Header shows
            
            // Track header show once per session
            if (!headerTrackingSent.current && currentScrollY > 100) {
              headerTrackingSent.current = true;
              
              axiosInstance.post('/api/stats/header-show')
                .then(response => {
                  console.log('📊 Header show tracked:', response.data);
                })
                .catch(error => {
                  console.error('Failed to track header show:', error);
                });
            }
          }
          
          // Update last scroll position
          lastScrollY.current = currentScrollY;
        }
        
        ticking.current = false;
      });
      
      ticking.current = true;
    }
  }, []);

  // Add scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  // Share scroll state with Home component if needed
  useEffect(() => {
    // Check if we have homeScrollState from Home component
    if (window.homeScrollState) {
      // Update our local state based on Home component's state
      setScrollDirection(window.homeScrollState.direction);
      setHasScrolled(window.homeScrollState.hasScrolled);
    }
  }, []);

  // Calculate header height for consistent spacing
  const headerHeight = 60; // Set this to match your actual header height

  return (
    <Box sx={{ 
      minHeight: '50vh',
      display: 'flex',
      flexDirection: 'column',
      background: 'linear-gradient(45deg, rgb(0, 205, 41) 0%, rgb(161, 225, 0) 100%)',
      position: 'relative'
    }}>
      {/* Only one Header component that responds to scroll */}
      <Header 
        scrollDirection={scrollDirection} 
        hasScrolled={hasScrolled}
        onToggleSidebar={() => console.log('Sidebar toggle clicked')} 
      />

      {/* Main content with dynamic padding to account for fixed header */}
      <Box 
        sx={{ 
          flexGrow: 1,
          paddingTop: scrollDirection === 'up' ? `${headerHeight}px` : '0px', 
          transition: 'padding-top 0.3s ease',
          position: 'relative',
          zIndex: 1 // Ensure content is above potential background elements
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Layout;