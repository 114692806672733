import React, { useState, useCallback, useEffect, useMemo } from 'react';  // Add useMemo here
import { Box, IconButton, TextField, Grid, CircularProgress , Typography } from '@mui/material';
import { Send, Add, Close, ExpandMore } from '@mui/icons-material';
import { useUploadQueue } from '../contexts/UploadQueueContext.js';
import { FileCard } from './shared/FileCard.js';
import axiosInstance from '../services/api.js';

const ChatInput = React.memo(({ user, currentChannel, onMessageSent, scrollToBottom }) => {
  const [message, setMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [showFilePreview, setShowFilePreview] = useState(true);
  const { 
    uploadQueue, 
    uploadFiles, 
    addFilesToQueue, 
    removeFileFromQueue,
    uploadProgress,
    uploadLogs 
  } = useUploadQueue();

  // Calculate total upload progress
  const totalProgress = useMemo(() => {
    if (uploadQueue.length === 0) return 0;
    const totalProgress = uploadQueue.reduce((acc, { file }) => {
      return acc + (uploadProgress[file.name] || 0);
    }, 0);
    return Math.round(totalProgress / uploadQueue.length);
  }, [uploadQueue, uploadProgress]);

  // Check if any files are still processing
  const hasProcessingFiles = useMemo(() => {
    return uploadQueue.some(({ file }) => {
      const status = uploadLogs[file.name]?.[uploadLogs[file.name].length - 1] || '';
      const progress = uploadProgress[file.name] || 0;
      return progress < 100 || status.includes('uploading') || status.includes('stitching');
    });
  }, [uploadQueue, uploadProgress, uploadLogs]);

  const handleSend = useCallback(async () => {
    if (!message.trim() && !uploadQueue.length) return;
    if (!currentChannel?._id || !user?._id) return;

    setIsSending(true);
    try {
      const messageData = {
        text: message.trim(),
        userId: user._id,
        userEmail: user.email,
        channelId: currentChannel._id,
        ...(uploadQueue.length > 0 && {
          fileUrls: [],
          processingFiles: uploadQueue.map(({ file }) => ({
            name: file.name,
            size: file.size,
            type: file.type,
            status: 'pending'
          }))
        })
      };

      const response = await axiosInstance.post(
        `/api/message/channel/${currentChannel._id}/send`,
        messageData
      );

      if (response.data.success) {
        if (uploadQueue.length > 0) {
          setShowFilePreview(false); // Minimize after sending
          await uploadFiles(response.data.message._id, currentChannel._id);
        }
        setMessage('');
        onMessageSent?.(response.data.message);
      }
    } catch (error) {
      console.error('Send failed:', error);
    } finally {
      setIsSending(false);
    }
  }, [message, uploadQueue, currentChannel, user, uploadFiles, onMessageSent]);



  const handleFileSelect = useCallback((e) => {
    const files = Array.from(e.target.files || []);
    
    if (files.length && currentChannel?._id) {
      console.log('Adding files to upload queue:', files.map(f => f.name));

      const queuedFiles = files.map(file => ({
        file,
        channelId: currentChannel._id,
        status: 'queued',
        addedAt: Date.now()
      }));

      addFilesToQueue(queuedFiles, currentChannel._id);
      e.target.value = ''; // Clear file input
    }
  }, [addFilesToQueue, currentChannel]);

  const handleKeyPress = useCallback((e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  }, [handleSend]);

  const hasContent = message.trim().length > 0 || uploadQueue.length > 0;

  return (
    <Box 
      sx={{ 
        position: 'fixed', 
        bottom: 0, 
        left: 0, 
        right: 0,
        // bgcolor: 'background.paper',
        // borderTop: '10px solid #00FF00',
        // borderColor: '#00FF00'
      }}
    >
      {/* File Preview Grid */}
      {uploadQueue.length > 0 && showFilePreview && (
        <Box 
          sx={{
            p: 2,
            pt: 5,
            maxHeight: '200px',
            overflowY: 'auto',
            backgroundColor: '#4CAF50', // Changed to solid green
            transition: 'all 0.3s ease',
            position: 'relative',
            borderTop: '1px solid #43A047' // Darker green border
          }}
        >
          <IconButton
            onClick={() => setShowFilePreview(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white', // Changed to white for better contrast
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.2)'
              },
              zIndex: 1
            }}
          >
            <ExpandMore />
          </IconButton>
          <Grid container spacing={1}>
            {uploadQueue.map(({ file }) => (
              <Grid item xs={4} sm={3} md={2} key={file.name}>
                <FileCard
                  file={file}
                  onRemove={removeFileFromQueue}
                  customProgress={uploadProgress[file.name]}
                  status={uploadLogs[file.name]?.[uploadLogs[file.name].length - 1]}
                  size="small"
                  showFileName
                  isNewUpload={true}
                  sx={{
                    root: {
                      backgroundColor: '#66BB6A', // Lighter green for cards
                      border: '1px solid #43A047', // Darker green border
                    }
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {/* Input Area */}
      <Box 
        sx={{ 
          p: 2,
          pb: { xs: 'env(safe-area-inset-bottom, 16px)', sm: 2 }
        }}
      >
        <Box 
          sx={{ 
            display: 'flex', 
            gap: 1, 
            alignItems: 'flex-end', 
            maxWidth: '800px', // Increased from 800px
            mx: 'auto', 
            bgcolor: 'white', // Changed to orange
            // border: '10px solidrgb(208, 206, 208)',
            outline: '1px solid rgb(208, 206, 208)',
            borderRadius: '16px', // Increased border radius for outer container
            p: 0,
            // border: '10px solid',
            // borderColor: '#00FFFF'
          }}
        >
          <Box sx={{ position: 'relative' }}>
            <IconButton 
              component="label" 
              disabled={isSending} 
              size="small" 
              sx={{ color: 'text.secondary' }}
            >
              <Add fontSize="small" />
              <input 
                type="file" 
                accept="image/*,video/*,.pdf,.doc,.docx" 
                multiple 
                onChange={handleFileSelect} 
                hidden 
              />
            </IconButton>

            {/* Upload Progress Indicator */}
            {uploadQueue.length > 0 && !showFilePreview && (
              <Box
                onClick={() => setShowFilePreview(true)}
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  width: 30,
                  height: 30,
                  cursor: 'pointer',
                  bgcolor: 'primary.light',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <CircularProgress
                  variant="determinate"
                  value={totalProgress}
                  size={20}
                  sx={{
                    color: 'white', // Changed to white for better contrast
                    position: 'absolute',
                    '& circle': {
                      strokeLinecap: 'round'
                    }
                  }}
                />
                {hasProcessingFiles && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Typography 
                      variant="caption" 
                      component="div" 
                      sx={{ 
                        fontSize: '0.75rem',
                        color: 'white', // Changed to white for better contrast
                        fontWeight: 'bold'
                      }}
                    >
                      {totalProgress}%
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          </Box>

          <TextField
            multiline
            minRows={1}
            maxRows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleKeyPress}
            placeholder="Type a message..."
            disabled={isSending}
            sx={{ 
              flex: 1,
              '& .MuiInputBase-root': { 
                p: 1.5,
                fontSize: 16,
                minHeight: '100px', // Added to ensure 4 lines can fit
                borderRadius: '12px', // Increased border radius for text field
                alignItems: 'flex-start' // Align content to top
              }, 
              '& .MuiOutlinedInput-notchedOutline': { 
                border: '10px solid',
                borderColor: '#FFFF00',
                borderRadius: '12px' // Increased border radius for outline
              },
              '& .MuiInputBase-input': {
                verticalAlign: 'top' // Align text to top
              },
              backgroundColor: 'background.paper'
            }}
          />

          <IconButton 
            onClick={handleSend} 
            disabled={isSending || !hasContent} 
            size="small" 
            sx={{ 
              color: hasContent ? 'primary.main' : 'text.disabled', 
              bgcolor: hasContent ? 'primary.light' : 'transparent', 
              '&:hover': { 
                bgcolor: hasContent ? 'primary.main' : 'transparent' 
              } 
            }}
          >
            <Send fontSize="small" />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
});


ChatInput.displayName = 'ChatInput';

export default ChatInput;