import React, { useEffect } from "react";
import { Box } from "@mui/material";


const VideoContainer = () => {
  return (
    <Box sx={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
      <video autoPlay loop muted playsInline style={{ width: "100%", height: "100%", objectFit: "cover" }}>
        <source src="../video/heroVideo01.mp4" type="video/mp4" />
      </video>
      <Box sx={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }} />
    </Box>
  );
}


export default VideoContainer;