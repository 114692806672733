import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  IconButton, 
  Modal, 
  TextField, 
  Button, 
  Snackbar, 
  Alert, 
  CircularProgress 
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext.js';
import axiosInstance from '../services/api.js';

const ShareModal = ({ 
  open, 
  onClose, 
  message = '', 
  channel = {}, 
  fileUrls = [] 
}) => {
  const [email, setEmail] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useAuth();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (errorMessage) setErrorMessage('');
  };

  const handleSendEmail = async () => {
    try {
      const payload = {
        email: email.trim(), 
        user_id: user._id, 
        channel_id: channel._id, 
        content: message || 'Shared message', 
        fileUrls: fileUrls || []
      };
  
      const response = await axiosInstance.post('/api/share', payload);
  
      // Optional: Add a different success message if it's an existing message
      if (response.data.existingMessage) {
        // Maybe show a different snackbar or toast
        console.log('Share link resent for existing message');
      }
  
      setEmail('');
      onClose();
      setSnackbarOpen(true);
    } catch (error) {
      // Error handling remains the same
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  // Truncate message if it's too long
  const truncateMessage = (text, maxLength = 200) => {
    if (!text) return 'No message content';
    return text.length > maxLength 
      ? text.substring(0, maxLength) + '...' 
      : text;
  };

  return (
    <>
      <Modal
        open={open}
        onClose={isLoading ? null : onClose}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: '350px',
            bgcolor: 'background.paper',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: 24,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          {isLoading && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: 'rgba(255,255,255,0.7)',
                zIndex: 10,
              }}
            >
              <CircularProgress />
            </Box>
          )}

          <Typography variant="h6" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
            Share this message
          </Typography>
          
          <Box 
            sx={{
              width: '100%',
              bgcolor: 'grey.100',
              borderRadius: '4px',
              padding: '10px',
              mb: 2,
              maxHeight: '150px',
              overflowY: 'auto'
            }}
          >
            <Typography variant="body2" color="text.secondary">
              Message Preview:
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              {truncateMessage(message)}
            </Typography>

            {fileUrls && fileUrls.length > 0 && (
  <Box sx={{ mt: 2 }}>
    <Typography variant="body2" color="text.secondary">
      Shared Files: {fileUrls.length}
    </Typography>
    <Box sx={{ 
      display: 'grid', 
      gridGap: '10px', 
      gridTemplateColumns: {
        xs: 'repeat(auto-fill, minmax(50px, 1fr))',
        sm: 'repeat(auto-fill, minmax(80px, 1fr))',
        md: 'repeat(auto-fill, minmax(100px, 1fr))',
      } 
    }}>
      {fileUrls.map((fileUrl, index) => (
        <Box key={index} sx={{ textAlign: 'left' }}>
          {/\.(jpg|jpeg|png|gif)$/i.test(fileUrl) ? (
            <img
              src={fileUrl}
              alt={`File ${index + 1}`}
              loading="lazy"
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: '4px',
              }}
              onError={(e) => console.error(`Failed to load image: ${e.target.src}`)}
            />
          ) : /\.(mp4|mov|webm)$/i.test(fileUrl) ? (
            <video
              src={fileUrl}
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: '4px',
              }}
              onError={(e) => console.error(`Failed to load video: ${e.target.src}`)}
            />
          ) : (
            <a
              href={fileUrl}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: 'inline-block',
                width: '100%',
                height: 'auto',
                lineHeight: '50px',
                textAlign: 'center',
                border: '1px solid #ddd',
                borderRadius: '4px',
                textDecoration: 'none',
              }}
            >
              View File
            </a>
          )}
        </Box>
      ))}
    </Box>
  </Box>
)}
          </Box>

          {errorMessage && (
            <Box sx={{ width: '100%', mb: 2 }}>
              <Typography variant="body2" color="error">
                {errorMessage}
              </Typography>
            </Box>
          )}

          <TextField
            label="Recipient Email"
            type="email"
            value={email}
            onChange={handleEmailChange}
            fullWidth
            sx={{ mb: 2 }}
            placeholder="Enter email address"
            error={!!errorMessage}
            helperText={errorMessage}
            disabled={isLoading}
          />

          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleSendEmail}
            disabled={!email || !user || !channel?._id || isLoading}
            fullWidth
          >
            {isLoading ? 'Sending...' : 'Send Share Link'}
          </Button>

          {!isLoading && (
            <IconButton 
              onClick={onClose} 
              sx={{ 
                position: 'absolute', 
                top: 10, 
                right: 10 
              }}
            >
              <Close />
            </IconButton>
          )}
            </Box>
          </Modal>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert 
            onClose={handleSnackbarClose} 
            severity="success" 
            sx={{ width: '100%' }}
          >
            Share link sent successfully!
          </Alert>
        </Snackbar>
    </>
  );
};

export default ShareModal;