import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Collapse, Button, TextField, Typography, Alert, CircularProgress } from '@mui/material';
import { useAuth } from '../contexts/AuthContext.js';
import axiosInstance from '../services/api.js';
import { Helmet } from "react-helmet-async";

// Cookie Verification Component
const CookieVerification = ({ cookies = [] }) => {
  const [storedCookies, setStoredCookies] = useState({
    browser: [],
    localStorage: [],
    indexedDB: []
  });

  useEffect(() => {
    const isIOS = /iPhone|iPad|iPod/.test(navigator.userAgent);
    
    // Get browser cookies
    const browserCookies = document.cookie ? 
      document.cookie.split(';').map(cookie => cookie.trim()) : [];

    // Get localStorage cookies
    const localStorageCookies = Object.keys(localStorage)
      .filter(key => key.startsWith('cookie_'))
      .map(key => `${key.replace('cookie_', '')}=${localStorage.getItem(key)}`);

    // Update state
    setStoredCookies(prev => ({
      ...prev,
      browser: browserCookies,
      localStorage: localStorageCookies
    }));

    // Get IndexedDB cookies if on iOS
    if (isIOS && window.indexedDB) {
      const request = window.indexedDB.open('cookieStore', 1);
      request.onsuccess = (event) => {
        const db = event.target.result;
        const transaction = db.transaction(['cookies'], 'readonly');
        const store = transaction.objectStore('cookies');
        const getAllRequest = store.getAll();
        
        getAllRequest.onsuccess = () => {
          setStoredCookies(prev => ({
            ...prev,
            indexedDB: getAllRequest.result.map(item => item.value)
          }));
        };
      };
    }
  }, [cookies]);

  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="subtitle2" color="primary">Cookie Storage Status</Typography>
      <Box sx={{ 
        mt: 1,
        p: 1,
        backgroundColor: '#fff',
        borderRadius: 1,
        maxHeight: '300px',
        overflowY: 'auto'
      }}>
        {/* Browser Cookies */}
        <Typography variant="body2" color="text.secondary">Browser Cookies:</Typography>
        {storedCookies.browser.length > 0 ? (
          storedCookies.browser.map((cookie, index) => (
            <Typography key={index} variant="body2" sx={{ ml: 2 }}>{cookie}</Typography>
          ))
        ) : (
          <Typography variant="body2" color="error" sx={{ ml: 2 }}>No browser cookies</Typography>
        )}

        {/* localStorage Cookies */}
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>LocalStorage:</Typography>
        {storedCookies.localStorage.length > 0 ? (
          storedCookies.localStorage.map((cookie, index) => (
            <Typography key={index} variant="body2" sx={{ ml: 2 }}>{cookie}</Typography>
          ))
        ) : (
          <Typography variant="body2" color="error" sx={{ ml: 2 }}>No localStorage cookies</Typography>
        )}

        {/* IndexedDB Cookies */}
        {storedCookies.indexedDB.length > 0 && (
          <>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>IndexedDB:</Typography>
            {storedCookies.indexedDB.map((cookie, index) => (
              <Typography key={index} variant="body2" sx={{ ml: 2 }}>{cookie}</Typography>
            ))}
          </>
        )}
      </Box>
    </Box>
  );
};

// Main Login Page Component
const LoginPage = () => {
  const navigate = useNavigate();
  const { isAuthenticated, loading: authLoading, login } = useAuth();
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cookieStore, setCookieStore] = useState(null);
  const [isEmailValid, setIsEmailValid] = useState(false);
  
  const [systemInfo, setSystemInfo] = useState({
    health: {
      status: null,
      serverInfo: null,
      errorDetails: null
    },
    cookies: [],
    browser: {
      userAgent: navigator.userAgent,
      isIOS: /iPhone|iPad|iPod/.test(navigator.userAgent),
      isMobile: /Mobile|Android/.test(navigator.userAgent),
      isDevelopment: process.env.NODE_ENV === 'development'
    },
    api: {
      baseUrl: axiosInstance.defaults.baseURL,
      lastChecked: null,
      responseTime: null
    }
  });

  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded((prev) => !prev);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Initialize iOS-specific features
  useEffect(() => {
    const isIOS = systemInfo.browser.isIOS;
    const isDevelopment = systemInfo.browser.isDevelopment;

    if (isIOS) {
      // Request storage persistence
      navigator.storage.persist().then(isPersistent => {
        console.log(`Storage persistence: ${isPersistent}`);
      });

      // Set manual cookie
      document.cookie = `healthCheck=active; path=/; ${!isDevelopment ? 'secure; ' : ''}samesite=${isDevelopment ? 'lax' : 'none'}`;

      // Initialize IndexedDB
      const dbRequest = window.indexedDB.open('cookieStore', 1);
      dbRequest.onupgradeneeded = (event) => {
        const db = event.target.result;
        db.createObjectStore('cookies', { keyPath: 'name' });
      };
      dbRequest.onsuccess = (event) => {
        setCookieStore(event.target.result);
      };
    }
  }, [systemInfo.browser.isIOS, systemInfo.browser.isDevelopment]);

  const handleCookieStorage = (cookies) => {
    if (!systemInfo.browser.isIOS) return;

    // Store in IndexedDB
    if (cookieStore) {
      const transaction = cookieStore.transaction(['cookies'], 'readwrite');
      const store = transaction.objectStore('cookies');
      cookies.forEach(cookie => {
        const [name, value] = cookie.split('=');
        store.put({
          name: name.trim(),
          value: value,
          timestamp: new Date().toISOString()
        });
      });
    }

    // Store in localStorage as backup
    cookies.forEach(cookie => {
      try {
        const [name, value] = cookie.split('=');
        localStorage.setItem(`cookie_${name.trim()}`, value);
      } catch (e) {
        console.warn('Failed to store cookie in localStorage:', e);
      }
    });
  };

  const checkApiHealth = async () => {
    try {
      setLoading(true);
      const startTime = performance.now();
      
      const response = await axiosInstance.get('/api/health');
      const endTime = performance.now();

      // Parse response cookies
      const setCookieHeader = response.headers['set-cookie'];
      const responseCookies = setCookieHeader ? 
        (Array.isArray(setCookieHeader) ? setCookieHeader : [setCookieHeader]) : [];

      // Store cookies in multiple locations
      handleCookieStorage(responseCookies);

      // Get current document cookies
      const currentCookies = document.cookie ? 
        document.cookie.split(';').map(cookie => cookie.trim()) : [];

      setSystemInfo(prev => ({
        ...prev,
        health: {
          title: response.data?.title || "Unknown",
          status: response.data?.status || "Unknown",
          environment: response.data?.system?.env || response.data?.deployment?.environment || "Unknown",
          serverInfo: response.data?.system || {},
          errorDetails: null
        },
        cookies: [...responseCookies, ...currentCookies],
        api: {
          ...prev.api,
          baseUrl: axiosInstance.defaults.baseURL,
          lastChecked: new Date().toISOString(),
          responseTime: Math.round(endTime - startTime)
        }
      }));
    } catch (error) {
      console.error('Health check error:', error);
      setSystemInfo(prev => ({
        ...prev,
        health: {
          status: 'error',
          serverInfo: null,
          errorDetails: error.message || 'Failed to connect to server'
        }
      }));
    } finally {
      setLoading(false);
    }
  };

  // Regular health checks
  useEffect(() => {
    checkApiHealth();
    const interval = setInterval(checkApiHealth, 30000);
    return () => clearInterval(interval);
  }, []);

  // Authentication redirect
  useEffect(() => {
    if (!authLoading && isAuthenticated) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, authLoading, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.email || !formData.password) {
      setError('Email and password are required');
      return;
    }

    try {
      setLoading(true);
      const success = await login(formData.email, formData.password);
      if (success) navigate('/dashboard');
    } catch (error) {
      setError(error.response?.data?.message || 'Login failed');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setError(null);

    if (name === 'email') {
      setIsEmailValid(validateEmail(value));
    }
  };

  return (
    <Box sx={{ position: 'relative', minHeight: '100vh', overflow: 'hidden' }}>

      {/* Main Container */}
      <Container
        maxWidth="sm"
        sx={{
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
        }}
      >
        {/* Logo */}
        <Typography align="center" gutterBottom>
          <img src="/images/logo.png" alt="Logo" style={{ width: '20%', maxWidth: '300px' }} />
        </Typography>

        {/* Error Alert */}
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

        {/* Login Form */}
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <TextField
            fullWidth
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            margin="normal"
            required
            error={formData.email !== '' && !isEmailValid}
            helperText={formData.email !== '' && !isEmailValid ? 'Please enter a valid email' : ''}
          />
          {isEmailValid && (
            <TextField
              fullWidth
              label="Password"
              name="password"
              type="password"
              value={formData.password}
              onChange={handleChange}
              margin="normal"
              required
            />
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={loading || !isEmailValid}
            sx={{ mt: 2 }}
          >
            {loading ? <CircularProgress size={24} /> : 'Login'}
          </Button>
        </form>

        {/* Register Link */}
        <Box sx={{ textAlign: 'center', mt: 2 }}>
          <Button onClick={() => navigate('/register')}>Create New Account</Button>
        </Box>

        {/* System Status */}
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
              cursor: "pointer",
            }}
            onClick={handleToggle}
          >
            <Typography variant="h6">System Status</Typography>
            <Button size="small" variant="outlined" onClick={checkApiHealth} disabled={loading}>
              Refresh
            </Button>
          </Box>

          <Collapse in={expanded}>
            <Box sx={{ mt: 4, p: 2, backgroundColor: "rgba(255, 255, 255, 0.8)", borderRadius: 1 }}>
              {loading ? (
                <CircularProgress size={24} />
              ) : systemInfo.health.errorDetails ? (
                <Alert severity="error">API Connection Error: {systemInfo.health.errorDetails}</Alert>
              ) : (
                <>
                  {/* Server Info */}
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" color="primary">
                      Server Status
                    </Typography>
                    <Typography variant="body2">Title: {systemInfo.health.title || "Unknown"}</Typography>
                    <Typography variant="body2">Environment: {systemInfo.health.environment || "Unknown"}</Typography>
                    <Typography variant="body2">Uptime: {systemInfo.health.serverInfo?.uptime?.toFixed(2) || 0} seconds</Typography>
                    <Typography variant="body2">
                      Memory: {Math.round((systemInfo.health.serverInfo?.memory?.heapUsed || 0) / 1024 / 1024)} MB
                    </Typography>
                  </Box>

                  {/* API Info */}
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" color="primary">
                      API Details
                    </Typography>
                    <Typography variant="body2">Base URL: {systemInfo.api.baseUrl}</Typography>
                    <Typography variant="body2">
                      Response Time: {systemInfo.api.responseTime || 0}ms
                    </Typography>
                    <Typography variant="body2">
                      Last Checked:{" "}
                      {systemInfo.api.lastChecked
                        ? new Date(systemInfo.api.lastChecked).toLocaleTimeString()
                        : "Never"}
                    </Typography>
                  </Box>

                  {/* Browser Info */}
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" color="primary">
                      Browser Details
                    </Typography>
                    <Typography variant="body2">
                      Platform: {systemInfo.browser.isIOS
                        ? "iOS"
                        : systemInfo.browser.isMobile
                        ? "Mobile"
                        : "Desktop"}
                    </Typography>
                    <Typography variant="body2">
                      Environment: {systemInfo.browser.isDevelopment ? "Development" : "Production"}
                    </Typography>
                    <Typography variant="body2">User Agent: {systemInfo.browser.userAgent}</Typography>
                  </Box>
                </>
              )}
            </Box>
          </Collapse>
        </Box>
      </Container>

      {/* Fullscreen Background Video */}
      <Box
        component="video"
        src="/video/vau7t.mp4"
        autoPlay
        muted
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: -1,
        }}
      />

    </Box>
  );
};

export default LoginPage;