import React, { createContext, useState, useContext } from 'react';

// Create the context
const ChatTitleContext = createContext();

// Create a provider component
export const ChatTitleProvider = ({ children }) => {
    const [chatTitle, setChatTitle] = useState('');

    return (
        <ChatTitleContext.Provider value={{ chatTitle, setChatTitle }}>
            {children}
        </ChatTitleContext.Provider>
    );
};

// Custom hook to use the ChatTitleContext
export const useChatTitle = () => {
    return useContext(ChatTitleContext);
};