// Function to parse cookies from the document.cookie string
export const parseCookies = () => {
  try {
    const cookieString = document.cookie;
    const cookies = {};

    if (!cookieString) return cookies;

    cookieString.split(';').forEach(cookie => {
      const parts = cookie.split('=');
      const name = parts[0]?.trim();
      if (name) {
        try {
          // Handle different cookie value formats
          const value = parts.slice(1).join('=').trim();
          cookies[name] = decodeURIComponent(value.replace(/^"(.*)"$/, '$1'));
        } catch (e) {
          // Fallback for cookies that can't be decoded
          cookies[name] = parts.slice(1).join('=').trim();
        }
      }
    });

    // Log for debugging
    console.log('Parsed cookies:', {
      raw: document.cookie,
      parsed: cookies
    });

    return cookies;
  } catch (error) {
    console.error('Error parsing cookies:', error);
    return {};
  }
};

// Function to get a specific cookie by name
export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
};

// Function to get cookies stored in localStorage
export const getLocalStorageCookies = () => {
  try {
    const cookies = {};
    // Check both cookie_ prefix and raw token names
    const tokenNames = ['accessToken', 'refreshToken', 'healthCheck', 'healthCheckSecure'];
    
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      // Check for both prefixed and non-prefixed keys
      if (key?.startsWith('cookie_') || tokenNames.includes(key)) {
        const name = key.startsWith('cookie_') ? key.replace('cookie_', '') : key;
        cookies[name] = localStorage.getItem(key);
      }
    }

    // Log for debugging
    console.log('LocalStorage cookies:', cookies);
    
    return cookies;
  } catch (error) {
    console.error('Error getting localStorage cookies:', error);
    return {};
  }
};

// Function to detect platform information
export const detectPlatform = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const platform = navigator?.platform?.toLowerCase() || '';
  const vendor = navigator?.vendor?.toLowerCase() || '';

  // More accurate platform detection
  const isIOS = /iphone|ipad|ipod/.test(userAgent) || 
                /ipad|iphone|ipod/.test(platform) ||
                (platform === 'macintel' && navigator.maxTouchPoints > 0); // Modern iPads

  const isAndroid = /android/.test(userAgent) || /linux armv|aarch64/.test(platform);
  const isMobile = /(iphone|ipod|android|mobile)/.test(userAgent) || 
                  navigator.maxTouchPoints > 0;
  
  const isTablet = /(ipad|android(?!.*mobile))/.test(userAgent) || 
                   (isIOS && navigator.maxTouchPoints > 0);

  const isSafari = /safari/.test(userAgent) && 
                   !/chrome/.test(userAgent) && 
                   vendor.includes('apple');
                   
  const isChrome = /chrome/.test(userAgent) && 
                   /google/.test(vendor);

  const deviceInfo = {
    isIOS,
    isAndroid,
    isMobile,
    isTablet,
    isSafari,
    isChrome,
    userAgent: navigator.userAgent,
    deviceType: isTablet ? 'Tablet' : 
                isMobile ? 'Mobile' : 
                'Desktop',
    platform,
    vendor,
    touchPoints: navigator.maxTouchPoints
  };

  // Log for debugging
  console.log('Platform detection:', deviceInfo);

  return deviceInfo;
};

// Object to handle cookies
export const handleCookies = {
  set: (name, value, options = {}) => {
    const platform = detectPlatform();
    const isDevelopment = process.env.NODE_ENV === 'development';
    
    const defaultOptions = {
      path: '/',
      secure: !isDevelopment,
      sameSite: isDevelopment ? 'Lax' : 'None',
      expires: new Date(Date.now() + 24 * 60 * 60 * 1000).toUTCString()
    };

    if (platform.isIOS) {
      defaultOptions.sameSite = 'None';
      defaultOptions.secure = true;
    }

    const cookieOptions = { ...defaultOptions, ...options };
    const cookieString = Object.entries(cookieOptions)
      .reduce((acc, [key, value]) => {
        if (value === true) return `${acc}; ${key}`;
        if (value === false) return acc;
        return `${acc}; ${key}=${value}`;
      }, `${name}=${encodeURIComponent(value)}`);

    document.cookie = cookieString;

    // Always store in localStorage as backup
    try {
      localStorage.setItem(`cookie_${name}`, value);
      localStorage.setItem(name, value); // Also store without prefix
    } catch (e) {
      console.warn('Failed to store cookie in localStorage:', e);
    }
  },

  get: (name) => {
    const cookies = parseCookies();
    const browserValue = cookies[name];
    const storageValue = localStorage.getItem(`cookie_${name}`) || 
                        localStorage.getItem(name);
    return browserValue || storageValue;
  },

  getAll: () => {
    const browserCookies = parseCookies();
    const localStorageCookies = getLocalStorageCookies();

    // Log combined cookies for debugging
    console.log('All cookies:', {
      browser: browserCookies,
      localStorage: localStorageCookies
    });

    return {
      browser: browserCookies,
      localStorage: localStorageCookies,
      total: Object.keys(browserCookies).length + Object.keys(localStorageCookies).length
    };
  },

  remove: (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    localStorage.removeItem(`cookie_${name}`);
    localStorage.removeItem(name);
  }
};