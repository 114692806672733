import React, { useState } from 'react';
import { Box, CircularProgress, useMediaQuery } from '@mui/material';
import Header from '../components/Header.js';
import Sidebar from '../components/Sidebar.js';
import Chat from '../components/Chat.js';

const TestPage = () => {
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [channels] = useState([
  ]);
  const [loading, setLoading] = useState(false);

  // Sidebar visibility adjustment on mobile
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [isSidebarOpen, setSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };

  const handleChannelSelect = (channel) => {
    setSelectedChannel(channel);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', height: '100vh', flexDirection: 'column' }}>
      {/* Header */}
      <Header toggleSidebar={toggleSidebar} />

      <Box sx={{ display: 'flex', flex: 1, marginTop: 8 }}>
        {/* Sidebar */}
        <Box
          sx={{
            position: 'fixed',
            top: 60, // Adjust for the header height
            left: isSidebarOpen ? 0 : -250, // Sidebar moves out of view when closed
            width: 250,
            backgroundColor: '#f4f4f4',
            height: '100vh',
            transition: 'left 0.3s ease',
          }}
        >
          <Sidebar channels={channels} onChannelSelect={handleChannelSelect} />
        </Box>

        {/* Main content (Chat Component) */}
        <Chat selectedChannel={selectedChannel} />
      </Box>
    </Box>
  );
};

export default TestPage;
