import React, { useState, useRef, useEffect, useCallback } from "react";
import { 
  Box, 
  Snackbar, 
  Typography, 
  Container, 
  useMediaQuery, 
  useTheme, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  TextField, 
  CircularProgress, 
  IconButton, 
  Alert,
  Divider
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import EmailIcon from '@mui/icons-material/Email';
import NewsFeed from "../components/NewsFeed.js";
import ExchangeModule from "../components/ExchangeModule.js";
import VideoContainer from "../components/VideoContainer.js";
import axiosInstance from '../services/api.js';
import { Helmet } from "react-helmet-async";

const Home = () => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [newsLoading, setNewsLoading] = useState(true);
  const [launchFailed, setLaunchFailed] = useState(false);
  
  // Contact form state
  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const [contactForm, setContactForm] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [contactFormErrors, setContactFormErrors] = useState({});
  const [sending, setSending] = useState(false);
  const [sendSuccess, setSendSuccess] = useState(false);
  const [sendError, setSendError] = useState(null);
  
  // Simplify scroll tracking
  const [hasScrolled, setHasScrolled] = useState(false);
  const lastScrollTop = useRef(0);
  
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const headerHeight = 10; // Match header height
  const newsLogoRef = useRef(null);
  const exchangeRef = useRef(null);
  const videoSectionRef = useRef(null);
  const contactBtnRef = useRef(null);
  
  // Flag to prevent recursion
  const isProcessingRef = useRef(false);

  const handleSnackbarClose = () => setOpenSnackbar(false);

  // Define footerHeight constant
  const footerHeight = {
    xs: 80, // mobile
    sm: 100 // desktop
  };

  // Contact form handlers
  const handleOpenContactDialog = useCallback(() => {
    setContactDialogOpen(true);
    setContactForm({
      name: '',
      email: '',
      subject: '',
      message: ''
    });
    setContactFormErrors({});
    setSendSuccess(false);
    setSendError(null);
  }, []);

  const handleCloseContactDialog = useCallback(() => {
    if (sending) return; // Prevent closing while sending
    setContactDialogOpen(false);
  }, [sending]);

  const handleContactFormChange = useCallback((e) => {
    const { name, value } = e.target;
    setContactForm(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Clear error when user types
    if (contactFormErrors[name]) {
      setContactFormErrors(prev => ({
        ...prev,
        [name]: null
      }));
    }
  }, [contactFormErrors]);

  const validateContactForm = useCallback(() => {
    const errors = {};
    if (!contactForm.name.trim()) errors.name = 'Name is required';
    if (!contactForm.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(contactForm.email)) {
      errors.email = 'Invalid email address';
    }
    if (!contactForm.subject.trim()) errors.subject = 'Subject is required';
    if (!contactForm.message.trim()) errors.message = 'Message is required';
    
    setContactFormErrors(errors);
    return Object.keys(errors).length === 0;
  }, [contactForm]);

  const handleSubmitContactForm = useCallback(async () => {
    if (!validateContactForm()) return;
    
    setSending(true);
    setSendSuccess(false);
    setSendError(null);
    
    try {
      // Get browser and device information
      const browserInfo = {
        userAgent: navigator.userAgent,
        language: navigator.language,
        platform: navigator.platform,
        screenWidth: window.screen.width,
        screenHeight: window.screen.height,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        timestamp: new Date().toISOString()
      };
      
      // Fix the API endpoint path to include the /api prefix
      const response = await axiosInstance.post('/api/email/send-email', {
        to: 'contact@transfer.co', // Replace with your desired recipient
        subject: `Contact Form: ${contactForm.subject}`,
        text: `
          Name: ${contactForm.name}
          Email: ${contactForm.email}
          
          Message:
          ${contactForm.message}
          
          -------- Sender Information --------
          
          Date/Time: ${browserInfo.timestamp}
          Browser: ${browserInfo.userAgent}
          Language: ${browserInfo.language}
          Platform: ${browserInfo.platform}
          Screen Size: ${browserInfo.screenWidth}x${browserInfo.screenHeight}
          Timezone: ${browserInfo.timeZone}
          
          Note: IP address will be added by server
        `
      });
      
      console.log('Email sent successfully:', response.data);
      setSendSuccess(true);
      
      // Show success in snackbar
      setErrorMessage('Your message has been sent successfully!');
      setOpenSnackbar(true);
      
      // Close dialog after a short delay
      setTimeout(() => {
        setContactDialogOpen(false);
      }, 2000);
      
    } catch (error) {
      console.error('Failed to send email:', error);
      // More detailed error messaging for debugging
      if (error.response) {
        // Server responded with a status code outside of 2xx range
        console.error('Error response data:', error.response.data);
        console.error('Error response status:', error.response.status);
        setSendError(`Server error: ${error.response.status} - ${error.response.data?.error || 'Unknown error'}`);
      } else if (error.request) {
        // Request was made but no response received
        console.error('No response received:', error.request);
        setSendError('No response from server. Please check your connection and try again.');
      } else {
        // Error setting up the request
        setSendError(error.message || 'Failed to send your message. Please try again.');
      }
    } finally {
      setSending(false);
    }
  }, [contactForm, validateContactForm]);

  // Add keyboard handler for ENTER key submission
  const handleKeyPress = useCallback((e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      // Only handle ENTER for single-line text fields
      if (e.target.name !== 'message') {
        e.preventDefault();
        handleSubmitContactForm();
      }
    }
  }, [handleSubmitContactForm]);

  // Footer contact button click handler
  const handleContactButtonClick = useCallback(() => {
    handleOpenContactDialog();
  }, [handleOpenContactDialog]);

  // Function to trigger exchange from any source - using the Header.js method
  const triggerExchange = useCallback((source = 'unknown') => {
    // Prevent recursion and double popups
    if (isProcessingRef.current) return;
    isProcessingRef.current = true;
    
    console.log(`Triggering exchange from ${source}`);
    
    try {
      // PRIORITY METHOD: Dispatch the custom event first
      // This is what Header.js listens for and will open the correct dialog
      const event = new CustomEvent('openExchangeDialog', { detail: { source } });
      window.dispatchEvent(event);
      
      // FALLBACK METHOD: If for some reason the event doesn't work,
      // try calling the global function directly
      if (typeof window.openExchangeDialog === 'function') {
        // Add a small delay to avoid double-triggering
        setTimeout(() => {
          try {
            window.openExchangeDialog(source);
            console.log('Fallback exchange dialog function called');
          } catch (err) {
            console.warn('Fallback exchange dialog call failed', err);
          }
        }, 100);
      }
      
      // Reset processing flag after a short delay
      setTimeout(() => {
        isProcessingRef.current = false;
      }, 500);
    } catch (error) {
      console.error("Failed to trigger exchange:", error);
      setLaunchFailed(true);
      setErrorMessage("Exchange service is currently unavailable. Please try again later.");
      setOpenSnackbar(true);
      
      // Visual feedback on failure
      const sourceElement = source === 'video' ? videoSectionRef.current :
                           source === 'header-logo' ? newsLogoRef.current : null;
      
      if (sourceElement) {
        sourceElement.style.backgroundColor = 'rgba(255, 0, 0, 0.3)';
        setTimeout(() => {
          sourceElement.style.backgroundColor = 'transparent';
        }, 2000);
      }
      
      // Reset processing flag
      isProcessingRef.current = false;
    }
  }, []);

  // Event handlers for different click sources
  const handleVideoClick = useCallback((e) => {
    e.preventDefault();
    // Use the same method as header logo click
    triggerExchange('video');
  }, [triggerExchange]);

  const handleNewsLogoClick = useCallback((e) => {
    e.preventDefault();
    // Use the same method as header logo click
    triggerExchange('header-logo');
  }, [triggerExchange]);

  // Reset state on component mount
  useEffect(() => {
    setLaunchFailed(false);
    setErrorMessage(null);
  }, []);

  // Override ExchangeModule's openExchangeDialog to use our method instead
  useEffect(() => {
    // Save original function to restore later
    const originalOpenExchangeDialog = window.openExchangeDialog;
    
    // Force attach a listener for clicks on our exchange module
    // This should make click handling more reliable
    const handleExchangeClick = (event) => {
      if (exchangeRef.current && exchangeRef.current.contains(event.target)) {
        console.log('Clicked directly on exchange module, not triggering popup');
        // Don't do anything when clicking directly on the module
      }
    };
    
    document.addEventListener('click', handleExchangeClick);
    
    return () => {
      // Restore original function if needed
      if (originalOpenExchangeDialog && originalOpenExchangeDialog !== window.openExchangeDialog) {
        window.openExchangeDialog = originalOpenExchangeDialog;
      }
      document.removeEventListener('click', handleExchangeClick);
    };
  }, []);

  // Simplified scroll tracking
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setHasScrolled(currentScrollTop > 0);
      lastScrollTop.current = currentScrollTop;
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        background: 'linear-gradient(45deg, rgb(0, 205, 41) 0%, rgb(161, 225, 0) 100%)',
        color: "#FFF",
        m: 0,
        p: 0,
        pt: `${headerHeight}px`, // Account for fixed header
      }}
    >
      <Helmet>
        <title>Transfer.co</title>
        <meta
          name="description"
          content="Secure, fast crypto transfers and the latest market news."
        />
        <meta property="og:title" content="Transfer.co" />
        <meta
          property="og:description"
          content="Secure, fast crypto transfers and the latest market news."
        />
        <meta
          property="og:image"
          content="/images/default-image.jpg"
        />
      </Helmet>

      {/* Contact Form Dialog */}
      <Dialog 
        open={contactDialogOpen} 
        onClose={handleCloseContactDialog}
        fullWidth
        fullScreen={isMobile} // Full screen on mobile devices
        maxWidth="sm"
        PaperProps={{
          sx: {
            borderRadius: isMobile ? 0 : '12px', // No rounded corners on mobile
            backgroundColor: 'white',
            backgroundImage: 'linear-gradient(to bottom right, rgba(255,255,255,0.9), rgba(240,240,240,0.9))',
            boxShadow: '0 8px 32px rgba(0,0,0,0.2)',
            margin: isMobile ? '60px 0 0 0' : undefined, // Add top margin for header on mobile
            height: isMobile ? 'calc(100% - 60px)' : 'auto', // Adjust height on mobile
          }
        }}
      >
        <DialogTitle 
          sx={{ 
            background: 'linear-gradient(90deg, #00D632 0%, #A1E100 100%)',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            pb: 1,
            px: isMobile ? 2 : 3, // Adjusted padding for mobile
          }}
        >
          {/* Changed title from "Contact Us" to "Transfer.co" */}
          <Box sx={{ 
            fontSize: isMobile ? '1.1rem' : '1.25rem', 
            fontWeight: 500,
            fontFamily: theme.typography.fontFamily,
            display: 'flex',
            alignItems: 'center'
          }}>
            Transfer.co
          </Box>
          <IconButton 
            onClick={handleCloseContactDialog} 
            disabled={sending}
            sx={{ color: 'white' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        
        <DialogContent sx={{ 
          pt: 3, 
          pb: 2,
          px: isMobile ? 2 : 3, // Adjusted padding for mobile
          display: 'flex',
          flexDirection: 'column',
          flex: isMobile ? 1 : 'unset', // Allow content to expand on mobile
        }}>
          {sendSuccess ? (
            <Alert severity="success" sx={{ mb: 2 }}>
              Thank you! Your message has been sent successfully.
            </Alert>
          ) : sendError ? (
            <Alert severity="error" sx={{ mb: 2 }}>
              {sendError}
            </Alert>
          ) : null}
          
          {/* Form fields with keyboard event handlers */}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="name"
            label="Your Name"
            type="text"
            fullWidth
            variant="outlined"
            value={contactForm.name}
            onChange={handleContactFormChange}
            onKeyPress={handleKeyPress}
            error={!!contactFormErrors.name}
            helperText={contactFormErrors.name}
            disabled={sending || sendSuccess}
            sx={{ mb: 1 }}
          />
          
          <TextField
            margin="dense"
            id="email"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="outlined"
            value={contactForm.email}
            onChange={handleContactFormChange}
            onKeyPress={handleKeyPress}
            error={!!contactFormErrors.email}
            helperText={contactFormErrors.email}
            disabled={sending || sendSuccess}
            sx={{ mb: 1 }}
          />
          
          <TextField
            margin="dense"
            id="subject"
            name="subject"
            label="Subject"
            type="text"
            fullWidth
            variant="outlined"
            value={contactForm.subject}
            onChange={handleContactFormChange}
            onKeyPress={handleKeyPress}
            error={!!contactFormErrors.subject}
            helperText={contactFormErrors.subject}
            disabled={sending || sendSuccess}
            sx={{ mb: 1 }}
          />
          
          <TextField
            margin="dense"
            id="message"
            name="message"
            label="Your Message"
            multiline
            rows={isMobile ? 6 : 4} // More rows on mobile
            fullWidth
            variant="outlined"
            value={contactForm.message}
            onChange={handleContactFormChange}
            error={!!contactFormErrors.message}
            helperText={contactFormErrors.message}
            disabled={sending || sendSuccess}
            sx={{ 
              mb: 1,
              flex: isMobile ? 1 : 'unset', // Allow textarea to grow on mobile
            }}
          />
        </DialogContent>
        
        <DialogActions sx={{ 
          px: isMobile ? 2 : 3, 
          pb: isMobile ? 3 : 2,
          gap: isMobile ? 2 : 1, // More spacing between buttons on mobile
        }}>
          <Button 
            onClick={handleCloseContactDialog} 
            color="inherit"
            disabled={sending}
            sx={{
              px: isMobile ? 3 : 2, // Wider buttons on mobile
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleSubmitContactForm} 
            variant="contained"
            color="primary"
            disabled={sending || sendSuccess}
            startIcon={sending ? <CircularProgress size={20} color="inherit" /> : <SendIcon />}
            sx={{
              px: isMobile ? 3 : 2, // Wider buttons on mobile
            }}
          >
            {sending ? 'Sending...' : 'Send Message'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Main content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          p: 0,
          px: { xs: 1, sm: 3, md: 5 },
          m: 0,
        }}
      >
        {/* Video Section */}
        <Box
          ref={videoSectionRef}
          sx={{
            position: "relative",
            width: "100%",
            height: isMobile ? "40vh" : isDesktop ? "80vh" : "60vh",
            minHeight: isMobile ? "250px" : "400px",
            overflow: "hidden",
            borderRadius: { xs: '24px', md: '48px' },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            m: 1,
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
            transition: 'all 0.3s ease',
            '&:hover': {
              transform: 'scale(1.01)',
              boxShadow: '0 8px 30px rgba(0,0,0,0.15)',
            }
          }}
          onClick={handleVideoClick}
          data-source="video"
          role="button"
          aria-label="Open exchange"
          tabIndex={0}
        >
          {/* Video container with matching border radius */}
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 1,
              '& video': {
                borderRadius: { xs: '24px', md: '48px' },
              }
            }}
          >
            <VideoContainer />
          </Box>

          {/* Typography overlayed above the video */}
          <Typography
            variant="h1"
            sx={{
              position: "absolute",
              bottom: isMobile ? "15%" : "10%",
              left: 0,
              right: 0,
              zIndex: 2,
              fontWeight: 900,
              p: 0,
              m: 0,
              fontSize: { xs: "2rem", sm: "3rem", md: "5rem" },
              lineHeight: { xs: 1.1, sm: 1.2, md: 1.5 },
              textAlign: "center",
              background: "linear-gradient(90deg, #00D632 0%, #A1E100 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Secure. Fast. Transfer.
          </Typography>
        </Box>
      </Box>

      <Container
        disableGutters
        maxWidth={false}
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          m: 0,
          p: 0,
        }}
      >
        <Box sx={{ 
          textAlign: "left",
          width: '100%',
          p: 0,
          px: isMobile ? 1 : 2,
        }}>
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: "2.5rem", sm: "3.5rem", md: "5rem" },
              fontWeight: "900",
              lineHeight: 1,
              m: 0,
              p: 0,
              py: 2,
              background: "linear-gradient(90deg,rgb(255, 255, 255) 0%,rgb(237, 237, 237) 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            You Are The Bank Now
          </Typography>
        </Box>
        
        <Box
          sx={{
            maxWidth: isMobile ? "100%" : "480px",
            width: "100%",
            overflow: "hidden",
            p: 0,
            m: 0,
            pr: { xs: 0, md: 4 },
          }}
        >
          {/* Typography for additional information */}
          <Box sx={{ 
            position: "relative", 
            zIndex: 2, 
            textAlign: "left", 
            p: 0,
            px: 1,
            m: 0
          }}>
            <Typography
              variant="h6"
              sx={{
                fontSize: { xs: "1rem", sm: "1.25rem", md: "1.75rem" },
                background: "linear-gradient(90deg,rgb(255, 255, 255) 0%,rgb(146, 190, 17) 100%)",
                WebkitBackgroundClip: "text",
                fontWeight: 500,
                color: "white",
                m: 0,
              }}
            >
              One-click Swap.
            </Typography>

            <Typography
              variant="h6"
              sx={{
                fontSize: { xs: "1rem", sm: "1.25rem", md: "1.75rem" },
                fontWeight: 500,
                color: "white",
                textAlign: "right",
                m: 0,
              }}
            >
              Instant Wallet-to-Wallet Transfer
            </Typography>
          </Box>

          {/* Reference to the ExchangeModule component for direct access */}
          <Box 
            ref={exchangeRef}
            sx={{
              mt: 2,
              borderRadius: '12px',
              overflow: 'hidden',
              transition: 'all 0.3s ease',
              boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
            }}
          >
            <ExchangeModule />
          </Box>
        </Box>
      </Container>

      {/* News Section */}
      <Box sx={{ 
        width: "100%", 
        backgroundColor: "#FFF", 
        color: "#000", 
        position: "relative", 
        zIndex: 10, 
        m: 0,
        p: 0,
      }} id="news-section">
        {/* Fixed header bar for news section */}
        <Box 
          sx={{ 
            width: "100%", 
            backgroundColor: "rgba(255, 255, 255, 0.95)",
            borderTop: "1px solid rgba(0,0,0,0.1)",
            borderBottom: "1px solid rgba(0,0,0,0.1)",
            height: isMobile ? "60px" : "80px",
            display: "flex",
            alignItems: "center",
            backdropFilter: "blur(10px)",
            boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
            m: 0,
            p: 0,
          }}
        >
          <Container disableGutters maxWidth="xl">
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              height: '100%',
              gap: isMobile ? 1 : 2,
              px: isMobile ? 1 : 2,
              m: 0,
            }}>
              {/* Logo container - uses exact same method as header logo */}
              <Box
                ref={newsLogoRef}
                onClick={handleNewsLogoClick}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  padding: '4px',
                  borderRadius: '50%',
                  transition: 'all 0.3s ease',
                  '&:hover': { transform: 'scale(1.05)' },
                }}
                data-source="header-logo"
                role="button"
                aria-label="Open exchange"
                tabIndex={0}
              >
                <img
                  src="/images/logo.png"
                  alt="Logo"
                  style={{ 
                    width: isMobile ? 30 : 40,
                    height: isMobile ? 30 : 40,
                    objectFit: 'contain'
                  }}
                />
              </Box>

              <Typography 
                variant={isMobile ? "h5" : "h4"}
                sx={{ 
                  fontWeight: 900,
                  textAlign: "left",
                  background: "linear-gradient(90deg, #00D632 0%, #A1E100 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  m: 0,
                }}
              >
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: isMobile ? 1 : 2,
                  m: 0,
                }}>
                  <Box sx={{ 
                    flex: 1, 
                    height: 2, 
                    bgcolor: '#00D632',
                    display: { xs: 'none', sm: 'block' },
                    m: 0,
                  }} />
                  Crypto News
                  <Box sx={{ 
                    flex: 1, 
                    height: 2, 
                    bgcolor: '#A1E100',
                    display: { xs: 'none', sm: 'block' },
                    m: 0,
                  }} />
                </Box>
              </Typography>
            </Box>
          </Container>
        </Box>

        <Container disableGutters maxWidth={false} sx={{ p: 0, m: 0 }}>
          {/* Loading overlay */}
          {newsLoading && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                backgroundImage: "url('/images/loading.gif')",
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                zIndex: 10,
              }}
            >
              <CircularProgress
                size={40}
                thickness={4}
                sx={{ color: "#00D632", mb: 2 }}
              />
              <Typography variant="body2" sx={{ color: "white", fontWeight: "bold" }}>
                Loading news...
              </Typography>
            </Box>
          )}
          <NewsFeed
            onLoadingChange={(isLoading) => {
              setNewsLoading(isLoading);
            }}
          />
        </Container>
      </Box>

      {/* Footer with Contact Us button */}
      <Box
        component="footer"
        sx={{
          width: "100%",
          background: 'linear-gradient(90deg, rgb(0, 255, 51) 0%, rgb(161, 225, 0) 100%)',
          borderTop: "1px solid rgba(255,255,255,0.1)",
          py: { xs: 2, sm: 3 },
          mt: { xs: 4, sm: 6 },
          textAlign: "center",
          minHeight: { xs: `${footerHeight.xs}px`, sm: `${footerHeight.sm}px` },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          zIndex: 2,
          gap: 2
        }}
      >
        {/* Contact button */}
        <Box 
          ref={contactBtnRef}
          onClick={handleContactButtonClick}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
            padding: '8px 16px',
            borderRadius: '20px',
            background: 'rgba(255, 255, 255, 0.2)',
            cursor: 'pointer',
            transition: 'all 0.3s ease',
            mb: 1,
            backdropFilter: 'blur(5px)',
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
            '&:hover': {
              background: 'rgba(255, 255, 255, 0.3)',
              transform: 'translateY(-2px)',
              boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
            }
          }}
        >
          <EmailIcon sx={{ fontSize: 20 }} />
          <Typography variant="body2" fontWeight="medium">
            Contact us
          </Typography>
        </Box>
        
        <Divider sx={{ width: '80%', borderColor: 'rgba(255,255,255,0.2)', my: 1 }} />
        
        <Typography variant="body2">© 2025 Transfer.co All rights reserved.</Typography>
      </Box>

      {/* Snackbar for notifications */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        message={errorMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#000",
            border: "1px solid #00D632",
            color: "#FFF",
            p: 0,
          },
        }}
      />
    </Box>
  );
};

export default Home;