import React, { useEffect, useState, useCallback, memo, useRef } from "react";
import { 
  Box, Typography, CircularProgress, Grid, Card, CardContent, CardMedia, Button
} from "@mui/material";
import axiosInstance from '../services/api.js';
import { Helmet } from 'react-helmet-async'; // Updated to use react-helmet-async
import { useLocation } from 'react-router-dom';

// Memoized NewsCard component for better performance
const NewsCard = memo(({ article, onClick }) => {
  const [expanded, setExpanded] = useState(false);
  const imageUrl = article.heroImageUrl || 
                  (article['media:content']?.[0]?.url) || 
                  article.enclosure?.url;

  const handleImageClick = useCallback((e) => {
    e.stopPropagation(); // Prevent card expansion
    onClick(article); // Navigate to source
  }, [article, onClick]);

  return (
    <Card
      sx={{
        maxWidth: 600,
        boxShadow: 3,
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          transform: expanded ? 'none' : 'scale(1.02)',
          boxShadow: expanded ? 3 : 5
        }
      }}
      onClick={() => setExpanded(!expanded)}
    >
      <CardContent 
        sx={{ 
          flexGrow: 1,
          transition: 'all 0.3s ease-in-out',
          padding: 1
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{
            fontWeight: "bold",
            textAlign: "left",
            mb: 0.5,
            lineHeight: 1.1,
            fontSize: {
              xs: '1.5rem',
              sm: '1.25rem'
            }
          }}
        >
          {article.title}
        </Typography>
      </CardContent>

      {imageUrl && (
        <Box 
          sx={{ 
            position: 'relative',
            cursor: 'pointer',
            '&:hover': {
              '& .overlay': {
                opacity: 1
              }
            }
          }}
          onClick={handleImageClick}
        >
          <CardMedia
            component="img"
            height={expanded ? 400 : 300}
            image={imageUrl}
            alt={article.title}
            sx={{ 
              objectFit: "cover",
              transition: 'height 0.3s ease-in-out'
            }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/images/logo.png";
            }}
          />
          <Box 
            className="overlay"
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              opacity: 0,
              transition: 'opacity 0.2s ease-in-out',
              color: 'white',
              fontSize: '1rem',
              fontWeight: 'bold'
            }}
          >
            Visit Source Article
          </Box>
        </Box>
      )}

      <CardContent sx={{ p: 1 }}>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            mb: 0.5,
            textAlign: "left",
            fontSize: {
              xs: '1rem',
              sm: '0.875rem'
            }
          }}
        >
          Source: {article.source}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ 
            textAlign: 'left',
            display: '-webkit-box',
            WebkitLineClamp: expanded ? undefined : 3,
            WebkitBoxOrient: 'vertical',
            overflow: expanded ? 'visible' : 'hidden',
            fontSize: {
              xs: '1.1rem',
              sm: '1.3rem',
              md: '1.4rem'
            },
            lineHeight: 1.5
          }}
          dangerouslySetInnerHTML={{ 
            __html: article.description?.replace(/<img[^>]*>/g, '') 
          }}
        />
        <Button
          variant="text"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            setExpanded(!expanded);
          }}
          sx={{ 
            mt: 0.5,
            fontSize: {
              xs: '1rem',
              sm: '0.875rem'
            }
          }}
        >
          {expanded ? 'Show Less' : 'Read More'}
        </Button>
      </CardContent>
    </Card>
  );
});

NewsCard.displayName = 'NewsCard'; // Add display name for memo component

const NewsFeed = ({ onLoadingChange }) => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const location = useLocation();
  
  // Track fetched article URLs to prevent duplicates
  const fetchedArticleUrls = useRef(new Set());

  // Notify parent component about loading state
  useEffect(() => {
    if (onLoadingChange) {
      onLoadingChange(loading);
    }
  }, [loading, onLoadingChange]);

  // Memoized fetch function
  const fetchNews = useCallback(async (pageNum) => {
    try {
      setLoading(true);
      
      // Include already fetched URLs to prevent duplicates
      const response = await axiosInstance.get('/api/news/crypto', {
        params: { 
          start: pageNum * 10, 
          limit: 10,
          exclude: Array.from(fetchedArticleUrls.current).join(',')
        }
      });
      
      const newArticles = response.data.articles || [];
      
      // Update fetched URLs set
      newArticles.forEach(article => {
        if (article.link) {
          fetchedArticleUrls.current.add(article.link);
        }
      });
      
      // Update state
      setNews(prev => pageNum === 0 ? newArticles : [...prev, ...newArticles]);
      setHasMore(response.data.hasMore);
      
    } catch (error) {
      console.error("Failed to fetch news:", error.message);
    } finally {
      setLoading(false);
      setInitialLoad(false);
    }
  }, []);

  // Initial load
  useEffect(() => {
    // Reset state when route changes
    setNews([]);
    setPage(0);
    setHasMore(true);
    setInitialLoad(true);
    fetchedArticleUrls.current.clear();
    fetchNews(0);
  }, [location.pathname, fetchNews]);

  // Memoized handlers
  const handleArticleClick = useCallback((article) => {
    if (article?.link) {
      window.open(article.link, "_blank");
    }
  }, []);

  const loadMoreNews = useCallback(async () => {
    if (!loading && hasMore) {
      try {
        setLoading(true);
        const nextPage = page + 1;
        
        // Pass already fetched URLs to backend to exclude them
        const response = await axiosInstance.get('/api/news/crypto', {
          params: { 
            start: nextPage * 10, 
            limit: 10,
            exclude: Array.from(fetchedArticleUrls.current).join(',')
          }
        });
        
        const newArticles = response.data.articles || [];
        
        // Update fetched URLs set with new articles
        newArticles.forEach(article => {
          if (article.link) {
            fetchedArticleUrls.current.add(article.link);
          }
        });
        
        setNews(prev => [...prev, ...newArticles]);
        setHasMore(response.data.hasMore);
        setPage(nextPage);
        
        // Scroll to the first new article
        if (newArticles.length > 0) {
          const firstNewArticle = document.getElementById(`article-${news.length}`);
          if (firstNewArticle) {
            firstNewArticle.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }
      } catch (error) {
        console.error("Failed to load more articles:", error.message);
      } finally {
        setLoading(false);
      }
    }
  }, [loading, hasMore, page, news.length]);

  // Loading state
  if (initialLoad) {
    return (
      <Box sx={{ 
        display: "flex", 
        flexDirection: "column",
        justifyContent: "center", 
        alignItems: "center", 
        minHeight: '60vh'
      }}>
        <CircularProgress size={60} sx={{ mt: 2 }} />
      </Box>
    );
  }

  return (
    <Box sx={{ textAlign: "center", px: 1, py: 1 }}>
      {/* Use HelmetProvider at App level and use Helmet here */}
      <Helmet>
        <title>Crypto News</title>
        <meta name="description" content="Latest crypto news and updates" />
      </Helmet>

      {news.length > 0 ? (
        <>
          <Grid container spacing={2}>
            {news.map((article, index) => (
              <Grid 
                item 
                xs={12} 
                sm={6} 
                md={4} 
                key={`${article.link || index}-${index}`}
                id={`article-${index}`}
              >
                <NewsCard 
                  article={article} 
                  onClick={handleArticleClick}
                />
              </Grid>
            ))}
          </Grid>
          
          {hasMore && (
            <Box sx={{ 
              mt: 2, 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              gap: 1 
            }}>
              <Button 
                variant="contained" 
                onClick={loadMoreNews} 
                disabled={loading}
                sx={{ 
                  px: 2,
                  py: 1,
                  fontSize: '1.1rem',
                  minWidth: '250px',
                  position: 'relative',
                  ...(loading && {
                    backgroundColor: 'rgba(0, 0, 0, 0.12)',
                    color: 'text.secondary'
                  })
                }}
              >
                {loading ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CircularProgress 
                      size={24} 
                      sx={{ 
                        color: 'primary.main',
                        position: 'absolute',
                        left: '20px'
                      }} 
                    />
                    <Typography>
                      Loading articles...
                    </Typography>
                  </Box>
                ) : (
                  'Load More Articles'
                )}
              </Button>
              {loading && (
                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                  Fetching next {10} articles...
                </Typography>
              )}
            </Box>
          )}
        </>
      ) : (
        <Typography variant="body1" color="error" sx={{ mt: 2 }}>
          No news available. Please try again later.
        </Typography>
      )}
    </Box>
  );
};

export default NewsFeed;