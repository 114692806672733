import React, { useState, useCallback, useEffect } from 'react';
import { 
    Box, 
    Paper, 
    Button, 
    Typography, 
    CircularProgress,
    Card,
    CardContent,
    Grid,
    Chip,
    Snackbar,
    Alert
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonIcon from '@mui/icons-material/Person';
import SecurityIcon from '@mui/icons-material/Security';
import { useAuth } from '../contexts/AuthContext.js';
import axiosInstance from '../services/api.js';
import SessionMonitor from '../components/SessionMonitor.js';
import Header from '../components/Header.js';



const formatTimeRemaining = (milliseconds) => {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);

    seconds = seconds % 60;
    minutes = minutes % 60;
    hours = hours % 24;

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
};

const AuthDashboard = () => {
    const { 
        user, 
        accessToken,
        isAuthenticated,
        accessTokenExpiration,
        refreshTokenExpiration,
        isRefreshing,
        refreshMessage,
        timeUntilRefresh,
        error,
        refreshTokens,
        updateSession   
    } = useAuth();

    const [localRefreshing, setLocalRefreshing] = useState(false);
    const [sessionTime, setSessionTime] = useState('');
    const [feedback, setFeedback] = useState({ open: false, message: '', severity: '' });
    const [logs, setLogs] = useState([]);

    // Add log helper
    const addLog = useCallback((type, message, details = null) => {
        const timestamp = new Date().toISOString();
        setLogs(prev => [...prev, {
            type,
            message: typeof message === 'object' ? JSON.stringify(message, null, 2) : message,
            details: details ? JSON.stringify(details, null, 2) : null,
            timestamp
        }]);
        
        // Also log to console for debugging
        console.log(`[${timestamp}] ${type}:`, message, details || '');
    }, []);

    // Calculate time until expiration
    useEffect(() => {
        const timer = setInterval(() => {
            const now = Date.now();
            if (accessTokenExpiration) {
                const remaining = accessTokenExpiration - now;
                if (remaining > 0) {
                    const hours = Math.floor(remaining / (1000 * 60 * 60));
                    const minutes = Math.floor((remaining % (1000 * 60 * 60)) / (1000 * 60));
                    const seconds = Math.floor((remaining % (1000 * 60)) / 1000);
                    setSessionTime(`${hours}h ${minutes}m ${seconds}s`);
                } else {
                    setSessionTime('Expired');
                }
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [accessTokenExpiration]);

    // Handle token generation
    const handleGenerateTokens = useCallback(async () => {
        if (!user?._id || !accessToken) {
          setFeedback({
            open: true,
            message: 'Authentication required',
            severity: 'warning'
          });
          return;
        }
      
        try {
          setLocalRefreshing(true);
          addLog('info', '🚀 Starting token generation');
          
          const response = await axiosInstance.post('/api/auth/generate-tokens', 
            { userId: user._id },
            {
              headers: { 'Authorization': `Bearer ${accessToken}` },
              withCredentials: true
            }
          );
      
          addLog('success', '✅ Tokens generated successfully');
          addLog('info', 'Response data:', response.data);
      
          if (response.data.accessToken) {
            const { accessToken, accessTokenExpiration, refreshTokenExpiration } = response.data;
            
            const success = await updateSession({
              accessToken,
              accessTokenExpiration,
              refreshTokenExpiration
            });
            
            if (success) {
              addLog('success', '✅ Session updated with new tokens');
              setFeedback({
                open: true,
                message: 'New tokens generated and session updated',
                severity: 'success'
              });
            } else {
              throw new Error('Failed to update session');
            }
          } else {
            throw new Error('Invalid token generation response');
          }
        } catch (error) {
          console.error('Failed to generate tokens:', error);
          addLog('error', '❌ Token generation failed:', error.message);
          
          setFeedback({
            open: true,
            message: error.response?.data?.message || 'Failed to generate tokens',
            severity: 'error'
          });
        } finally {
          setLocalRefreshing(false);
        }
      }, [user, accessToken, updateSession, addLog]);

    // Handle manual token refresh
    const handleRefresh = useCallback(async () => {
        try {
            setLocalRefreshing(true);
            addLog('info', '🔄 Starting token refresh');

            const success = await refreshTokens();

            if (success) {
                addLog('success', '✅ Session refreshed successfully');
                setFeedback({
                    open: true,
                    message: 'Session refreshed with new tokens',
                    severity: 'success'
                });
            } else {
                throw new Error('Token refresh failed');
            }
        } catch (error) {
            addLog('error', '❌ Session refresh failed:', error.message);
            setFeedback({
                open: true,
                message: 'Failed to refresh session',
                severity: 'error'
            });
        } finally {
            setLocalRefreshing(false);
        }
    }, [refreshTokens, addLog]);

    // Handle feedback close
    const handleCloseFeedback = useCallback(() => {
        setFeedback({ ...feedback, open: false });
    }, [feedback]);

    // Render session status chip
    const renderStatusChip = useCallback(() => {
        if (isRefreshing || localRefreshing) {
            return (
                <Chip 
                    label="Refreshing..."
                    color="info"
                    icon={<CircularProgress size={16} />}
                />
            );
        }
        if (!isAuthenticated) {
            return <Chip label="Not Authenticated" color="error" />;
        }
        if (timeUntilRefresh && timeUntilRefresh <= 30) {
            return (
                <Chip 
                    label="Expiring Soon"
                    color="warning"
                    icon={<AccessTimeIcon />}
                />
            );
        }
        return <Chip label={`Authenticated (${sessionTime})`} color="success" icon={<SecurityIcon />} />;
    }, [isAuthenticated, isRefreshing, localRefreshing, timeUntilRefresh, sessionTime]);

    return (
        <Box sx={{ maxWidth: 'lg', mx: 'auto', p: 3 }}>
            {/* Header */}
            <Header />

            <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h4" component="h1">
                    Session Management
                </Typography>
                {renderStatusChip()}
            </Box>

            <Grid container spacing={3}>
                {/* User Information */}
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <PersonIcon /> User Profile
                            </Typography>
                            {user ? (
                                <Box>
                                    <Typography><strong>Name:</strong> {user.name}</Typography>
                                    <Typography><strong>Email:</strong> {user.email}</Typography>
                                    <Typography><strong>ID:</strong> {user._id}</Typography>
                                    {user.roles && (
                                        <Typography>
                                            <strong>Roles:</strong> {user.roles.join(', ')}
                                        </Typography>
                                    )}
                                </Box>
                            ) : (
                                <Typography color="error">No user data available</Typography>
                            )}
                        </CardContent>
                    </Card>
                </Grid>

                {/* Session Information */}
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <AccessTimeIcon /> Session Status
                            </Typography>
                            <Box>
                                <Typography>
                                    <strong>Time Remaining:</strong> {sessionTime}
                                </Typography>
                                <Typography>
                                    <strong>Token Status:</strong> {accessToken ? 'Valid' : 'Invalid'}
                                </Typography>
                                {refreshMessage && (
                                    <Typography color="info.main" sx={{ mt: 1 }}>
                                        {refreshMessage}
                                    </Typography>
                                )}
                                {error && (
                                    <Typography color="error.main" sx={{ mt: 1 }}>
                                        {error}
                                    </Typography>
                                )}
                            </Box>

                            <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
                                <Button
                                    variant="contained"
                                    startIcon={isRefreshing || localRefreshing ? <CircularProgress size={20} /> : <RefreshIcon />}
                                    onClick={handleRefresh}
                                    disabled={isRefreshing || localRefreshing || !isAuthenticated}
                                >
                                    Refresh Session
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<SecurityIcon />}
                                    onClick={handleGenerateTokens}
                                    disabled={isRefreshing || localRefreshing || !isAuthenticated}
                                >
                                    Generate Tokens
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Token Details */}
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <SecurityIcon /> Token Details
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle2" gutterBottom>Access Token</Typography>
                                    <Typography 
                                        sx={{ 
                                            wordBreak: 'break-all', 
                                            bgcolor: 'grey.100', 
                                            p: 1, 
                                            borderRadius: 1 
                                        }}
                                    >
                                        {accessToken ? `${accessToken.substring(0, 20)}...` : 'Not available'}
                                    </Typography>
                                    <Typography sx={{ mt: 1 }}>
                                        <strong>Expires:</strong> {accessTokenExpiration ? new Date(accessTokenExpiration).toLocaleString() : 'N/A'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle2" gutterBottom>Refresh Token</Typography>
                                    <Typography 
                                        sx={{ 
                                            wordBreak: 'break-all', 
                                            bgcolor: 'grey.100', 
                                            p: 1, 
                                            borderRadius: 1 
                                        }}
                                    >
                                        {refreshTokenExpiration ? 'Present (HttpOnly Cookie)' : 'Not available'}
                                    </Typography>
                                    <Typography sx={{ mt: 1 }}>
                                        <strong>Expires:</strong> {refreshTokenExpiration ? new Date(refreshTokenExpiration).toLocaleString() : 'N/A'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {/* Auto-refresh Message & Session Monitor */}
            {timeUntilRefresh && timeUntilRefresh <= 30 && (
                <Paper 
                    sx={{ 
                        position: 'fixed', 
                        bottom: 16, 
                        right: 16, 
                        p: 2, 
                        bgcolor: 'warning.light',
                        color: 'warning.contrastText',
                        borderRadius: 2,
                        boxShadow: 3,
                        maxWidth: 400,
                        zIndex: 1300
                    }}
                >
                    <Box sx={{ mb: 1 }}>
                        <SessionMonitor 
                            variant="minimal"
                            showUserDetails={false}
                            sx={{ 
                                color: 'inherit',
                                fontSize: '1.1rem',
                                fontWeight: 'medium'
                            }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <AccessTimeIcon fontSize="small" />
                        <Typography variant="body2">
                            Auto-refresh will trigger when session nears expiration
                        </Typography>
                    </Box>
                </Paper>
            )}
            {/* Feedback Snackbar */}
            <Snackbar
                open={feedback.open}
                autoHideDuration={6000}
                onClose={handleCloseFeedback}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={{
                    '& .MuiAlert-root': {
                        width: '100%',
                        boxShadow: 2,
                    }
                }}
            >
                <Alert 
                    onClose={handleCloseFeedback} 
                    severity={feedback.severity}
                    variant="filled"
                    elevation={6}
                    sx={{ 
                        width: '100%',
                        minWidth: 300,
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    {feedback.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default AuthDashboard;