import React, { useState, useEffect } from 'react';
import { Box, Snackbar } from '@mui/material';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles/index.js';
import { HelmetProvider } from 'react-helmet-async'; // Import HelmetProvider

import { AuthProvider } from './contexts/AuthContext.js';
import Layout from './components/Layout.js';
import Dashboard from './pages/Dashboard.js';
import TestPage from './pages/TestPage.js';
import LoginPage from './pages/LoginPage.js';
import Register from './pages/RegisterPage.js';
import AuthCheckPage from './pages/AuthCheckPage.js';
import ProfilePage from './pages/ProfilePage.js';
import UserChannels from './components/UserChannels.js';
import NotFound from './components/NotFound.js';
import Header from './components/Header.js';
import theme from './theme.js';
import ProtectedRoute from './components/ProtectedRoute.js';
import CheckCookie from './pages/CheckCookie.js';
import TokenTester from './pages/TokenTester.js';
import TokenGeneratorTest from './pages/TokenGeneratorTest.js';
import TrafficDetailsPage from './pages/TrafficDetailsPage.js';
import AuthDashboard from './pages/AuthDashboard.js';
import Home from './pages/Home.js';

const App = () => {
  const [testMode, setTestMode] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const location = useLocation();

  const handleError = (message) => {
    setErrorMessage(message);
    setOpenSnackbar(true);
  };

  useEffect(() => {
    // Simple global function to signal exchange dialog should open
    window.openExchangeDialog = function() {
      // Create and dispatch a custom event for Header to listen for
      const event = new CustomEvent('openExchangeDialog');
      window.dispatchEvent(event);
    };
  }, []);

  return (
    <HelmetProvider> {/* Add HelmetProvider wrapper */}
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <Layout>
            <Routes>
              <Route path="/home" element={<Navigate to="/" replace />} />
              <Route path="/" element={<Home />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/login" element={<LoginPage setErrorMessage={handleError} setOpenSnackbar={setOpenSnackbar} />} />
              <Route path="/register" element={<Register />} />
              <Route path="/authcheck" element={<AuthCheckPage />} />
              <Route path="/test" element={<ProtectedRoute testMode={testMode}><TestPage /></ProtectedRoute>} />
              <Route path="/profile" element={<ProtectedRoute testMode={testMode}><ProfilePage /></ProtectedRoute>} />
              <Route path="/checkcookie" element={<ProtectedRoute testMode={testMode}><CheckCookie /></ProtectedRoute>} />
              <Route path="/tokentester" element={<ProtectedRoute testMode={testMode}><TokenTester /></ProtectedRoute>} />
              <Route path="/AuthDashboard" element={<ProtectedRoute testMode={testMode}><AuthDashboard /></ProtectedRoute>} />
              <Route path="/generate" element={<ProtectedRoute testMode={testMode}><TokenGeneratorTest /></ProtectedRoute>} />
              <Route path="/userchannels" element={<ProtectedRoute testMode={testMode}><UserChannels /></ProtectedRoute>} />
              <Route path="/traffic" element={<ProtectedRoute testMode={testMode}><TrafficDetailsPage /></ProtectedRoute>} />
              <Route path="*" element={<NotFound />} />
            </Routes>

            <Snackbar
              open={openSnackbar}
              autoHideDuration={9000}
              onClose={() => setOpenSnackbar(false)}
              message={errorMessage}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            />
          </Layout>
        </AuthProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;