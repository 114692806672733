import React, { createContext, useState, useCallback, useRef } from 'react';
import axiosInstance from '../services/api.js';
import UploadQueue from './components/UploadQueue.js';
import { formatBytes } from '../utils/uploadUtils.js';

const UploadQueueContext = createContext();
const CHUNK_SIZE = 4 * 1024 * 1024; // 4MB chunks
const MAX_RETRIES = 5;
const MAX_BACKOFF = 30000; // 30 seconds

function useUploadQueue() {
  const context = React.useContext(UploadQueueContext);
  if (!context) {
    throw new Error('useUploadQueue must be used within an UploadQueueProvider');
  }
  return context;
}

function UploadQueueProvider({ children, onMessageUpdated  }) {
  // States
  const [uploadQueue, setUploadQueue] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [uploadErrors, setUploadErrors] = useState({});
  const [uploadLogs, setUploadLogs] = useState({});
  const [minimized, setMinimized] = useState(true);
  const [processingFiles, setProcessingFiles] = useState(new Set());

  // Refs
  const uploadControllersRef = useRef(new Map());
  const processingStatusRef = useRef(new Map());
  const messageUrlMapRef = useRef(new Map());



  // Add a function to check if a file is already being processed
  const isFileInQueue = useCallback((fileName, fileSize, lastModified) => {
    return uploadQueue.some(item => 
      item.file.name === fileName &&
      item.file.size === fileSize &&
      item.file.lastModified === lastModified
    );
  }, [uploadQueue]);



  const queueFiles = useCallback((files, channelId) => {
    // setMinimized(false);
    
    // Check for duplicates using isFileInQueue
    const newFiles = files.filter(file => {
      if (isFileInQueue(file.name, file.size, file.lastModified)) {
        setUploadErrors(prev => ({
          ...prev,
          [file.name]: 'File already in queue'
        }));
        return false;
      }
      return true;
    });
  
    if (newFiles.length > 0) {
      setUploadQueue(prev => [
        ...prev,
        ...newFiles.map(file => ({ 
          file, 
          channelId, 
          status: 'queued',
          addedAt: Date.now()
        }))
      ]);
    }
  }, [isFileInQueue]);

  // 1. Base utility functions
  const calculateBackoff = useCallback((retryCount) => 
    Math.min(1000 * Math.pow(2, retryCount), MAX_BACKOFF)
  , []);

  const getStatusMessage = useCallback((status, details) => {
    switch (status) {
      case 'uploading':
        return `📤 Uploading chunk ${details.currentChunk}/${details.totalChunks}`;
      case 'processing':
        return '🔄 Processing file...';
      case 'stitching':
        return '🔄 Combining file chunks...';
      case 'complete':
        return '✅ Upload complete!';
      case 'error':
        return `❌ ${details.error || 'Upload failed'}`;
      default:
        return `ℹ️ Status: ${status}`;
    }
  }, []);

  // 2. Basic state updates
  const updateProcessingStatus = useCallback((fileName, status, details = {}) => {
    processingStatusRef.current.set(fileName, {
      status,
      timestamp: Date.now(),
      ...details
    });

    setUploadLogs(prev => {
      const currentLogs = prev[fileName] || [];
      const statusMessage = getStatusMessage(status, details);
      return {
        ...prev,
        [fileName]: [...currentLogs, statusMessage]
      };
    });
  }, [getStatusMessage]);

  // 3. Basic queue management
  const removeFileFromQueue = useCallback((fileName, messageId) => {
    console.log(`🗑️ Removing file from queue: ${fileName}`);
    
    const controller = uploadControllersRef.current.get(fileName);
    if (controller) {
      console.log(`⏹️ Aborting upload for: ${fileName}`);
      controller.abort();
      uploadControllersRef.current.delete(fileName);
    }
  
    // Update upload queue first
    setUploadQueue(prev => prev.filter(item => item.file.name !== fileName));
    
    // Clean up states
    setUploadProgress(prev => {
      const { [fileName]: _, ...rest } = prev;
      return rest;
    });
    
    setUploadErrors(prev => {
      const { [fileName]: _, ...rest } = prev;
      return rest;
    });
    
    setUploadLogs(prev => {
      const { [fileName]: _, ...rest } = prev;
      return rest;
    });
  
    // If messageId is provided, update the message's processing files
    if (messageId) {
      const message = messages.find(m => m._id === messageId);
      if (message) {
        axiosInstance.post(
          `/api/message/channel/${message.channel_id}/message/${messageId}/update`,
          {
            processingFiles: message.processingFiles.filter(f => f.name !== fileName)
          }
        ).catch(error => console.error('Failed to update message processing files:', error));
      }
    }
  
    console.log(`✅ Successfully removed ${fileName} from queue`);
  }, []);


  // 4. File processing functions
  const processFile = useCallback(async (fileName, totalChunks, channelId, messageId) => {
    try {
      updateProcessingStatus(fileName, 'stitching');
      
      // Use the correct endpoint for processing files
      const response = await axiosInstance.post('/api/files/complete-upload', {
        fileName,
        totalChunks,
        channelId,
        messageId
      });
  
      if (!response.data) {
        throw new Error('No response from processing endpoint');
      }
  
      // Update status based on response
      if (response.data.success) {
        updateProcessingStatus(fileName, 'complete');
        
        return {
          success: true,
          fileUrl: response.data.fileUrls, // Note: server returns fileUrls not fileUrl
          fileName: fileName
        };
      } else {
        throw new Error(response.data.message || 'File processing failed');
      }
  
    } catch (error) {
      console.error(`❌ Processing failed for ${fileName}:`, error);
      
      // Handle specific error cases
      let errorMessage = error.message;
      if (error.response?.status === 404) {
        errorMessage = 'File processing endpoint not found. Please check server configuration.';
      } else if (error.response?.status === 500) {
        errorMessage = 'Server error while processing file.';
      }
      
      updateProcessingStatus(fileName, 'error', { error: errorMessage });
      throw error;
    }
  }, [updateProcessingStatus]);

  
  // 5. Upload functions
  const uploadChunk = useCallback(async (chunk, file, index, totalChunks, channelId) => {
    let retryCount = 0;
    
    while (retryCount < MAX_RETRIES) {
      try {
        // Create a blob from the chunk
        const chunkBlob = new Blob([chunk], { type: file.type });
        
        const formData = new FormData();
        formData.append('chunk', chunkBlob, file.name);
        formData.append('chunkIndex', index.toString());
        formData.append('totalChunks', totalChunks.toString());
        formData.append('fileName', file.name);
        formData.append('channelId', channelId);
        formData.append('fileSize', chunkBlob.size.toString());
        formData.append('mimeType', file.type);
  
        const controller = uploadControllersRef.current.get(file.name);
        
        const response = await axiosInstance.post('/api/files/upload-chunk', formData, {
          signal: controller?.signal,
          timeout: 60000, // 60 second timeout
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (e) => {
            const overallProgress = ((index * CHUNK_SIZE + e.loaded) / file.size) * 100;
            setUploadProgress(prev => ({
              ...prev,
              [file.name]: Math.min(Math.round(overallProgress), 99)
            }));
          }
        });
  
        if (response.data?.success) {
          console.log(`✅ Chunk ${index + 1}/${totalChunks} uploaded`);
          return true;
        }
        throw new Error(response.data?.message || 'Chunk upload failed');
  
      } catch (error) {
        if (error.code === 'ECONNABORTED' || error.response?.status === 504) {
          retryCount++;
          if (retryCount === MAX_RETRIES) {
            throw new Error(`Chunk upload failed after ${MAX_RETRIES} retries`);
          }
          console.log(`Retrying chunk ${index} (Attempt ${retryCount}/${MAX_RETRIES})`);
          await new Promise(resolve => setTimeout(resolve, calculateBackoff(retryCount)));
          continue;
        }
        throw error;
      }
    }
    return false;
  }, [calculateBackoff]);

  const uploadFile = useCallback(async (file, channelId, messageId) => {
    const controller = new AbortController();
    
    try {
      // Check if file is already being uploaded
      if (uploadControllersRef.current.has(file.name)) {
        console.log(`⚠️ File ${file.name} is already being uploaded`);
        return { status: 'already_uploading' };
      }
      
      // Set the controller for this upload
      uploadControllersRef.current.set(file.name, controller);
      setProcessingFiles(prev => new Set(prev).add(file.name));
      
      const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
      
      updateProcessingStatus(file.name, 'starting', {
        totalSize: formatBytes(file.size),
        totalChunks
      });
  
      // Upload all chunks
      const uploadedChunks = new Set();
      for (let index = 0; index < totalChunks; index++) {
        // Only check cancellation at the start of each chunk
        if (!uploadControllersRef.current.has(file.name)) {
          console.log(`⚠️ Upload cancelled for ${file.name}`);
          throw new Error('Upload cancelled by user');
        }
  
        const start = index * CHUNK_SIZE;
        const end = Math.min(start + CHUNK_SIZE, file.size);
        const chunk = file.slice(start, end);
  
        updateProcessingStatus(file.name, 'uploading', {
          currentChunk: index + 1,
          totalChunks,
          uploadedChunks: uploadedChunks.size
        });
  
        // Retry logic is handled inside uploadChunk
        const success = await uploadChunk(chunk, file, index, totalChunks, channelId);
        if (success) {
          uploadedChunks.add(index);
        } else {
          throw new Error('Chunk upload failed');
        }
      }
  
      // All chunks uploaded successfully
      return {
        status: 'complete',
        fileName: file.name,
        totalChunks,
        uploadedChunks: uploadedChunks.size
      };
  
    } catch (error) {
      // Don't show error if it was cancelled by user
      if (error.message !== 'Upload cancelled by user') {
        updateProcessingStatus(file.name, 'error', { 
          error: error.message || 'Upload failed' 
        });
      }
      throw error;
    } finally {
      uploadControllersRef.current.delete(file.name);
      setProcessingFiles(prev => {
        const newSet = new Set(prev);
        newSet.delete(file.name);
        return newSet;
      });
    }
  }, [uploadChunk, updateProcessingStatus]);

  
// In UploadQueueProvider
const sendMessageWithFiles = useCallback(async (messageContent, channelId) => {
  try {
    if (uploadQueue.length === 0 && !messageContent) {
      return { success: false, message: 'No content to send' };
    }

    // Filter out files that are already being processed
    const newFiles = uploadQueue.filter(({ file }) => !processingFiles.has(file.name));

    if (newFiles.length === 0 && !messageContent) {
      return { success: false, message: 'All files are already being processed' };
    }

    // Create initial message with placeholders only for new files
    const messageData = {
      text: messageContent,
      fileUrls: [],
      processingFiles: newFiles.map(({ file }) => ({
        name: file.name,
        size: file.size,
        type: file.type,
        previewUrl: file.type.startsWith('image/') ? URL.createObjectURL(file) : null
      }))
    };

    // Send initial message
    const messageResponse = await axiosInstance.post(
      `/api/message/channel/${channelId}/send`, 
      messageData
    );

    if (!messageResponse.data.success) {
      throw new Error('Failed to create message');
    }

    const messageId = messageResponse.data.message._id;

    // Start processing files in background
    if (newFiles.length > 0) {
      setMinimized(true); // Start minimized immediately after sending
      uploadFiles(messageId, channelId, newFiles);
    }

    return {
      success: true,
      messageId,
      initialFiles: messageData.processingFiles
    };

  } catch (error) {
    console.error('Failed to send message:', error);
    return { success: false, error: error.message };
  }
}, [uploadQueue, processingFiles]);

const uploadFiles = useCallback(async (messageId, channelId) => {
  const results = new Map();
  const errors = new Map();
  const completedFiles = new Set();

  const processFiles = async () => {
    try {
      for (const { file } of uploadQueue) {
        if (completedFiles.has(file.name)) continue;

        try {
          // Upload chunks
          const uploadStatus = await uploadFile(file, channelId, messageId);
          
          if (uploadStatus.status === 'complete') {
            // Process the completed file
            const result = await processFile(file.name, uploadStatus.totalChunks, channelId, messageId);
            
            if (result.success) {
              results.set(file.name, result.fileUrl);
              completedFiles.add(file.name);
              
              // Update message with each completed file
              const currentUrls = messageUrlMapRef.current.get(messageId) || [];
              const updatedUrls = [...currentUrls, result.fileUrl];
              
              const updateResponse = await axiosInstance.post(
                `/api/message/channel/${channelId}/message/${messageId}/update`,
                {
                  fileUrls: updatedUrls,
                  processingFiles: uploadQueue
                    .filter(({ file }) => !completedFiles.has(file.name))
                    .map(({ file }) => ({
                      name: file.name,
                      size: file.size,
                      type: file.type
                    }))
                }
              );

              // Dispatch custom event for real-time update
              const updateEvent = new CustomEvent('message-file-updated', {
                detail: {
                  messageId,
                  fileUrls: updatedUrls,
                  processingFiles: updateResponse.data.message.processingFiles || []
                }
              });
              window.dispatchEvent(updateEvent);

              // Update message URL map
              messageUrlMapRef.current.set(messageId, updatedUrls);

              // Trigger UI update via callback
              if (typeof onMessageUpdated === 'function') {
                onMessageUpdated(messageId, updatedUrls);
              }
            }
          }
        } catch (error) {
          errors.set(file.name, error.message || 'Unknown error');
        }
      }

      // Clean up completed files
      if (completedFiles.size === uploadQueue.length) {
        setMinimized(true);
        completedFiles.forEach(fileName => {
          setTimeout(() => removeFileFromQueue(fileName), 2000);
        });
      }
    } catch (error) {
      console.error('Critical error in file processing:', error);
    }
  };

  // Start processing in background
  processFiles();

  return {
    success: true,
    uploadStarted: true,
    totalFiles: uploadQueue.length
  };
}, [uploadQueue, uploadFile, processFile, removeFileFromQueue, onMessageUpdated]);


  // 6. Helper functions
  const updateMessageWithFile = useCallback(async (messageId, channelId, fileUrl) => {
    // ... updateMessageWithFile implementation
  }, []);

  return (
    <UploadQueueContext.Provider
      value={{
        uploadQueue,
        uploadProgress,
        uploadErrors,
        uploadLogs,
        minimized,
        setMinimized,
        isProcessing: processingFiles.size > 0,
        processingFiles,
        addFilesToQueue: queueFiles,
        removeFileFromQueue,
        
        uploadFiles,
        getProcessingStatus: () => ({
          isProcessing: processingFiles.size > 0,
          processingCount: processingFiles.size,
          processingFiles: Array.from(processingFiles)
        }),
        getMessageUrls: messageId => 
          messageUrlMapRef.current.get(messageId) || []
      }}
    >
      <UploadQueue />
      {children}
    </UploadQueueContext.Provider>
  );
}

export { useUploadQueue };
export default UploadQueueProvider;