import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Popover,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Avatar,
  CircularProgress,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import axiosInstance from "../services/api.js";

const UserChannels = ({ channels = [], selectedChannel, onChannelSelect, setChannels, user }) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentChannel, setCurrentChannel] = useState(null);
  const [files, setFiles] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [error, setError] = useState(null);
  const [channelName, setChannelName] = useState("");
  const [channelDescription, setChannelDescription] = useState("");
  const [creating, setCreating] = useState(false);




  const ChannelItem = ({ channel, isSelected, onChannelClick, onMenuOpen }) => (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 2,
        borderRadius: 2,
        p: 2,
        cursor: "pointer",
        bgcolor: isSelected ? "primary.light" : "transparent",
        "&:hover": { bgcolor: "grey.300" },
        transition: "all 0.2s ease-in-out",
        position: 'relative',
      }}
      onClick={() => onChannelClick(channel)}
    >
      <Avatar sx={{ width: 50, height: 50 }}>
        {channel.name.charAt(0).toUpperCase()}
      </Avatar>
      
      <Box sx={{ flex: 1, minWidth: 0 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0.5 }}>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: isSelected ? 'bold' : 'normal',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
          >
            {channel.name}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              fontFamily: 'monospace',
              color: isSelected ? 'error.main' : 'text.secondary',
              fontSize: '0.7rem'
            }}
          >
            ID: {channel._id}
          </Typography>
        </Box>
        
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
        >
          {channel.description || "No description"}
        </Typography>
      </Box>
  
      <IconButton
        onClick={(event) => onMenuOpen(event, channel)}
        sx={{
          ml: 'auto',
          '&:hover': { bgcolor: 'rgba(0,0,0,0.08)' }
        }}
      >
        <MoreVertIcon />
      </IconButton>
    </Box>
  );
  



// Add handler for Enter key
const handleKeyPress = (event) => {
  if (event.key === 'Enter') {
    if (openDeleteModal) {
      handleDeleteChannel();
    } else if (openCreateModal) {
      handleCreateChannel();
    } else if (openEditModal) {
      handleEditChannel();
    }
  }
};

// Add effect for key listener
useEffect(() => {
  window.addEventListener('keypress', handleKeyPress);
  return () => {
    window.removeEventListener('keypress', handleKeyPress);
  };
}, [openDeleteModal, openCreateModal, openEditModal]);



  // Open Delete Modal
  const handleOpenDeleteModal = async (channel) => {
    handleCloseMenu();
    if (!channel || !user) return;

    setCurrentChannel(channel);
    setOpenDeleteModal(true);

    try {
      setLoadingFiles(true);
      const response = await axiosInstance.get(`/api/files/list/${channel._id}`);
      setFiles(response?.data?.files || []);
    } catch (error) {
      console.error("Error fetching files:", error);
    } finally {
      setLoadingFiles(false);
    }
  };

  // Delete Channel
const handleDeleteChannel = async () => {
  if (!currentChannel?._id || !user?._id) {
    console.error("❌ Missing channel or user ID.");
    return;
  }

  setDeleting(true);
  try {
    console.log("📁 Files to delete:", files);

    if (files.length > 0) {
      const filePaths = files.map(file => file.Key);
      console.log("📤 Deleting files:", filePaths);

      const fileDeleteResponse = await axiosInstance.post('/api/files/delete', { filePaths });

      if (!fileDeleteResponse.data.success) {
        throw new Error("File deletion failed: " + fileDeleteResponse.data.message);
      }

      console.log("✅ Files deleted successfully.");
    }

    console.log("🗑 Deleting channel:", currentChannel._id);
    const channelDeleteResponse = await axiosInstance.delete(`/api/channels/${user._id}/${currentChannel._id}`);

    // 🔹 Fix: If response message contains "deleted successfully", consider it a success
    if (channelDeleteResponse.data.success || channelDeleteResponse.data.message.includes("deleted successfully")) {
      console.log("✅ Channel deleted successfully.");
      setChannels(prev => prev.filter(ch => ch._id !== currentChannel._id));

      if (selectedChannel?._id === currentChannel._id) {
        onChannelSelect(null);
      }

      setOpenDeleteModal(false); // ✅ Close the modal
      return;
    }

    throw new Error("Channel deletion failed: " + channelDeleteResponse.data.message);
  } catch (error) {
    console.error("❌ Error deleting channel:", error);
    setError(error.response?.data?.message || "Failed to delete channel");
  } finally {
    setDeleting(false);
  }
};

  
  

  

  // Handle Channel Selection
  const handleChannelClick = async (channel) => {
    if (!channel || !channel._id) {
      console.error("❌ Channel selection failed: Invalid channel object", channel);
      return;
    }

    console.log("🔄 Channel clicked:", {
      channelId: channel._id,
      channelName: channel.name,
      userId: user._id,
    });

    try {
      if (onChannelSelect) {
        onChannelSelect(channel);
      } else {
        console.warn("⚠️ onChannelSelect function is not defined");
      }
    } catch (error) {
      console.error("❌ Error in channel selection:", error);
    }
  };




const handleCreateChannel = async () => {
  if (!channelName.trim()) {
    setError("Channel name is required");
    return;
  }

  setCreating(true);
  try {
    const response = await axiosInstance.post(`/api/channels/${user._id}`, {
      name: channelName.trim(),
      description: channelDescription.trim() || ""
    });

    setChannels(prev => [...prev, response.data.channel]);
    setOpenCreateModal(false);
    setChannelName("");
    setChannelDescription("");
    setError(null);
  } catch (error) {
    setError(error.response?.data?.message || "Failed to create channel");
  } finally {
    setCreating(false);
  }
};

// Edit Channel Handler
const handleEditChannel = async () => {
  if (!channelName.trim() || !currentChannel?._id) {
    setError("Channel name is required");
    return;
  }

  setCreating(true);
  try {
    const response = await axiosInstance.put(`/api/channels/${currentChannel._id}`, {
      name: channelName.trim(),
      description: channelDescription.trim()
    });

    if (response?.data?.channel) {
      setChannels(prev => prev.map(ch => 
        ch._id === currentChannel._id 
          ? { ...ch, name: channelName, description: channelDescription }
          : ch
      ));
      setOpenEditModal(false);
      setError(null);
    }
  } catch (error) {
    setError(error.response?.data?.message || "Failed to update channel");
  } finally {
    setCreating(false);
  }
};





  useEffect(() => {
    if (!openDeleteModal) {
      setCurrentChannel(null);
      setFiles([]);
    }
  }, [openDeleteModal]);

  // Open Popover Menu
  const handleOpenMenu = (event, channel) => {
    event.stopPropagation(); // Prevent channel selection
    setAnchorEl(event.currentTarget);
    setCurrentChannel(channel);
  };

  // Close Popover
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };


// In UserChannels component, update the return statement:

return (
  <Box sx={{ p: 2, bgcolor: "background.paper", borderRadius: 2, boxShadow: 2 }}>
    {/* Header */}
    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
      <Typography variant="h6">Your Channels</Typography>
      <Button 
        variant="contained" 
        startIcon={<AddIcon />} 
        onClick={() => {
          setChannelName("");
          setChannelDescription("");
          setError(null);
          setOpenCreateModal(true);
        }}
      >
        Create
      </Button>
    </Box>

    {/* Channel List */}
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {channels.map((channel) => (
        <Box
          key={channel._id}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            borderRadius: 2,
            p: 2,
            cursor: "pointer",
            bgcolor: selectedChannel?._id === channel._id ? "primary.light" : "transparent",
            "&:hover": { bgcolor: "grey.300" },
            transition: "all 0.2s ease-in-out",
            position: 'relative',
          }}
          onClick={() => handleChannelClick(channel)}
        >
          <Avatar sx={{ width: 50, height: 50 }}>
            {channel.name.charAt(0).toUpperCase()}
          </Avatar>
          
          <Box sx={{ flex: 1, minWidth: 0 }}>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: selectedChannel?._id === channel._id ? 'bold' : 'normal',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              {channel.name}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              {channel.description || "No description"}
            </Typography>
          </Box>

          <IconButton
            onClick={(event) => handleOpenMenu(event, channel)}
            sx={{
              ml: 'auto',
              '&:hover': { bgcolor: 'rgba(0,0,0,0.08)' }
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Box>
      ))}
    </Box>

    {/* Popover Menu */}
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleCloseMenu}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <MenuItem onClick={() => {
        handleCloseMenu();
        setChannelName(currentChannel?.name || "");
        setChannelDescription(currentChannel?.description || "");
        setError(null);
        setOpenEditModal(true);
      }}>
        <Typography sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          ✏️ Edit Channel
        </Typography>
      </MenuItem>
      <MenuItem onClick={() => handleOpenDeleteModal(currentChannel)}>
        <Typography sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          🗑 Delete Channel
        </Typography>
      </MenuItem>
    </Popover>

    {/* Create Channel Modal */}
    <Dialog 
      open={openCreateModal} 
      onClose={() => setOpenCreateModal(false)}
      PaperProps={{
        sx: {
          borderRadius: 2,
          width: '90%',   // ✅ Responsive width
          maxWidth: '600px' // ✅ But don't exceed 600px
        }
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
          e.preventDefault();
          handleCreateChannel();
        }
      }}
    >
      <DialogTitle sx={{ borderBottom: '1px solid rgba(0,0,0,0.12)', pb: 2 }}>
        Create New Channel
      </DialogTitle>
      
      <DialogContent sx={{ mt: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Channel Name *
            </Typography>
            <input
              type="text"
              value={channelName}
              onChange={(e) => setChannelName(e.target.value)}
              placeholder="Enter channel name"
              autoFocus
              style={{
                width: '100%',
                padding: '10px',
                borderRadius: '6px',
                border: '1px solid rgba(0,0,0,0.23)',
                fontSize: '16px',
                marginBottom: '10px'  // ✅ Even spacing below input
              }}
            />
          </Box>

          <Box>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Description (Optional)
            </Typography>
            <textarea
              value={channelDescription}
              onChange={(e) => setChannelDescription(e.target.value)}
              placeholder="Enter channel description"
              style={{
                width: '100%',
                padding: '10px',
                borderRadius: '6px',
                border: '1px solid rgba(0,0,0,0.23)',
                fontSize: '16px',
                minHeight: '100px',
                resize: 'vertical'
              }}
            />
          </Box>

          {error && (
            <Typography color="error" sx={{ mt: 1 }}>
              {error}
            </Typography>
          )}
        </Box>
      </DialogContent>

      <DialogActions sx={{ p: 2, borderTop: '1px solid rgba(0,0,0,0.12)' }}>
        <Button 
          onClick={() => setOpenCreateModal(false)}
          disabled={creating}
        >
          Cancel
        </Button>
        <Button 
          variant="contained" 
          onClick={handleCreateChannel}
          disabled={creating}
        >
          {creating ? "Creating..." : "Create Channel"}
        </Button>
      </DialogActions>
    </Dialog>

    {/* Edit Channel Modal */}
    <Dialog 
      open={openEditModal} 
      onClose={() => setOpenEditModal(false)}
      PaperProps={{
        sx: {
          borderRadius: 2,
          borderRadius: 2,
          width: '90%',   // ✅ Responsive width
          maxWidth: '600px' // ✅ But don't exceed 600px
                  }
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
          e.preventDefault();
          handleEditChannel();
        }
      }}
    >
      <DialogTitle sx={{ borderBottom: '1px solid rgba(0,0,0,0.12)', pb: 2 }}>
        Edit Channel
      </DialogTitle>
      
      <DialogContent sx={{ mt: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Channel Name *
            </Typography>
            <input
              type="text"
              value={channelName}
              onChange={(e) => setChannelName(e.target.value)}
              placeholder="Enter channel name"
              autoFocus
              style={{
                width: '100%',
                padding: '8px 12px',
                borderRadius: '4px',
                border: '1px solid rgba(0,0,0,0.23)',
                fontSize: '16px'
              }}
            />
          </Box>

          <Box>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Description (Optional)
            </Typography>
            <textarea
              value={channelDescription}
              onChange={(e) => setChannelDescription(e.target.value)}
              placeholder="Enter channel description"
              style={{
                width: '100%',
                padding: '8px 12px',
                borderRadius: '4px',
                border: '1px solid rgba(0,0,0,0.23)',
                fontSize: '16px',
                minHeight: '100px',
                resize: 'vertical'
              }}
            />
          </Box>

          {error && (
            <Typography color="error" sx={{ mt: 1 }}>
              {error}
            </Typography>
          )}
        </Box>
      </DialogContent>

      <DialogActions sx={{ p: 2, borderTop: '1px solid rgba(0,0,0,0.12)' }}>
        <Button 
          onClick={() => setOpenEditModal(false)}
          disabled={creating}
        >
          Cancel
        </Button>
        <Button 
          variant="contained" 
          onClick={handleEditChannel}
          disabled={creating}
        >
          {creating ? "Updating..." : "Update Channel"}
        </Button>
      </DialogActions>
    </Dialog>

    {/* Delete Confirmation Dialog */}
    <Dialog 
      open={openDeleteModal} 
      onClose={() => setOpenDeleteModal(false)}
      PaperProps={{
        sx: {
          borderRadius: 2,
          minWidth: '400px'
        }
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
          e.preventDefault();
          handleDeleteChannel();
        }
      }}
    >
      <DialogTitle sx={{ borderBottom: '1px solid rgba(0,0,0,0.12)', pb: 2 }}>
        Delete Channel
      </DialogTitle>
      <DialogContent sx={{ mt: 2 }}>
        <Typography>
          Are you sure you want to delete <strong>{currentChannel?.name}</strong>?
          This action cannot be undone.
        </Typography>
        {loadingFiles ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <CircularProgress size={24} />
          </Box>
        ) : (
          files.length > 0 && (
            <List sx={{ mt: 2 }}>
              {files.map((file) => (
                <ListItem key={file.Key}>
                  <ListItemText primary={file.Key} />
                </ListItem>
              ))}
            </List>
          )
        )}
      </DialogContent>
      <DialogActions sx={{ p: 2, borderTop: '1px solid rgba(0,0,0,0.12)' }}>
        <Button onClick={() => setOpenDeleteModal(false)}>
          Cancel
        </Button>
        <Button 
          variant="contained"
          color="error" 
          onClick={handleDeleteChannel} 
          disabled={deleting}
        >
          {deleting ? "Deleting..." : "Delete"}
        </Button>
      </DialogActions>
    </Dialog>
  </Box>
);



};

export default UserChannels;
