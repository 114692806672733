import React, { useState } from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  LinearProgress, 
  CircularProgress,
  IconButton, 
  Fade, 
  Tooltip,
  Snackbar,
  Card,
  CardMedia,
  CardContent,
  Grid
} from '@mui/material';
import { Close, Minimize } from '@mui/icons-material';
import { useTheme, useMediaQuery } from '@mui/material';
import { useUploadQueue } from '../UploadQueueContext.js';

const FileStatus = ({ fileName, getFileStatus }) => {
  const { uploadProgress, uploadLogs } = useUploadQueue();
  const progress = uploadProgress[fileName] || 0;
  const { status } = getFileStatus(fileName);

  return (
    <Box sx={{ position: 'relative' }}>
      <LinearProgress 
        variant="determinate" 
        value={progress} 
        sx={{ 
          height: 4,
          borderRadius: 2,
          backgroundColor: status === 'stitching' ? 'warning.light' : 
            status === 'complete' ? 'success.light' : 'grey.300'
        }}
      />
      {status === 'stitching' && (
        <Typography
          variant="caption"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'warning.dark',
            fontSize: '0.6rem',
            fontWeight: 'bold'
          }}
        >
          COMBINING
        </Typography>
      )}
    </Box>
  );
};

const UploadQueue = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  
  const { 
    uploadQueue, 
    uploadProgress, 
    uploadLogs, 
    minimized, 
    setMinimized,
    removeFileFromQueue,
    isProcessing
  } = useUploadQueue();

  const handleClearAll = () => {
    // Remove each file individually since clearQueue isn't available
    uploadQueue.forEach(({ file }) => {
      removeFileFromQueue(file.name);
    });
    setMinimized(true);
  };

  const getFileKey = (file) => {
    return `${file.name}-${file.lastModified}-${file.size}`;
  };

  const FileCard = ({ file }) => (
    <Card 
      sx={{ 
        position: 'relative',
        borderRadius: 1,
        boxShadow: 1,
        maxWidth: isMobile ? 80 : 100, // Smaller cards
        mx: 'auto'
      }}
    >
      <IconButton
        size="small"
        sx={{
          position: 'absolute',
          top: 2,
          right: 2,
          padding: '2px',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 1)',
          },
          zIndex: 1,
        }}
        onClick={() => removeFileFromQueue(file.name)}
      >
        <Close sx={{ fontSize: '0.9rem' }} />
      </IconButton>

      <CardMedia
        component="img"
        height={isMobile ? 40 : 60} // Smaller images
        image={file.type.startsWith('image/') ? URL.createObjectURL(file) : 'https://placehold.co/300x200/EEE/31343C'}
        alt={file.name}
        sx={{ objectFit: 'cover' }}
      />

      <CardContent sx={{ padding: '4px' }}>
        <Typography variant="caption" noWrap sx={{ fontSize: '0.7rem' }}>
          {file.name}
        </Typography>
      </CardContent>

      <FileStatus fileName={file.name} getFileStatus={getFileStatus} />
    </Card>
  );

  const getFileStatus = (fileName) => {
    const logs = uploadLogs[fileName] || [];
    if (logs.some(log => log.includes('✅ File processing complete'))) {
      return { status: 'complete', message: 'Complete' };
    }
    if (logs.some(log => log.includes('🔄 Combining file chunks'))) {
      return { status: 'stitching', message: 'Combining chunks...' };
    }
    if (logs.some(log => log.includes('📤 Uploading chunk'))) {
      return { status: 'uploading', message: 'Uploading...' };
    }
    return { status: 'pending', message: 'Pending' };
  };

  const totalProgress = uploadQueue.reduce((acc, { file }) => {
    return acc + (uploadProgress[file.name] || 0);
  }, 0) / (uploadQueue.length || 1);

  if (minimized) {
    const processingCount = uploadQueue.filter(({ file }) => {
      const status = getFileStatus(file.name).status;
      return status === 'uploading' || status === 'stitching';
    }).length;

    return (
      <Fade in>
        <Box sx={{ position: 'fixed', bottom: isMobile ? 16 : 24, left: 16, zIndex: 2000 }}>
          <Box
            onClick={() => setMinimized(false)}
            sx={{
              position: 'relative',
              cursor: 'pointer',
              width: 32,
              height: 32,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Tooltip title={processingCount > 0 ? `${processingCount} files processing...` : 'Upload complete'}>
              <CircularProgress 
                variant="determinate" 
                value={totalProgress}
                size={32}
                sx={{ color: processingCount > 0 ? 'warning.main' : 'success.main' }}
              />
            </Tooltip>
            {processingCount > 0 && (
              <Typography
                variant="caption"
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  fontSize: '0.6rem',
                  fontWeight: 'bold',
                  color: 'warning.main'
                }}
              >
                {processingCount}
              </Typography>
            )}
          </Box>
        </Box>
      </Fade>
    );
  }

  if (uploadQueue.length === 0) {
    return null;
  }

  return (
    <Fade in>
      <Box sx={{
        position: 'fixed',
        zIndex: 2000,
        ...(isMobile ? {
          left: 8,
          bottom: 60,
          width: 'calc(100% - 16px)',
          maxWidth: '300px',
        } : {
          bottom: 80,
          right: 16,
          width: 240,
        }),
      }}>
        <Paper sx={{
          padding: 1,
          borderRadius: 2,
          boxShadow: 2,
          height: isMobile ? '40vh' : 300,
          maxHeight: isMobile ? 'calc(100vh - 120px)' : 300,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'background.paper'
        }}>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            mb: 1,
            px: 1
          }}>
            <Typography variant="caption" sx={{ fontWeight: 'medium' }}>
              Files ({uploadQueue.length})
            </Typography>
            <Box>
              <IconButton size="small" onClick={() => setMinimized(true)}>
                <Minimize sx={{ fontSize: '1rem' }} />
              </IconButton>
              <IconButton 
                size="small" 
                onClick={handleClearAll}
                sx={{ 
                  ml: 0.5,
                  '&:hover': { 
                    color: 'error.main'
                  }
                }}
              >
                <Close sx={{ fontSize: '1rem' }} />
              </IconButton>
            </Box>
          </Box>

          <Box sx={{
            overflowY: 'auto',
            flex: 1,
            px: 1,
          }}>
            <Grid container spacing={1}>
              {uploadQueue.map(({ file }) => (
                <Grid item xs={6} key={getFileKey(file)}>
                  <FileCard file={file} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Paper>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={4000}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        />
      </Box>
    </Fade>
  );
};

export default UploadQueue;