import React, { useState, useEffect, useCallback } from 'react';
import {
    CircularProgress,
    Box,
    Typography,
    IconButton,
    useMediaQuery,
} from '@mui/material';
import { InsertDriveFile, ExpandMore, ExpandLess } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles/index.js';
import axiosInstance from '../services/api.js';

const FilesInChannel = ({ channel }) => {
    const [objects, setObjects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Generate File URL (Storj)
    const generateFileUrl = (key) => {
        const baseUrl = "https://link.storjshare.io/s/jwxazbmhg7qirv2qmhjthxx4if3q/vau7t";
        return `${baseUrl}/${encodeURIComponent(key)}?wrap=0`;
    };

    // Fetch files from the channel
    const fetchObjects = useCallback(async () => {
        if (!channel?._id) return;

        try {
            setLoading(true);
            const response = await axiosInstance.get(`/api/files/list/${channel._id}`);
            if (response?.data?.success && Array.isArray(response.data.files)) {
                const filesWithUrls = response.data.files
                    .filter(file => file.Size > 0)
                    .map(file => ({
                        ...file,
                        url: generateFileUrl(file.Key),
                    }));
                setObjects(filesWithUrls);
            } else {
                setError('Failed to load files.');
                setObjects([]);
            }
        } catch (err) {
            setError('Error fetching files: ' + (err.response?.data?.message || err.message));
            setObjects([]);
        } finally {
            setLoading(false);
        }
    }, [channel]);

    useEffect(() => {
        if (channel) {
            fetchObjects();
        }
    }, [channel, fetchObjects]);

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <Box
            sx={{
                width: '100%',
                padding: 0,
                backgroundColor: isExpanded ? 'transparent' : 'rgba(0, 255, 0, 0.1)',
                borderBottom: '1px solid #ddd',
                overflow: 'hidden',
                borderRadius: '8px',
                boxShadow: isExpanded ? '0px 4px 10px rgba(0,0,0,0.1)' : 'none',
                transition: 'all 0.3s ease-in-out',
                height: isExpanded ? 'auto' : '40px', // Increased height for better tap area
                position: 'relative', // Important for positioning
                zIndex: 10, // Ensure it's above other elements
            }}
        >
            {/* Header Section (Toggle) - Full width clickable area */}
            <Box
                onClick={toggleExpanded}
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '8px 8px', // Increased padding for better tap target
                    cursor: 'pointer',
                    height: '40px', // Fixed height for header
                    width: '100%', // Ensure full width
                    position: 'relative', // For proper stacking
                    userSelect: 'none', // Prevent text selection
                    '&:hover': {
                        backgroundColor: isExpanded ? 'rgba(0,0,0,0.03)' : 'rgba(0,255,0,0.15)',
                    },
                }}
                role="button"
                aria-expanded={isExpanded}
                tabIndex={0} // Make it keyboard accessible
                onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                        toggleExpanded();
                        e.preventDefault();
                    }
                }}
            >
                <Typography
                    variant="h8"
                    sx={{
                        fontSize: '12px', // Slightly increased size
                        fontWeight: 'bold',
                        flexGrow: 1,
                        margin: 0,
                        color: 'rgba(0,0,0,0.7)',
                    }}
                >
                    Files in Channel {objects.length > 0 && `(${objects.length})`}
                </Typography>
                <IconButton 
                    sx={{ 
                        padding: '4px',
                        color: 'rgba(0,0,0,0.6)',
                        zIndex: 2, // Ensure it's above other elements 
                    }}
                    onClick={(e) => {
                        toggleExpanded();
                        e.stopPropagation(); // Prevent double toggling
                    }}
                    aria-label={isExpanded ? "Collapse files" : "Expand files"}
                >
                    {isExpanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
            </Box>

            {/* Collapsible Content */}
            {isExpanded && (
                <Box sx={{ 
                    marginTop: 1,
                    maxHeight: '400px', 
                    overflowY: 'auto',
                    padding: 2,
                    backgroundColor: 'white', // Ensure content has a solid background
                }}>
                    {loading ? (
                        <Box sx={{ 
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            p: 2
                        }}>
                            <CircularProgress size={24} />
                            <Typography sx={{ ml: 2 }}>Loading files...</Typography>
                        </Box>
                    ) : error ? (
                        <Typography color="error" sx={{ p: 2 }}>{error}</Typography>
                    ) : objects.length > 0 ? (
                        <Box
                            sx={{
                                display: 'grid',
                                gap: 2,
                                gridTemplateColumns: {
                                    xs: 'repeat(auto-fill, minmax(100px, 1fr))',
                                    sm: 'repeat(auto-fill, minmax(120px, 1fr))',
                                    md: 'repeat(auto-fill, minmax(140px, 1fr))',
                                },
                            }}
                        >
                            {objects.map((object, index) => (
                                <Box 
                                    key={index} 
                                    sx={{ 
                                        textAlign: 'center', 
                                        position: 'relative',
                                        borderRadius: '8px',
                                        overflow: 'hidden',
                                        transition: 'transform 0.2s',
                                        '&:hover': {
                                            transform: 'scale(1.02)',
                                            boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                                        },
                                    }}
                                >
                                    {/* Image Files */}
                                    {/\.(jpg|jpeg|png|gif)$/i.test(object.Key) ? (
                                        <Box 
                                            sx={{ 
                                                position: 'relative',
                                                paddingTop: '100%', // Square aspect ratio
                                                backgroundColor: '#f5f5f5',
                                                borderRadius: '4px',
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => window.open(object.url, '_blank')}
                                        >
                                            <img
                                                src={object.url}
                                                alt={object.Key.split('/').pop()}
                                                loading="lazy"
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                    borderRadius: '4px',
                                                }}
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJsdWNpZGUgbHVjaWRlLWltYWdlLW9mZiI+PHBhdGggZD0iTTIwIDIwYTIgMiAwIDAgMS0yIDJINnYtMl0iLz48cGF0aCBkPSJNNCA4djExYTIgMiAwIDAgMCAxLjYgMiI+PC9wYXRoPjxwYXRoIGQ9Ik0yIDJsMTAgMTAiPjwvcGF0aD48cGF0aCBkPSJNMTIgMTJsNCAxNSI+PC9wYXRoPjxwYXRoIGQ9Ik0yIDIyIDE2IDh1cHBlcmxlZnQiPjwvcGF0aD48cGF0aCBkPSJNOCA4YzAtLjUuMi0xIC41LTEuNS8yIDAtNCAxLTQgMno7L3BhdGg+PC9zdmc+';
                                                    e.target.style.padding = '20%';
                                                    e.target.style.objectFit = 'contain';
                                                }}
                                            />
                                        </Box>
                                    ) : 
                                    /* Video Files */
                                    /\.(mp4|mov|webm)$/i.test(object.Key) ? (
                                        <Box 
                                            sx={{ 
                                                position: 'relative',
                                                paddingTop: '56.25%', // 16:9 aspect ratio
                                                backgroundColor: '#000',
                                                borderRadius: '4px',
                                                overflow: 'hidden'
                                            }}
                                        >
                                            <video
                                                src={object.url}
                                                controls
                                                preload="metadata"
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'contain',
                                                }}
                                                onError={(e) => {
                                                    const errorDiv = document.createElement('div');
                                                    errorDiv.style.width = '100%';
                                                    errorDiv.style.height = '100%';
                                                    errorDiv.style.background = '#222';
                                                    errorDiv.style.color = '#fff';
                                                    errorDiv.style.display = 'flex';
                                                    errorDiv.style.alignItems = 'center';
                                                    errorDiv.style.justifyContent = 'center';
                                                    errorDiv.textContent = 'Video unavailable';
                                                    e.target.parentNode.appendChild(errorDiv);
                                                    e.target.style.display = 'none';
                                                }}
                                            />
                                        </Box>
                                    ) : (
                                    /* Other Files */
                                        <a
                                            href={object.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                padding: '10px',
                                                borderRadius: '4px',
                                                border: '1px solid #ddd',
                                                backgroundColor: '#fff',
                                                textDecoration: 'none',
                                                height: '100%',
                                                minHeight: '120px',
                                                color: 'inherit',
                                                transition: 'background-color 0.2s',
                                                '&:hover': {
                                                    backgroundColor: '#f5f5f5',
                                                }
                                            }}
                                        >
                                            <InsertDriveFile style={{ fontSize: '40px', color: '#555', marginBottom: '8px' }} />
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    fontSize: '12px',
                                                    wordBreak: 'break-word',
                                                    overflowWrap: 'break-word',
                                                    whiteSpace: 'normal',
                                                    maxWidth: '100%',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                {object.Key.split('/').pop()}
                                            </Typography>
                                        </a>
                                    )}

                                    {/* File Metadata */}
                                    <Box sx={{ mt: 1, textAlign: 'center' }}>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: 'bold',
                                                wordBreak: 'break-word',
                                                overflowWrap: 'break-word',
                                                whiteSpace: 'normal',
                                                maxWidth: '100%',
                                                display: '-webkit-box',
                                                WebkitLineClamp: 2,
                                                WebkitBoxOrient: 'vertical',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            {object.Key.split('/').pop()}
                                        </Typography>
                                        <Typography variant="body2" sx={{ fontSize: '11px', color: 'text.secondary' }}>
                                            {Math.round(object.Size / 1024)} KB
                                        </Typography>
                                        <Typography variant="body2" sx={{ fontSize: '11px', color: 'text.secondary' }}>
                                            {new Date(object.LastModified).toLocaleDateString()}
                                        </Typography>
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    ) : (
                        <Typography sx={{ p: 2, textAlign: 'center' }}>No files available.</Typography>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default FilesInChannel;