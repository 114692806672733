import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import axiosInstance from '../services/api.js';

const TokenDisplay = ({ title, token, expiration, isHttpOnly = false }) => {
  const [timeData, setTimeData] = useState({
    remaining: null,
    countdown: '--:--:--',
    serverOffset: 0,
    lastSync: null
  });

  // Synchronize with server time
  const syncServerTime = useCallback(async () => {
    try {
      const startTime = Date.now();
      const response = await axiosInstance.get('/api/server-time');
      const roundTripTime = Date.now() - startTime;
      const serverTime = response.data.serverTime;
      const offset = serverTime - (startTime + roundTripTime / 2);
      
      setTimeData(prev => ({
        ...prev,
        serverOffset: offset,
        lastSync: Date.now()
      }));
    } catch (error) {
      console.error('Error syncing server time:', error);
    }
  }, []);

  // Calculate time remaining
  const updateTime = useCallback(() => {
    if (!expiration) return;

    const now = Date.now() + timeData.serverOffset;
    const remaining = expiration - now;

    if (remaining <= 0) {
      setTimeData(prev => ({
        ...prev,
        remaining: 'Expired',
        countdown: '00:00:00',
        status: 'error'
      }));
      return;
    }

    const seconds = Math.floor(remaining / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    let remainingText;
    let status;

    if (days > 0) {
      remainingText = `${days}d ${hours % 24}h remaining`;
      status = 'success';
    } else if (hours > 0) {
      remainingText = `${hours}h ${minutes % 60}m remaining`;
      status = hours < 2 ? 'warning' : 'success';
    } else {
      remainingText = `${minutes}m ${seconds % 60}s remaining`;
      status = minutes < 5 ? 'error' : 'warning';
    }

    const countdownText = [
      String(hours).padStart(2, '0'),
      String(minutes % 60).padStart(2, '0'),
      String(seconds % 60).padStart(2, '0')
    ].join(':');

    setTimeData(prev => ({
      ...prev,
      remaining: remainingText,
      countdown: countdownText,
      status
    }));
  }, [expiration, timeData.serverOffset]);

  // Set up time sync and updates
  useEffect(() => {
    syncServerTime();
    const syncInterval = setInterval(syncServerTime, 30000);
    const updateInterval = setInterval(updateTime, 1000);

    return () => {
      clearInterval(syncInterval);
      clearInterval(updateInterval);
    };
  }, [syncServerTime, updateTime]);

  // Get status color
  const getStatusColor = (status) => {
    switch (status) {
      case 'error': return 'error.main';
      case 'warning': return 'warning.main';
      case 'success': return 'success.main';
      default: return 'text.secondary';
    }
  };

  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      
      <Box sx={{ pl: 2 }}>
        <Typography variant="body1" sx={{ mb: 1 }}>
          <strong>Status: </strong>
          <span style={{ 
            color: isHttpOnly ? 'blue' : token ? 'green' : 'red' 
          }}>
            {isHttpOnly ? 'Present (HTTP-only)' : 
             token ? 'Present' : 'Not found'}
          </span>
        </Typography>

        {expiration && (
          <>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>Expiration: </strong>
              {new Date(expiration).toLocaleString()}
            </Typography>

            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>Time Remaining: </strong>
              <span style={{ color: getStatusColor(timeData.status) }}>
                {timeData.remaining || 'N/A'}
              </span>
            </Typography>

            <Typography variant="body1" sx={{ 
              mb: 1,
              fontFamily: 'monospace',
              fontSize: '1.1em'
            }}>
              <strong>Countdown: </strong>
              <span style={{ color: getStatusColor(timeData.status) }}>
                {timeData.countdown}
              </span>
            </Typography>
          </>
        )}
      </Box>

      {isHttpOnly && (
        <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 1 }}>
          ℹ️ HTTP-only cookie - not accessible via JavaScript for security
        </Typography>
      )}
    </Box>
  );
};

export default TokenDisplay;